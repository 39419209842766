import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';

interface SearchParamsContextType<T> {
  filters: T;
  updateFilters: (newFilters: Partial<T>) => void;
  removeFilter: (key: string) => void;
  getFilter: (key: string) => string | boolean | null;
}

interface SearchParamsProviderProps<T> {
  children: ReactNode;
}

const SearchParamsContext = createContext<
  SearchParamsContextType<any> | undefined
>(undefined);

export function SearchParamsProvider<T>({
  children,
}: SearchParamsProviderProps<T>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const getFiltersFromUrl = (): T => {
    const filters = {} as T;
    searchParams.forEach((value, key) => {
      if (value === '') return;
      if (value === 'true') (filters as any)[key] = true;
      else if (value === 'false') (filters as any)[key] = false;
      else if (value === 'null') (filters as any)[key] = null;
      else (filters as any)[key] = value;
    });
    return filters;
  };

  const [filters, setFilters] = useState<T>(getFiltersFromUrl());

  useEffect(() => {
    setFilters(getFiltersFromUrl());
  }, [location.search]);

  const updateFilters = (newFilters: Partial<T>) => {
    // Get the current URL search params
    const currentUrlParams = new URLSearchParams(window.location.search);

    // Update params with new filters
    Object.entries(newFilters).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== '') {
        currentUrlParams.set(key, String(value));
      } else {
        currentUrlParams.delete(key);
      }
    });

    // Update URL without refresh
    const newUrl = `${location.pathname}${
      currentUrlParams.toString() ? `?${currentUrlParams.toString()}` : ''
    }`;
    window.history.replaceState(null, '', newUrl);

    // Update state
    setFilters((prev) => ({
      ...prev,
      ...newFilters,
    }));

    // Update state
    setFilters((prev) => ({
      ...prev,
      ...newFilters,
    }));
  };

  const removeFilter = (key: string) => {
    // Get the current URL search params
    const currentUrlParams = new URLSearchParams(window.location.search);

    // Remove only the specified key
    currentUrlParams.delete(String(key));

    // Update URL without refresh
    const newUrl = `${location.pathname}${
      currentUrlParams.toString() ? `?${currentUrlParams.toString()}` : ''
    }`;
    window.history.replaceState(null, '', newUrl);

    // Update state by removing only the specified key
    setFilters((prev) => {
      const newFilters = { ...prev };
      delete (newFilters as any)[key];
      return newFilters;
    });
  };

  const getFilter = (key: string) => {
    const value = new URLSearchParams(window.location.search).get(String(key));

    if (value === null || value === '') return null;
    if (value === 'true') return true;
    if (value === 'false') return false;
    return value;
  };

  return (
    <SearchParamsContext.Provider
      value={{ filters, updateFilters, removeFilter, getFilter }}
    >
      {children}
    </SearchParamsContext.Provider>
  );
}

export function useSearchParamsContext<T>() {
  const context = useContext(SearchParamsContext);
  if (context === undefined) {
    throw new Error(
      'useSearchParamsContext must be used within a SearchParamsProvider',
    );
  }
  return context as SearchParamsContextType<T>;
}
