import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Flex } from 'components/Layout/Flex';

// data
// Utils
import InputSelect from '../../../components/FormTemplate/Fields/InputSelect';
import InputSelectAsync from '../../../components/FormTemplate/Fields/InputSelectAsync';
import { Customer, Estimate, PaymentStatus } from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';
import { useSearchParamsContext } from '../../../context';
import { PaymentsFilters } from '../../../pages/admin/Payments';

const PaymentsListFilters: FC = () => {
  const { t } = useTranslation();
  const {
    filters: currentFilters,
    updateFilters,
    removeFilter,
    getFilter,
  } = useSearchParamsContext<PaymentsFilters>();

  const { execute: getCustomers } = useApi<Customer[]>(
    '/customers?sort=full_name&direction=asc',
  );

  const statusOptions = Object.values(PaymentStatus).map((s) => ({
    value: s,
    label: t(`payments.statuses.${s}`),
  }));

  const { execute: getEstimates, state: getEstimatesState } =
    useApi<Estimate[]>('/estimates');

  const estimates = getEstimatesState.data?.value ?? [];

  const estimateOptions = estimates.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  useEffect(() => {
    getCustomers();
    getEstimates();
  }, []);

  useEffect(() => {
    handleEstimateIdChange('');
    getEstimates({
      query: { customer_id: currentFilters.customer_id },
    });
  }, [currentFilters.customer_id]);

  const handleCustomerSearch = (
    val: string,
    callback: (options: any[]) => void,
  ) => {
    const query: Record<string, string> = {};
    if (val) {
      query.search = val;
    }

    getCustomers({
      query,
      onSuccess: (result) => {
        callback(
          result.value.map((c) => ({
            value: c.id,
            label: c.full_name,
          })),
        );
      },
    });
  };

  const handleCustomerIdChange = (val?: Customer['id']) => {
    updateFilters({ customer_id: val });
  };

  const handleEstimateIdChange = (val?: Estimate['id']) => {
    updateFilters({ estimate_id: val });
  };

  const handleStatusChange = (val?: PaymentStatus) => {
    updateFilters({ status: val });
  };

  return (
    <Flex marginTop={{ xs: 'space40' }} justify="start">
      <Flex>
        <InputSelectAsync
          onChange={handleCustomerIdChange}
          loadOptions={handleCustomerSearch}
          defaultOptions
          label={t('payments.customer')}
          labelWeight="bold"
          labelUppercase={true}
          placeholder={t('payments.filters.search_by_customer')}
          width="256px"
          size="small"
          isClearable
          value={(getFilter('customer_id') as string) || ''}
        />
      </Flex>
      <Flex marginLeft={{ xs: 'space32' }}>
        <InputSelect
          key={Date.now()}
          onChange={handleEstimateIdChange}
          options={estimateOptions}
          label={t('payments.estimate')}
          labelWeight="bold"
          labelUppercase={true}
          placeholder={t('payments.filters.search_by_estimate')}
          width="256px"
          size="small"
          isClearable
          value={(getFilter('estimate_id') as string) || ''}
        />
      </Flex>

      <Flex marginLeft={{ xs: 'space32' }}>
        <InputSelect
          onChange={handleStatusChange}
          options={statusOptions}
          label={t('payments.status')}
          labelWeight="bold"
          labelUppercase={true}
          placeholder={t('payments.filters.search_by_status')}
          width="256px"
          size="small"
          isClearable
          value={(getFilter('status') as string) || ''}
        />
      </Flex>
    </Flex>
  );
};

export default PaymentsListFilters;
