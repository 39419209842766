import React, { useState } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as Checkbox from '@radix-ui/react-checkbox';
import { Check, Columns3 } from 'lucide-react';
import { ColumnDef } from '@tanstack/react-table';

interface ColumnSelectorProps<T> {
  columns: ColumnDef<T>[];
  visibleColumns: { [key: string]: boolean };
  onVisibilityChange: (columnId: string, isVisible: boolean) => void;
}

const ColumnSelector = <T,>({
  columns,
  visibleColumns,
  onVisibilityChange,
}: ColumnSelectorProps<T>) => {
  const [open, setOpen] = useState(false);

  return (
    <DropdownMenu.Root open={open} onOpenChange={setOpen}>
      <DropdownMenu.Trigger asChild>
        <button className="tw-relative tw-flex tw-items-center tw-gap-2 tw-p-3 tw-text-sm tw-bg-white tw-rounded-xl tw-shadow-sm tw-border tw-border-gray-200 hover:tw-bg-gray-50 tw-transition-colors">
          <Columns3 className="mr-2 h-4 w-4" />
          Colonnes
          <span className="tw-rounded-full tw-bg-primary tw-text-xs tw-text-white tw-w-5 tw-h-5 tw-absolute tw-flex tw-items-center tw-justify-center -tw-right-2 -tw-bottom-2">
            {Object.values(visibleColumns).filter((value) => value).length}
          </span>
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="tw-bg-white tw-rounded-lg tw-shadow-lg tw-border tw-border-gray-200 tw-z-50 tw-text-sm tw-font-open"
          align="end"
          sideOffset={8}
        >
          <div className="tw-flex tw-flex-col">
            <div className="tw-px-3 tw-py-2 tw-border-b">
              <span className="tw-font-bold">Colonnes</span>
            </div>
            <div className="tw-flex tw-flex-col tw-px-3 tw-py-2 tw-gap-x-8 tw-gap-y-2 tw-max-h-[335px] tw-flex-wrap">
              {columns.map((column) => {
                const columnId = column.id as string;
                return (
                  <label
                    key={columnId}
                    className="tw-flex tw-items-center tw-gap-2 tw-cursor-pointer"
                  >
                    <Checkbox.Root
                      checked={visibleColumns[columnId]}
                      onCheckedChange={(checked) =>
                        onVisibilityChange(columnId, !!checked)
                      }
                      className="tw-w-5 tw-h-5 tw-border tw-border-gray-500 tw-rounded-md tw-bg-white data-[state=checked]:tw-bg-primary tw-text-white tw-flex tw-items-center tw-justify-center"
                    >
                      <Checkbox.Indicator>
                        <Check size={14} />
                      </Checkbox.Indicator>
                    </Checkbox.Root>
                    <span className="tw-text-sm tw-text-gray-700 tw-whitespace-nowrap">
                      {column.header}
                    </span>
                  </label>
                );
              })}
            </div>
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default ColumnSelector;
