import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { File as CustomFile, MessageTemplate } from '../../types/resources';
import { useApi } from '../../hooks/useApi';
import { Col, Flex, Text } from '../Layout';
import { AdminAppRoutes } from '../../AdminApp';
import { Button } from '../Buttons';
import { theme } from '../../theme';
import { InputText } from '../FormTemplate/Fields/InputText';

import { getRouteWithParams } from '../../utils/router';
import cx from 'classnames';
import MessageTemplateEdit from '../../container/admin/MessageTemplate/MessageTemplateEdit';
import toast from 'react-hot-toast';

interface MessageTemplatesListProps {
  isModal?: boolean;
  onSelect?: (
    templateId: string | undefined,
    content: string,
    attachments?: CustomFile[],
  ) => void;
}

const MessageTemplatesList: FC<MessageTemplatesListProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [search, setSearch] = useState<string>();
  const { isModal, onSelect } = props;
  const [template, setTemplate] = useState<MessageTemplate>();

  const { execute: getMessageTemplates, state: getMessageTemplatesState } =
    useApi<MessageTemplate[]>(`/conversations/models`);
  const models = getMessageTemplatesState.data?.value ?? [];
  const total = getMessageTemplatesState.data?.pagination?.total ?? 0;

  useEffect(() => {
    getMessageTemplates({
      query: {
        title: search || undefined,
      },
    });
  }, [search]);

  const handleCopyContent = async (content: string) => {
    await navigator.clipboard.writeText(content);
    toast.success(t('message_template.copy_success'));
  };

  if (template) {
    return (
      <MessageTemplateEdit
        id={template.id}
        onGoBack={() => setTemplate(undefined)}
        onRefetch={() =>
          getMessageTemplates({
            query: {
              title: search || undefined,
            },
          })
        }
        onSelect={onSelect}
      />
    );
  }

  return (
    <>
      <Flex
        justify="between"
        direction={{ xs: 'column', sm: 'row' }}
        gap={{ xs: 'space16' }}
        wrap="wrap"
      >
        <Col xs={12} md={4}>
          <Text
            content={`${t('message_template.title')} (${total})`}
            fontStyle="heading2"
          />
        </Col>
        {!isModal && (
          <Col xs={12} md={4} justify="end">
            <Button
              content={t('message_template.add.new')}
              iconLeft={{ name: 'Plus' }}
              onClick={() => navigate(AdminAppRoutes.MESSAGE_TEMPLATES_CREATE)}
            />
          </Col>
        )}
        <Col xs={12} md={4}>
          <InputText
            onChange={(e) => setSearch(e.target.value)}
            labelUppercase={true}
            labelFontStyle="label"
            placeholder={t('message_template.search')}
            width="100%"
            labelWeight="bold"
            $size="small"
            icon={{ name: 'Search', primaryColor: theme.colors.salmon1 }}
          />
        </Col>
      </Flex>

      <div
        className={cx('tw-mt-8 tw-flex tw-flex-col tw-gap-4', {
          'tw-max-h-[40vh] tw-overflow-y-auto': isModal,
        })}
      >
        {models.map((model) => (
          <div key={model.id} className="tw-flex tw-items-center tw-gap-4">
            <div className="tw-px-7 tw-py-5 tw-bg-white tw-border tw-border-border tw-rounded-2xl tw-flex tw-flex-col tw-w-full">
              <span className="tw-font-bold">{model.title}</span>
              <p className="tw-text-sm tw-mb-0 tw-mt-2 tw-line-clamp-1 tw-w-full">
                {model.content}
              </p>
              <div className="tw-flex tw-flex-row tw-gap-4">
                {model.attachments?.map((file) => (
                  <span
                    className="tw-text-xs tw-bg-green1 tw-text-white tw-p-1 tw-rounded-md tw-mt-2"
                    key={file.id}
                  >
                    {file.name}
                  </span>
                ))}
              </div>
            </div>
            <div className="tw-flex tw-gap-4">
              {isModal ? (
                <Button
                  iconLeft={{ name: 'PlusSquare' }}
                  onClick={() =>
                    onSelect
                      ? onSelect(model.id, model.content, model.attachments)
                      : handleCopyContent(model.content)
                  }
                  primary
                  size="small"
                />
              ) : (
                <Button
                  iconLeft={{ name: 'Copy' }}
                  onClick={() =>
                    onSelect
                      ? onSelect(model.id, model.content, model.attachments)
                      : handleCopyContent(model.content)
                  }
                  primary
                  size="small"
                />
              )}

              {isModal ? (
                <Button
                  iconLeft={{ name: 'Show' }}
                  onClick={() => setTemplate(model)}
                  primary
                  size="small"
                />
              ) : (
                <Button
                  iconLeft={{ name: 'Edit' }}
                  onClick={() =>
                    navigate(
                      getRouteWithParams(
                        AdminAppRoutes.MESSAGE_TEMPLATES_EDIT,
                        {
                          templateId: model.id,
                        },
                      ),
                    )
                  }
                  primary
                  size="small"
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default MessageTemplatesList;
