import { Col, Flex, Row, Text } from 'components/Layout';
import { Control, Controller, FieldError, useForm } from 'react-hook-form';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  Invoice,
  ModalityPayment,
  OptionPayment,
} from 'components/Forms/Payment';
import { useNavigate, useParams } from 'react-router-dom';

import { FinalPaymentCard } from 'components/Cards';
import FormFooter from 'components/Layout/Footer/FormFooter';
import { Ghost } from 'components/Loading';
import { Icon } from 'components/Images';
import InputCheckbox from 'components/FormTemplate/Fields/InputCheckbox';
import PaymentStrip from 'components/Cards/PaymentStrip';
import { PublicAppRoutes } from 'PublicApp';
import { formatPrice } from 'utils/format';
import { getPricePerSpouse } from 'utils/math';
import { getRouteWithParams } from 'utils/router';
import { md } from 'theme/styles/mediaQueries';
import { scrollToTop } from 'utils/layout';
import styled from 'styled-components';
import { theme } from 'theme';
import useIntersectionObserver from 'hooks/useOnScreen';
import { useTranslation } from 'react-i18next';
import { useViewport } from 'hooks/useViewport';
import ZendeskProvider from '../Zendesk/ZendeskProvider';
import { useApi } from '../../../hooks/useApi';
import {
  EstimateFull,
  EstimateStatus,
  PaymentUrl,
} from '../../../types/resources';
import { env } from '../../../config/env';
import { ModalityPaymentValue } from '../../../components/Forms/Payment/ModalityPayment';
import { useCustomer } from '../../../hooks/useCustomer';
import toast from 'react-hot-toast';

const Form = styled.form``;

const CGVText = styled(Text)`
  & a {
    color: ${theme.colors.black};
    text-decoration: underline;
    font-weight: bold;
  }
`;

const AccordionWrapper = styled(Flex)`
  & a {
    color: ${theme.colors.black};
    text-decoration: underline;
    font-weight: bold;
  }
`;

const IconContainer = styled(Flex)<{ isOpen: boolean }>`
  height: 24px;
  transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease-in-out;
`;

const Sticky = styled.div`
  position: sticky;
  top: 142px;
  width: 100%;
`;

const CustomRow = styled(Row)`
  flex-direction: column-reverse;

  ${md(`
    flex-direction: row;
  `)}
`;

const FinalPaymentRefWrapper = styled.div``;

interface FlexAnimatedProps {
  visible?: boolean;
}

const FlexAnimated = styled(Flex)<FlexAnimatedProps>`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
`;

interface AgreementProps {
  control: Control<ValidateEstimateInput, object>;
}

export type OptionPaymentEnum = 'totality' | 'split' | '';

interface AccordionProps {
  error: FieldError | undefined;
  title: string;
  onlyOpenOnIconClick?: boolean;
}

const Accordion: FC<AccordionProps> = ({
  error,
  children,
  title,
  onlyOpenOnIconClick = false,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      <AccordionWrapper
        cursor="pointer"
        onClick={!onlyOpenOnIconClick ? handleOpen : () => {}}
      >
        <Text
          fontStyle="body2"
          dangerouslySetInnerHTML={{
            __html: title,
          }}
          weight="bold"
          marginBottom={{ xs: 'space16' }}
          color={error ? theme.colors.red1 : theme.colors.gray6}
        />
        <IconContainer
          justify="center"
          alignItems="center"
          isOpen={isOpen}
          onClick={onlyOpenOnIconClick ? handleOpen : () => {}}
        >
          <Icon name="ChevronDown" primaryColor={theme.colors.salmon1} />
        </IconContainer>
      </AccordionWrapper>
      {isOpen && children}
    </>
  );
};

const Agreement: FC<AgreementProps> = ({ control }) => {
  const { t } = useTranslation();
  const textKeys = ['second', 'third', 'fourth'];

  return (
    <div style={{ userSelect: 'none' }}>
      <Controller
        control={control}
        name="acceptDivorce"
        rules={{ required: t('error.form.required').toString() }}
        render={({ field, fieldState: { error } }) => (
          <InputCheckbox
            onChange={field.onChange}
            checked={!!field.value}
            error={!!error}
          >
            <Flex
              direction={{ xs: 'column' }}
              paddingLeft={{ xs: 'space16' }}
              marginBottom={{ xs: 'space24' }}
            >
              <Accordion
                error={error}
                title={t(`quote.payment.agreement.first`)}
              >
                {textKeys.map((text, index) => (
                  <Text
                    key={`payment_agreement_text_${index}`}
                    fontStyle="body2"
                    content={t(`quote.payment.agreement.${text}`)}
                    weight="medium"
                    marginBottom={{ xs: 'space16' }}
                    color={error ? theme.colors.red1 : theme.colors.gray5}
                  />
                ))}
              </Accordion>
            </Flex>
          </InputCheckbox>
        )}
      />
      <Controller
        control={control}
        name="acceptLawyer"
        rules={{ required: t('error.form.required').toString() }}
        render={({ field, fieldState: { error } }) => (
          <InputCheckbox
            onChange={field.onChange}
            checked={!!field.value}
            error={!!error}
          >
            <Flex
              direction={{ xs: 'column' }}
              paddingLeft={{ xs: 'space16' }}
              marginBottom={{ xs: 'space32' }}
              onClick={() => field.onChange(!field.value)}
              style={{ cursor: 'pointer' }}
            >
              <Text
                fontStyle="body2"
                content={t('quote.payment.certificate')}
                weight="medium"
                color={error ? theme.colors.red1 : theme.colors.gray5}
              />
            </Flex>
          </InputCheckbox>
        )}
      />
      <Controller
        control={control}
        name="acceptCGU"
        rules={{ required: t('error.form.required').toString() }}
        render={({ field, fieldState: { error } }) => (
          <InputCheckbox
            onChange={field.onChange}
            checked={!!field.value}
            error={!!error}
            justify="start"
          >
            <Flex
              direction={{ xs: 'column' }}
              paddingLeft={{ xs: 'space16' }}
              marginBottom={{ xs: 'space24' }}
              marginRight={{ xs: 'space8' }}
            >
              <Accordion
                error={error}
                title={t('quote.payment.cgv')}
                onlyOpenOnIconClick
              >
                <CGVText
                  fontStyle="body2"
                  dangerouslySetInnerHTML={{
                    __html: t(`quote.payment.cgv_long`),
                  }}
                  weight="medium"
                  marginBottom={{ xs: 'space16' }}
                  color={error ? theme.colors.red1 : theme.colors.gray5}
                />
              </Accordion>
            </Flex>
          </InputCheckbox>
        )}
      />
    </div>
  );
};

export type ValidateEstimateInput = {
  acceptCGU: boolean;
  acceptDivorce: boolean;
  acceptLawyer: boolean;
  paymentSplit: boolean;
  modalityPayment: ModalityPaymentValue;
  spouseFollower: {
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
  };
  spouseLead: {
    address: string;
    city: string;
    country: string;
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
    postalCode: string;
    additional_address: string;
  };
};

const RecapPaymentQuote: FC = () => {
  const { t } = useTranslation();
  const { customer } = useCustomer();
  const {
    register,
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    setValue,
    reset,
    clearErrors,
    watch,
  } = useForm<ValidateEstimateInput>();
  const { isMobile } = useViewport();
  const [optionPayment, setOptionPayment] = useState<OptionPaymentEnum | ''>(
    'split',
  );

  const { id: estimateId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const finalPaymentRef = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(finalPaymentRef, {});
  const finalPaymentCardIsVisible = !!entry?.isIntersecting;
  const { execute: validateEstimate, state: validateEstimateState } = useApi(
    `/estimates/${estimateId}/validate`,
    { method: 'POST' },
  );

  const modalityPayment = watch('modalityPayment');

  const { execute: payAlmaEstimate, state: payAlmaEstimateState } =
    useApi<PaymentUrl>(`/estimates/${estimateId}/pay/alma`, { method: 'POST' });
  const { execute: paymentPaypalCapture } = useApi(`/payments/paypal/orders`, {
    method: 'POST',
  });
  const { execute: getEstimate, state: getEstimateState } =
    useApi<EstimateFull>(`/estimates/${estimateId}`);

  const estimate = getEstimateState.data?.value;
  const customer2 = estimate?.customer2;

  useEffect(() => {
    getEstimate();
  }, []);

  const validatePromise = (data: ValidateEstimateInput) =>
    new Promise<void>((resolve, reject) => {
      validateEstimate({
        body: {
          address: {
            street: data.spouseLead?.address,
            city: data.spouseLead?.city,
            country: data.spouseLead?.country,
            post_code: data.spouseLead?.postalCode,
            additional_address: data.spouseLead?.additional_address,
          },
          customer2: data.spouseFollower?.email
            ? {
                first_name: data.spouseFollower.first_name,
                last_name: data.spouseFollower.last_name,
                email: data.spouseFollower.email,
                phone: data.spouseFollower?.phone?.replaceAll(' ', ''),
              }
            : undefined,
          split_payments: optionPayment === 'split',
        },
        onError: (err) => {
          toast.error(t('quote.payment.error'));
          reject(err);
        },
        onSuccess: () => {
          resolve();
        },
      });
    });

  const validateAndPayWithAlma = async (data: ValidateEstimateInput) => {
    await validatePromise(data);

    payAlmaEstimate({
      body: { payment_plan: modalityPayment?.installments || 1 },
      onSuccess: (result) => {
        window.location.assign(result.value.url);
      },
      onError: () => {
        toast.error(t('quote.payment.error'));
      },
    });
  };

  const handlePaymentClick = () => {
    console.log('errors', errors);
  };

  const calculatePaymentStripPrice = useCallback(() => {
    const installments = modalityPayment?.installments || 1;

    if (estimate && installments) {
      if (optionPayment === 'split') {
        return formatPrice(
          parseInt(getPricePerSpouse(estimate.price)) / installments,
        );
      }
      return formatPrice(estimate.price / installments);
    }
  }, [estimate, modalityPayment, optionPayment]);

  useEffect(() => {
    if (estimate && customer) {
      reset({
        paymentSplit: true,
        spouseLead: {
          email: customer.email,
          first_name: customer.first_name,
          last_name: customer.last_name,
          phone: customer.phone,
          address: customer.address?.street,
          city: customer.address?.city,
          postalCode: customer.address?.post_code,
          additional_address: customer.address?.additional_address,
          country: customer.address?.country,
        },
        spouseFollower: {
          email: customer2?.email,
          phone: customer2?.phone ?? undefined,
          first_name: customer2?.first_name,
          last_name: customer2?.last_name,
        },
      });
    }
  }, [estimate, customer, customer2]);

  if (!estimate)
    return (
      <>
        <CustomRow
          marginBottom={{ xs: 'space16', md: 'space56' }}
          marginTop={{ xs: 'space40', md: 'space72' }}
        >
          <Col xs={12} md={7} vertical>
            <Flex marginBottom={{ xs: 'space32' }} width="100%">
              <Ghost width="100%" height={489} shape="rect" rx={24} />
            </Flex>
            <Ghost width="100%" height={375} shape="rect" rx={24} />
          </Col>
          <Col xs={12} md={5}>
            <Ghost width="100%" height={328} shape="rect" rx={24} />
          </Col>
        </CustomRow>
        <Row>
          <Col xs={12}>
            <Flex width="100%" marginBottom={{ xs: 'space56', md: 'space80' }}>
              <Ghost width="100%" height={835} shape="rect" rx={24} />
            </Flex>
          </Col>
        </Row>
      </>
    );

  return (
    <PayPalScriptProvider
      options={{
        clientId: env.paypal.clientId,
        currency: 'EUR',
        intent: 'capture',
      }}
    >
      <ZendeskProvider dealId={estimateId!}>
        <Form
          id="form"
          onSubmit={handleSubmit(validateAndPayWithAlma, (errors) => {
            console.log(errors);
          })}
        >
          <CustomRow
            marginBottom={{ xs: 'space16', md: 'space56' }}
            marginTop={{ xs: 'space40', md: 'space72' }}
          >
            <Col xs={12} md={7} vertical>
              {estimate.status !== EstimateStatus.WON && (
                <Flex marginBottom={{ xs: 'space24' }} expand>
                  <OptionPayment
                    defaultOptionSelected={optionPayment}
                    handleChange={setOptionPayment}
                  />
                </Flex>
              )}
              <Flex marginBottom={{ xs: 'space24' }} expand>
                <Controller
                  name="modalityPayment"
                  control={control}
                  rules={{ required: t('error.form.required').toString() }}
                  render={({ field, fieldState: { error } }) => (
                    <ModalityPayment
                      handleChange={field.onChange}
                      value={field.value}
                      error={error}
                    />
                  )}
                />
              </Flex>

              <FlexAnimated
                expand
                marginBottom={{ xs: 'space32', md: 'space80' }}
                direction={{ xs: 'column' }}
                visible
              >
                {customer && (
                  <Invoice
                    register={register}
                    errors={errors}
                    estimate={estimate}
                    customer1={customer}
                    customer2={customer2}
                    clearErrors={clearErrors}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                  />
                )}
              </FlexAnimated>
              <Flex mobileOnly direction={{ xs: 'column' }}>
                <Agreement control={control} />
              </Flex>
            </Col>
            <Col
              xs={12}
              md={5}
              vertical
              marginBottom={{ xs: 'space16', md: 'none' }}
            >
              <Sticky>
                <Flex
                  marginBottom={{ xs: 'none', md: 'space40' }}
                  width={'100%'}
                  direction={{ xs: 'column' }}
                  expand
                >
                  <FinalPaymentRefWrapper ref={finalPaymentRef}>
                    <FinalPaymentCard
                      prices={[
                        estimate.price,
                        getPricePerSpouse(estimate.price),
                      ]}
                      optionPayment={optionPayment}
                      modalityPayment={modalityPayment?.installments}
                      splitted={!!estimate.split_payments}
                    />
                  </FinalPaymentRefWrapper>
                  <Flex
                    desktopOnly
                    direction={{ xs: 'column' }}
                    marginTop={{ xs: 'space32' }}
                  >
                    <Agreement control={control} />
                  </Flex>
                </Flex>
              </Sticky>
            </Col>
          </CustomRow>
          <FormFooter
            step={9}
            maxStep={9}
            hideNext={modalityPayment?.provider === 'paypal'}
            prev={() => {
              navigate(
                getRouteWithParams(PublicAppRoutes.QUOTE_RESUME, {
                  id: estimateId!,
                }),
              );
              scrollToTop();
            }}
            prevText={t('back')}
            nextText={
              isMobile
                ? t('quote.access_payment_short')
                : t('quote.access_payment')
            }
            nextPrimary={true}
            $loading={
              validateEstimateState.loading || payAlmaEstimateState.loading
            }
            onClick={handlePaymentClick}
          >
            {modalityPayment?.provider === 'paypal' && (
              <PayPalButtons
                style={{
                  label: 'pay',
                  color: 'gold',
                  height: 37,
                }}
                fundingSource={'paypal'}
                onClick={async (_, actions) => {
                  try {
                    // Wrap react-hook-form handleSubmit in a Promise
                    // to benefit input validation & autofocus on errored fields
                    await new Promise<void>((resolve, reject) => {
                      handleSubmit(
                        // On form valid
                        async (data) => {
                          try {
                            await validatePromise(data);
                            resolve();
                          } catch (e) {
                            reject(e);
                          }
                        },
                        // On form invalid
                        (errors) => reject(errors),
                      )();
                    });
                    return actions.resolve();
                  } catch (e) {
                    return actions.reject();
                  }
                }}
                createOrder={(data, actions) => {
                  const price =
                    optionPayment === 'split'
                      ? estimate!.price / 2
                      : estimate!.price;

                  return actions.order.create({
                    intent: 'CAPTURE',
                    purchase_units: [
                      {
                        custom_id: estimateId,
                        amount: {
                          value: price.toFixed(2),
                          currency_code: 'EUR',
                        },
                      },
                    ],
                  });
                }}
                onApprove={(data) =>
                  new Promise((resolve, reject) =>
                    paymentPaypalCapture({
                      endpoint: `/payments/paypal/orders/${data.orderID}`,
                      onSuccess: () => {
                        navigate(
                          `/confirmation-paiement?estimate_id=${estimateId}`,
                        );
                        resolve();
                      },
                      onError: (err) => {
                        reject(err);
                      },
                    }),
                  )
                }
              />
            )}
          </FormFooter>
        </Form>
        {isMobile && (
          <PaymentStrip
            visible={!finalPaymentCardIsVisible}
            label={t(
              `quote.payment.total${
                modalityPayment?.installments > 1 ? '_today' : ''
              }`,
            )}
            price={`${calculatePaymentStripPrice()}`}
          />
        )}
      </ZendeskProvider>
    </PayPalScriptProvider>
  );
};

export default RecapPaymentQuote;
