import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { keyBy } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

import { theme } from 'theme';
import { View, Views } from 'types/nav';

import { Breadcrumbs, Nav } from 'container/admin';

import { Col, Flex, Requires, Row, Text } from 'components/Layout';
import { Button, IconButton } from 'components/Buttons';

import config from 'config/app';
import { Permission } from 'components/User';
import { Modal } from 'components/Modal';
import { AdminAppRoutes } from 'AdminApp';
import { useDownloadOpportunity } from 'utils/downloadFile';
import {
  ProcedureFull,
  ProcedureStep,
  ProcedureStepStatus,
  ProcedureStepType,
  ProcedureWorkflow,
  Role,
} from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';
import Dropdown from '../../../components/Dropdown';
import { isCurrentOrPastStep } from '../../../utils/status';
import toast from 'react-hot-toast';
import FooterRowSelect, {
  FooterButton,
} from '../../../components/Table/FooterRowSelect';

interface HeaderProps {
  procedure: ProcedureFull;
}

const Header: FC<HeaderProps> = ({ procedure }) => {
  const { t } = useTranslation();
  const { onDownload, loading: downloadLoading } = useDownloadOpportunity();
  const [deleteOpportunityLoading, setdeleteOpportunityLoading] =
    useState<boolean>(false);

  const { execute: syncYoProcess, state: syncYoProcessState } = useApi(
    `/procedures/${procedure.id}/sync/yo-process`,
    { method: 'POST' },
  );

  const { execute: downloadYopFiles, state: downloadYopFilesState } = useApi(
    `/procedures/${procedure.id}/download_yop_files`,
    { method: 'POST' },
  );

  const { execute: deleteProcedure } = useApi(`/procedures/${procedure.id}`, {
    method: 'DELETE',
    onSuccess: () => {
      toast.success(t('opportunity.delete_modal.success'));
      navigate(AdminAppRoutes.CASES);
    },
  });

  const location = useLocation();
  const navigate = useNavigate();
  const [bottomState, setBottomState] = useState<boolean>(false);
  const [openedModalDelete, toggleModalDelete] = useState<boolean>(false);

  const { reference, name } = procedure;
  const closureStep = procedure.steps.find(
    (s) => s.step === ProcedureStepType.CLOSED,
  );
  const formStep = procedure.steps.find(
    (s) => s.step === ProcedureStepType.FORM,
  );
  const date = format(new Date(procedure.created_at), config.dateFormat);
  const adminOnly = [Role.ADMIN, Role.MANAGER, Role.LAWYER];
  const hideNav = closureStep?.status === ProcedureStepStatus.VALIDATED;

  const steps = keyBy(procedure.steps, 'step') as Record<
    ProcedureStepType,
    (ProcedureStep & ProcedureWorkflow) | undefined
  >;

  const onMore = () => {
    setBottomState(!bottomState);
  };

  const buttons: FooterButton[] = [
    {
      onClick: () => toggleModalDelete(true),
      icon: 'Delete',
      text: t('opportunity.delete'),
      primary: true,
    },
    {
      onClick: () => {
        navigate({
          pathname: location.pathname,
          search: '?view=administrative-documents',
        });
        setBottomState(false);
      },
      icon: 'Folder',
      text: t('opportunity.see_all_administrative_documents'),
    },
  ];

  const views: View[] = [
    {
      name: Views.Global,
      disabled: false,
      roles: [Role.ADMIN, Role.MANAGER, Role.LAWYER],
    },
    {
      name: Views.Forms,
      disabled: false,
      roles: adminOnly,
    },
    {
      name: Views.Documents,
      disabled: !steps.DOCUMENT,
      roles: adminOnly,
    },
    {
      name: Views.Verify,
      disabled: !steps.VERIFICATION,
      roles: adminOnly,
    },
    {
      name: Views.ConventionWriting,
      disabled: !steps.WRITING_AGREEMENT,
      roles: [Role.ADMIN, Role.MANAGER, Role.LAWYER],
    },
    {
      name: Views.Convention,
      disabled:
        !steps.WRITING_AGREEMENT ||
        steps.WRITING_AGREEMENT?.status === ProcedureStepStatus.IN_PROGRESS,
      roles: [Role.ADMIN, Role.MANAGER, Role.LAWYER],
    },
    {
      name: Views.Recommended,
      disabled: !steps.AGREEMENT_SENT,
      roles: adminOnly,
    },
    {
      name: Views.Appointment,
      disabled: !steps.SIGNATURE,
      roles: adminOnly,
    },
    {
      name: Views.Notary,
      disabled: !steps.NOTARY,
      roles: [Role.ADMIN, Role.MANAGER],
    },
    {
      name: Views.Civil,
      disabled: !steps.MARITAL_STATUS,
      roles: adminOnly,
    },
    {
      name: Views.FinalSend,
      disabled: !steps.FINAL_SENDING,
      roles: adminOnly,
    },
  ];

  const sendYoProcess = async () => {
    syncYoProcess({
      onSuccess: () => {
        toast.success(t('opportunity.generate_convention_success'));
      },
      onError: () => {
        toast.error(t('opportunity.generate_convention_failed'));
      },
    });
  };

  const downloadFiles = async (files: string[]) => {
    downloadYopFiles({
      body: {
        file_types: files,
      },
      onSuccess: () => {
        toast.success(t('opportunity.download_yop_files_success'));
      },
      onError: () => {
        toast.error(t('opportunity.download_yop_files_failed'));
      },
    });
  };

  const handleSelectAction = async (id: string) => {
    switch (id) {
      case 'sync':
        await sendYoProcess();
        break;
      case 'c1':
        await downloadFiles([
          `attest_saisine_${procedure.lawyer1?.yo_process_value}`,
        ]);
        break;
      case 'c2':
        await downloadFiles([
          `attest_saisine_${procedure.lawyer2?.yo_process_value}`,
        ]);
        break;
      case 'transcription':
        await downloadFiles(['demande_transc']);
        break;
      case 'letters':
        await downloadFiles(['lettre_envoi_epoux1', 'lettre_envoi_epoux2']);
        break;
    }
  };

  return (
    <>
      <Row>
        <Col xs={8} vertical>
          <Breadcrumbs name={name} reference={reference.toString()} />
          <Text
            fontStyle="label"
            content={t('opportunity.opening', { date })}
            weight="bold"
            transform="uppercase"
            color={theme.colors.gray4}
            marginBottom={{ xs: 'space8' }}
          />
          <Text
            fontStyle="heading2"
            content={t('breadcrumb.opportunity', { name, reference })}
            marginRight={{ xs: 'space32' }}
            marginBottom={{ xs: 'space32' }}
          />
        </Col>
        <Permission roles={adminOnly}>
          <Col xs={4} justify="end">
            <Flex direction={{ xs: 'column' }}>
              <Flex marginBottom={{ xs: 'space8' }} justify="end" expand>
                <Button
                  content={t('opportunity.more_actions')}
                  iconLeft={{ name: 'MoreSquare' }}
                  onClick={onMore}
                />
              </Flex>
              <Dropdown
                loading={
                  syncYoProcessState.loading || downloadYopFilesState.loading
                }
                onValidate={handleSelectAction}
                actions={[
                  {
                    id: 'sync',
                    label: procedure.yo_process_case_id
                      ? t('opportunity.resend_yoprocess')
                      : t('opportunity.send_yoprocess'),
                    disabled:
                      formStep?.status !== ProcedureStepStatus.VALIDATED,
                  },
                  {
                    id: 'c1',
                    label: `Attestation de saisine de ${procedure.spouse1?.full_name}`,
                    disabled:
                      !isCurrentOrPastStep(
                        procedure.steps,
                        ProcedureStepType.FORM,
                        ProcedureStepStatus.VALIDATED,
                      ) ||
                      !procedure.lawyer1 ||
                      !procedure.yo_process_case_id,
                  },
                  {
                    id: 'c2',
                    label: `Attestation de saisine de ${procedure.spouse2?.full_name}`,
                    disabled:
                      !isCurrentOrPastStep(
                        procedure.steps,
                        ProcedureStepType.FORM,
                        ProcedureStepStatus.VALIDATED,
                      ) ||
                      !procedure.lawyer2 ||
                      !procedure.yo_process_case_id,
                  },
                  {
                    id: 'transcription',
                    label: 'Demande de transcription',
                    disabled:
                      !isCurrentOrPastStep(
                        procedure.steps,
                        ProcedureStepType.MARITAL_STATUS,
                        ProcedureStepStatus.IN_PROGRESS,
                      ) || !procedure.yo_process_case_id,
                  },
                  {
                    id: 'letters',
                    label: 'Envoi époux',
                    disabled:
                      !isCurrentOrPastStep(
                        procedure.steps,
                        ProcedureStepType.FINAL_SENDING,
                        ProcedureStepStatus.IN_PROGRESS,
                      ) || !procedure.yo_process_case_id,
                  },
                ]}
              />
            </Flex>
          </Col>
        </Permission>
      </Row>
      <Requires value={!hideNav}>
        <Row marginBottom={{ xs: 'space32' }}>
          <Col xs={12}>
            <Nav views={views} defaultView={Views.Global} />
          </Col>
        </Row>
      </Requires>
      <FooterRowSelect show={bottomState} buttons={buttons} />
      <Modal opened={openedModalDelete}>
        <Flex alignItems="center" marginBottom={{ xs: 'space16' }}>
          <IconButton
            iconName="Delete"
            color={theme.colors.salmon1}
            backgroundColor={theme.colors.salmon3}
            rounded
          />
          <Text
            fontStyle="heading5"
            weight="bold"
            content={t('opportunity.delete_modal.title')}
            marginLeft={{ xs: 'space16' }}
          />
        </Flex>
        <Text
          fontStyle="body1"
          content={t('opportunity.delete_modal.message')}
          marginBottom={{ xs: 'space24' }}
        />
        <Flex>
          <Flex marginRight={{ xs: 'space24' }}>
            <Button
              content={t('opportunity.delete_modal.cancel')}
              onClick={() => toggleModalDelete(false)}
            />
          </Flex>
          <Button
            content={t('opportunity.delete_modal.confirm')}
            primary
            onClick={async () => {
              setdeleteOpportunityLoading(true);
              await onDownload({
                opportunityId: procedure.id,
                filename: `${reference}-${name.toLocaleLowerCase()}`,
                onComplete: () => deleteProcedure(),
              });
            }}
            $loading={deleteOpportunityLoading || downloadLoading}
          />
        </Flex>
      </Modal>
    </>
  );
};

export default Header;
