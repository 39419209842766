import { Col, Flex, Row } from 'components/Layout';
import {
  DocumentInProgress,
  DocumentInReview,
  DocumentRequest,
  DocumentsValidated,
} from 'container/admin';
import { FC, useEffect, useState } from 'react';
import { getRouteWithParams, windowOpen } from 'utils/router';

import { AdminAppRoutes } from 'AdminApp';
import { Ghost } from 'components/Loading';
import { ListItem } from 'components/List';
import UpdateTitleDocument from './UpdateTitleDocument';
import { useTranslation } from 'react-i18next';
import {
  DocumentFull,
  DocumentStatus,
  Form,
  ProcedureFull,
  ProcedureSpouseFull,
  ProcedureStepStatus,
  ProcedureStepType,
} from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';
import toast from 'react-hot-toast';

interface DocumentViewProps {
  procedure: ProcedureFull;
  spouses: ProcedureSpouseFull[];
  refetchProcedure: () => void;
}

const DocumentView: FC<DocumentViewProps> = ({
  procedure,
  spouses,
  refetchProcedure,
}) => {
  const { t } = useTranslation();
  const [modalUpdateTitle, toogleModalUpdateTitle] = useState<{
    id: string;
    name: string;
  } | null>(null);

  const { execute: getDocuments, state: getDocumentsState } = useApi<
    DocumentFull[]
  >(`/procedures/${procedure.id}/documents?is_administrative=true`);

  const { execute: getForms, state: getFormsState } = useApi<Form[]>(
    `/procedures/${procedure.id}/forms`,
  );

  const { execute: validateStep, state: validateStepState } = useApi(
    `/procedures/${procedure.id}/steps/${ProcedureStepType.DOCUMENT}`,
    {
      method: 'PATCH',
    },
  );

  const firstForm = getFormsState.data?.value?.[0];
  const documents = getDocumentsState.data?.value ?? [];

  const loading = getFormsState.loading;

  const documentsValidated = documents.filter(
    (d) => d.status === DocumentStatus.VALIDATE,
  );
  const documentsInProgress = documents.filter(
    (d) => d.status === DocumentStatus.IN_PROGRESS,
  );
  const documentsInReview = documents.filter(
    (d) => d.status === DocumentStatus.IN_REVIEW,
  );
  const documentsBeingAdded = documents.filter(
    (d) => d.status === DocumentStatus.BEING_ADDED,
  );

  useEffect(() => {
    getForms();
    getDocuments();
  }, []);

  const onModalClose = () => {
    toogleModalUpdateTitle(null);
  };

  const validateStepCb = () => {
    validateStep({
      body: { status: ProcedureStepStatus.VALIDATED },
      onSuccess: () => {
        getDocuments();
        refetchProcedure();
        toast.success('Étape document validée');
      },
    });
  };

  const formRoute = getRouteWithParams(AdminAppRoutes.FORM, {
    opportunityId: procedure.id,
    formId: firstForm?.id ?? '',
  });

  const specs = {
    width: window.innerWidth / 2,
    height: window.innerHeight,
    top: 0,
  };

  const buttons = [
    {
      content: t('documents.verification.see_forms'),
      primary: false,
      onClick: () => {
        windowOpen(`${formRoute}?switchForm=true`, '_blank', specs);
      },
    },
    {
      content: t('documents.valid'),
      primary: true,
      loading: validateStepState.loading,
      hide: procedure.current_step.step !== ProcedureStepType.DOCUMENT,
      disabled: !(
        documents.every((doc) => doc.status === DocumentStatus.VALIDATE) &&
        documentsBeingAdded.length === 0 &&
        documentsInProgress.length === 0
      ),
      onClick: validateStepCb,
    },
  ];

  if (loading || !firstForm) {
    return (
      <>
        {[...Array(5)].map((_, index) => (
          <Flex
            key={`loading_form_${index}`}
            marginLeft={{ xs: 'space40' }}
            marginRight={{ xs: 'space40' }}
            marginBottom={{ xs: 'space40' }}
            width="80%"
          >
            <Ghost width="100%" height={56} shape="rect" rx={16} />
          </Flex>
        ))}
      </>
    );
  }

  return (
    <>
      <Row marginBottom={{ xs: 'space32' }}>
        <Col xs={12}>
          <ListItem
            iconName="Folder"
            title={t('documents.verification.opportunity', {
              name: procedure.name,
            })}
            buttons={buttons}
          />
        </Col>
      </Row>
      <Row marginBottom={{ xs: 'space32' }}>
        <Col xs={12} vertical>
          <DocumentRequest
            countDocumentsInReview={documentsInReview.length}
            spouses={spouses}
            documents={documentsBeingAdded}
            refetchDocuments={getDocuments}
            editTitle={toogleModalUpdateTitle}
            procedure={procedure}
          />
        </Col>
      </Row>
      <Row marginBottom={{ xs: 'space32' }}>
        <Col xs={12} vertical>
          <DocumentInProgress
            documents={documentsInProgress}
            procedure={procedure}
            refetchDocuments={getDocuments}
            editTitle={toogleModalUpdateTitle}
          />
        </Col>
      </Row>
      <Row marginBottom={{ xs: 'space32' }}>
        <Col xs={12} vertical>
          <DocumentInReview
            documents={documentsInReview}
            procedure={procedure}
            refetchDocuments={getDocuments}
            editTitle={toogleModalUpdateTitle}
          />
        </Col>
      </Row>
      <Row marginBottom={{ xs: 'space32' }}>
        <Col xs={12} vertical>
          <DocumentsValidated
            documents={documentsValidated}
            procedure={procedure}
            spouses={spouses}
            refetchDocuments={getDocuments}
            editTitle={toogleModalUpdateTitle}
          />
        </Col>
      </Row>

      {!!modalUpdateTitle && (
        <UpdateTitleDocument
          opened={!!modalUpdateTitle}
          refetchDocuments={getDocuments}
          onClose={onModalClose}
          documentId={modalUpdateTitle?.id}
          documentName={modalUpdateTitle?.name}
          opportunityId={procedure.id}
        />
      )}
    </>
  );
};

export default DocumentView;
