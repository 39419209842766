import {
  ProcedureStep,
  ProcedureStepStatus,
  ProcedureStepType,
} from '../types/resources';

export const getTypeByStatus = (status?: ProcedureStepStatus) => {
  switch (status) {
    case ProcedureStepStatus.IN_PROGRESS:
      return 'warning';
    case ProcedureStepStatus.IN_REVIEW:
      return 'info';
    case ProcedureStepStatus.LOCKED:
      return 'danger';
    default:
    case ProcedureStepStatus.VALIDATED:
      return 'success';
  }
};

export const getHideByStatus = (
  step: ProcedureStepType,
  status: ProcedureStepStatus,
) => {
  if (step === ProcedureStepType.WRITING_AGREEMENT) {
    return true;
  }
  if (step === ProcedureStepType.VERIFICATION) {
    return true;
  }
  if (
    step === ProcedureStepType.NOTARY &&
    status === ProcedureStepStatus.IN_PROGRESS
  ) {
    return true;
  }
  if (
    step === ProcedureStepType.MARITAL_STATUS &&
    status === ProcedureStepStatus.IN_PROGRESS
  ) {
    return true;
  }
  if (step === ProcedureStepType.CLOSED) {
    return true;
  }
  if (status === ProcedureStepStatus.LOCKED) {
    return true;
  }
};

export const isCurrentOrPastStep = (
  steps: ProcedureStep[],
  stepToCheck: ProcedureStepType,
  statusToCheck: ProcedureStepStatus,
): boolean => {
  const sortedSteps = [...steps].sort(
    (a, b) =>
      new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
  );

  const currentStep = sortedSteps[sortedSteps.length - 1];

  if (!currentStep) {
    return false;
  }

  const stepValues = Object.values(ProcedureStepType);
  const statusValues = Object.values(ProcedureStepStatus);

  const currentStepIndex = stepValues.indexOf(currentStep.step);
  const stepToCheckIndex = stepValues.indexOf(stepToCheck);
  const statusToCheckIndex = statusValues.indexOf(statusToCheck);

  if (stepToCheckIndex > currentStepIndex) {
    return false;
  }

  if (stepToCheckIndex === currentStepIndex) {
    const currentStatusIndex = statusValues.indexOf(currentStep.status);
    return currentStatusIndex >= statusToCheckIndex;
  }

  const pastStep = sortedSteps.find((s) => s.step === stepToCheck);
  if (pastStep) {
    const pastStepStatusIndex = statusValues.indexOf(pastStep.status);
    return pastStepStatusIndex >= statusToCheckIndex;
  }

  return false;
};

export const isCurrentOrPastStepSimple = (
  currentStepType: ProcedureStepType,
  currentStepStatus: ProcedureStepStatus,
  stepToCheck: ProcedureStepType,
  statusToCheck: ProcedureStepStatus,
): boolean => {
  const stepValues = Object.values(ProcedureStepType);
  const statusValues = Object.values(ProcedureStepStatus);

  const currentStepIndex = stepValues.indexOf(currentStepType);
  const stepToCheckIndex = stepValues.indexOf(stepToCheck);
  const currentStatusIndex = statusValues.indexOf(currentStepStatus);
  const statusToCheckIndex = statusValues.indexOf(statusToCheck);

  if (stepToCheckIndex > currentStepIndex) {
    return false;
  }

  if (stepToCheckIndex === currentStepIndex) {
    return currentStatusIndex >= statusToCheckIndex;
  }

  return stepToCheckIndex < currentStepIndex;
};
