import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Flex } from 'components/Layout/Flex';
import { Text } from 'components/Layout/Text';
import { Breadcrumbs, PaymentUpdateForm } from 'container/admin';
import { useApi } from '../../hooks/useApi';
import { useParams } from 'react-router-dom';
import { PaymentFull } from '../../types/resources';
import format from 'date-fns/format';

const PaymentsDetails: FC = () => {
  const { t } = useTranslation();
  const { id: paymentId } = useParams<{ id: string }>();

  const { execute: getPayment, state: getPaymentState } = useApi<PaymentFull>(
    `/payments/${paymentId}`,
  );
  const payment = getPaymentState.data?.value;

  useEffect(() => {
    getPayment();
  }, []);

  return (
    <>
      <Breadcrumbs name={payment?.id} />
      <Flex
        marginBottom={{ xs: 'space16' }}
        alignItems="center"
        justify="between"
      >
        <Text content={t('payments.update.title')} fontStyle="heading2" />
        {payment && (
          <Text
            content={t('updated_at', {
              updated_at: format(
                new Date(payment.updated_at),
                "dd/MM/YYY 'à' HH:mm",
              ),
            })}
            fontStyle="body3"
          />
        )}
      </Flex>
      {payment && <PaymentUpdateForm payment={payment} />}
    </>
  );
};

export default PaymentsDetails;
