import React, { FC } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { InputText } from '../FormTemplate/Fields/InputText';
import { useTranslation } from 'react-i18next';
import { TextArea } from '../FormTemplate/Fields/TextArea';
import { Button } from '../Buttons';
import { Page } from '../../types/resources';

type PageData = Omit<Page, 'created_at' | 'updated_at' | 'name'>;
interface PageFormProps {
  loading: boolean;
  onSubmit: (data: PageData) => void;
  Page?: Page;
}

const PageForm: FC<PageFormProps> = (props) => {
  const { t } = useTranslation();
  const { loading, onSubmit, Page } = props;

  const methods = useForm<PageData>({
    defaultValues: {
      title: Page?.title,
      description: Page?.description,
    },
  });

  const {
    control,
    register,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
  } = methods;

  const onSubmitHandler: SubmitHandler<PageData> = (data) => {
    onSubmit(data);
  };

  return (
    // @ts-ignore
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmitHandler)}
        className="tw-flex tw-flex-col tw-gap-x-16 tw-gap-y-8 tw-relative"
      >
        <div className="tw-flex tw-flex-col tw-gap-8">
          <InputText
            {...register('title', {
              required: t('error.form.required').toString(),
              maxLength: { value: 255, message: 'Texte trop long' },
            })}
            error={errors.title}
            label={t('pages.form.title.label')}
            placeholder={t('pages.form.title.placeholder')}
            width="100%"
          />
          <TextArea
            {...register('description', {
              required: t('error.form.required').toString(),
            })}
            error={errors.description}
            label={t('pages.form.description.label')}
            placeholder={t('pages.form.description.label')}
            width="100%"
            rows={6}
          />
        </div>
        <div className="tw-flex tw-items-start tw-gap-4">
          <Button
            content={t('pages.edit.submit')}
            type="submit"
            $loading={loading}
            primary
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default PageForm;
