import { FC, useEffect, useState } from 'react';
import { useCustomer } from '../../../hooks/useCustomer';
import { useLocation } from 'react-router-dom';

const blacklistRoutes = ['/messagerie', '/recapitulatif-paiement'];

function hideChatbot() {
  document
    .getElementById('moustache-ai-chat-icon')
    ?.classList.add('display-none');

  document.getElementById('moustacheAiIframe')?.classList.add('display-none');
}

function showChatbot() {
  document
    .getElementById('moustache-ai-chat-icon')
    ?.classList.remove('display-none');

  document
    .getElementById('moustacheAiIframe')
    ?.classList.remove('display-none');
}

function isBlackListed(pathName: string) {
  return blacklistRoutes.some((r) => pathName.startsWith(r));
}

const MoustacheAIProvider: FC = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const { customer } = useCustomer();
  const location = useLocation();
  const src = 'https://chat.moustacheai.com/chat.mjs';

  useEffect(() => {
    // Toggle show/hide according to blacklisted pages list
    if (isInitialized) {
      if (isBlackListed(location.pathname)) {
        hideChatbot();
      } else {
        showChatbot();
      }
    }
    // Initialize script
    else if (customer && !isBlackListed(location.pathname)) {
      const script = document.createElement('script');
      script.src = src;
      script.type = 'text/javascript';
      script.async = true;
      script.onload = function () {
        (window as any).initMoustacheAI({
          chatbot: '05f539fc-b082-45ee-bb7e-19fb0c134e91',
          userIdentifier: customer.email,
        });
      };

      document.getElementsByTagName('head')[0].appendChild(script);
      setIsInitialized(true);
    }
  }, [customer, location.pathname, isInitialized]);

  // Destroy elements when leaving customer app
  useEffect(() => {
    return () => {
      document.querySelector(`script[src="${src}"]`)?.remove();
      document.getElementById('moustache-ai-chat-icon')?.remove();
      document.getElementById('moustacheAiIframe')?.remove();
    };
  }, []);

  return <>{children}</>;
};

export default MoustacheAIProvider;
