import { colors } from 'theme/styles/colors';
import { spacing } from 'theme/styles/spacing';
import { alignments, directions, positions } from 'theme/styles/flex';
import { icons } from 'theme/styles/icons';
import { gutter, height, offset, size, weight } from 'theme/styles/size';
import { font, fontFamily } from 'theme/styles/fonts';
import { breakpoints } from 'theme/styles/breakpoints';

export const theme = {
  colors,
  spacing,
  alignments,
  breakpoints,
  positions,
  directions,
  icons,
  size,
  height,
  weight,
  font,
  fontFamily,
  offset,
  gutter,
};
