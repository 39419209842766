import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { theme } from 'theme';

import { Container, Flex, Text } from 'components/Layout';
import { useCustomer } from '../../hooks/useCustomer';
import { CustomerFull, ProcedureSpouseFull } from '../../types/resources';
import { useApi } from '../../hooks/useApi';
import { Card } from '../../components/Cards';
import { useTranslation } from 'react-i18next';
import { getRealInitials } from '../../utils/getter';
import { fillPdfFileFromBuffer } from '../../utils/pdf';
import format from 'date-fns/format';
import { PDFViewer } from '../../components/Viewer';
import { Button, IconButton, InlineButton } from '../../components/Buttons';
import { ClientAppRoutes } from '../../ClientApp';
import { useNavigate } from 'react-router-dom';
import { useViewport } from '../../hooks/useViewport';
import toast from 'react-hot-toast';

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 100%;
  background-color: ${theme.colors.salmon1};
`;

type LawyerFeesExplanationCardProps = {
  onClick: () => void;
};

const LawyerFeesExplanationCard: FC<LawyerFeesExplanationCardProps> = ({
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Flex marginBottom={{ xs: 'space16' }}>
        <Text
          content={t('lawyer_fees.explanation.title')}
          fontStyle="heading4"
        />
      </Flex>

      <Flex marginBottom={{ xs: 'space16' }}>
        <Flex alignItems={'center'}>
          <IconButton
            style={{ marginRight: theme.spacing.space16 }}
            iconName={'InfoCircle'}
            color={theme.colors.salmon2}
            backgroundColor={theme.colors.salmon3}
            rounded
            stroke="regular"
          />
        </Flex>
        <Text
          fontStyle={'body2'}
          color={theme.colors.gray6}
          style={{ lineHeight: '1.2rem' }}
          content={t('lawyer_fees.explanation.subtitle')}
        />
      </Flex>

      <Text
        fontStyle="heading6"
        content={t('lawyer_fees.explanation.steps')}
        marginBottom={{ xs: 'space16' }}
      />

      {[1, 2, 3].map((number) => (
        <Flex key={number} marginBottom={{ xs: 'space16' }}>
          <Flex marginRight={{ xs: 'space8' }}>
            <Circle>
              <Text
                fontStyle="heading6"
                content={number.toString()}
                color={'white'}
              />
            </Circle>
          </Flex>

          <Text
            fontStyle={'body2'}
            color={theme.colors.gray6}
            style={{ lineHeight: '1.2rem' }}
            content={t(`lawyer_fees.explanation.bullet.${number}`)}
          />
        </Flex>
      ))}

      <Button
        fullWidth
        content={t(`lawyer_fees.explanation.button`)}
        primary
        onClick={onClick}
        marginTop={{ xs: 'space24' }}
        borderRadius={'20px'}
      />
    </Card>
  );
};

type LawyerFeesSignatureCardProps = {
  onClick: () => void;
  downloaded: boolean;
  onDownload: () => void;
  downloadLoading: boolean;
  loading: boolean;
};

const LawyerFeesSignatureCard: FC<LawyerFeesSignatureCardProps> = ({
  onClick,
  loading,
  onDownload,
  downloadLoading,
  downloaded,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useViewport();

  return (
    <Card>
      <Flex marginBottom={{ xs: 'space16' }}>
        <Text
          content={t('lawyer_fees.explanation.title')}
          fontStyle="heading4"
        />
      </Flex>

      <Text
        fontStyle={'body2'}
        color={theme.colors.gray6}
        style={{ lineHeight: '1.2rem' }}
        content={t(`lawyer_fees.signature.subtitle`)}
      />

      {isMobile && (
        <Button
          fullWidth
          content={t(`lawyer_fees.download.button`)}
          primary={!downloaded}
          iconLeft={{ name: 'Download' }}
          $loading={downloadLoading}
          onClick={onDownload}
          marginTop={{ xs: 'space24' }}
          borderRadius={'20px'}
        />
      )}

      <Button
        fullWidth
        content={t(`lawyer_fees.signature.button`)}
        primary={!isMobile || (isMobile && downloaded)}
        $loading={loading}
        onClick={onClick}
        marginTop={{ xs: 'space24' }}
        borderRadius={'20px'}
      />
    </Card>
  );
};

type LawyerFeesSignedCardProps = {
  onClick: () => void;
};

const LawyerFeesSignedCard: FC<LawyerFeesSignedCardProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Flex marginBottom={{ xs: 'space16' }}>
        <Text content={t('lawyer_fees.signed.title')} fontStyle="heading4" />
      </Flex>

      <Text
        fontStyle={'body2'}
        color={theme.colors.gray6}
        style={{ lineHeight: '1.2rem' }}
        content={t(`lawyer_fees.signed.subtitle`)}
      />

      <Button
        fullWidth
        content={t(`lawyer_fees.signed.button`)}
        primary
        onClick={onClick}
        marginTop={{ xs: 'space24' }}
        borderRadius={'20px'}
      />
    </Card>
  );
};

const CustomContainer = styled(Container)`
  padding-top: ${theme.spacing.space32};
  padding-bottom: ${theme.spacing.space32};
  height: calc(100vh - 100px);
`;

const PreviewWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const ClientLawyerFees: FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const { customer } = useCustomer();
  const navigate = useNavigate();

  const fileName = 'convention_honoraires.pdf';

  const [readToSign, setReadToSign] = useState<boolean>(false);
  const [downloaded, setDownloaded] = useState<boolean>(false);
  const [filledFeesBuffer, setFilledFeesBuffer] = useState<ArrayBuffer | null>(
    null,
  );

  const { execute: signLawyerFees, state: signLawyerFeesState } = useApi(
    `/procedures/${customer?.procedure_id}/spouses/${customer?.id}/lawyer-fees`,
    { method: 'PATCH' },
  );
  const signLoading = signLawyerFeesState.loading;

  const { execute: getProcedureSpouse, state: getProcedureSpouseState } =
    useApi<ProcedureSpouseFull>(
      `/procedures/${customer?.procedure_id}/spouses/${customer?.id}`,
    );
  const spouse = getProcedureSpouseState.data?.value;

  const {
    execute: downloadLawyerFeesFile,
    state: downloadLawyerFeesFileState,
  } = useApi<{ url: string }>(
    `/procedures/${customer?.procedure_id}/spouses/${customer?.id}/lawyer-fees`,
  );
  const feesFileUrl = downloadLawyerFeesFileState.data?.value.url;

  const onReadyToSign = () => {
    setReadToSign(true);
  };

  const goToDocuments = () => {
    navigate(ClientAppRoutes.ADMINISTRATIVE_DOCUMENTS);
  };

  const onSign = useCallback(() => {
    if (customer?.procedure_id && customer.id && filledFeesBuffer) {
      const file = new File([filledFeesBuffer], fileName, {
        type: 'application/pdf',
      });
      const body = new FormData();
      body.set('signed_lawyer_fees', file);

      signLawyerFees({
        body,
        onSuccess: () => getProcedureSpouse(),
      });
    }
  }, [customer?.procedure_id, customer?.id, filledFeesBuffer]);

  const onDownload = useCallback(() => {
    if (!filledFeesBuffer) return;

    const blob = new Blob([filledFeesBuffer]);
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', fileName);
    link.click();

    setDownloaded(true);
  }, [filledFeesBuffer]);

  const fetchAndFillPdf = async (
    customer: CustomerFull,
    feesFileUrl: string,
  ) => {
    try {
      const res = await fetch(feesFileUrl);
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }

      const emptyFileBuffer = await res.arrayBuffer();
      const value = await fillPdfFileFromBuffer(emptyFileBuffer, {
        nomcomplet: customer.full_name,
        signature: customer.full_name,
        paraphe: getRealInitials(customer.first_name, customer.last_name),
        datesignature: format(new Date(), 'dd/MM/yyyy'),
        email: customer.email,
      });

      setFilledFeesBuffer(value.buffer);
    } catch (error) {
      toast.error(t('errors.document_load'));
    }
  };

  useEffect(() => {
    if (customer?.procedure_id) {
      getProcedureSpouse();
    }
  }, [customer?.procedure_id]);

  useEffect(() => {
    if (spouse?.lawyer?.fees_file_id) {
      downloadLawyerFeesFile();
    }
  }, [spouse?.lawyer?.fees_file_id]);

  useEffect(() => {
    if (customer && feesFileUrl) {
      void fetchAndFillPdf(customer, feesFileUrl);
    }
  }, [customer, feesFileUrl]);

  if (!filledFeesBuffer) return null;

  return (
    <CustomContainer fluid="xl">
      <Flex marginBottom={{ xs: 'space24' }} mobileOnly>
        <InlineButton
          iconName="ArrowLeft"
          iconColor={theme.colors.salmon2}
          backgroundColor={theme.colors.salmon3}
          text={t('back_folder')}
          onClick={() => {
            navigate('/');
          }}
          hoverBackgroundColor={theme.colors.salmon2}
          hoverIconColor={theme.colors.white}
        />
      </Flex>

      <Flex
        height={'100%'}
        width={'100%'}
        direction={{ xs: 'column', lg: 'row' }}
        style={{ gap: theme.spacing.space32 }}
      >
        <Flex direction={{ xs: 'column' }} flex={isMobile ? 0 : 1}>
          {!readToSign && !spouse?.signed_lawyer_fees_id && (
            <LawyerFeesExplanationCard onClick={onReadyToSign} />
          )}
          {readToSign && !spouse?.signed_lawyer_fees_id && (
            <LawyerFeesSignatureCard
              onClick={onSign}
              loading={signLoading}
              downloaded={downloaded}
              onDownload={onDownload}
              downloadLoading={downloadLawyerFeesFileState.loading}
            />
          )}
          {spouse?.signed_lawyer_fees_id && (
            <LawyerFeesSignedCard onClick={goToDocuments} />
          )}
        </Flex>

        {!isMobile && (
          <Flex flex={2}>
            <PreviewWrapper>
              {(readToSign || spouse?.signed_lawyer_fees_id) && (
                <PDFViewer
                  src={URL.createObjectURL(new Blob([filledFeesBuffer]))}
                  fileName={fileName}
                  id={Date.now().toString()}
                />
              )}
            </PreviewWrapper>
          </Flex>
        )}
      </Flex>
    </CustomContainer>
  );
};

export default ClientLawyerFees;
