import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import { Button } from 'components/Buttons/Button';
import Table from 'components/Table/Table';
import { Flex } from 'components/Layout/Flex';

import { AdminAppRoutes } from 'AdminApp';
import { getRouteWithParams } from 'utils/router';
import { useApi } from '../../../hooks/useApi';
import format from 'date-fns/format';
import { Estimate, Prospect, SortDirection } from '../../../types/resources';
import { ColumnDef } from '@tanstack/react-table';

type ProspectEstimatesTableProps = {
  prospect: Prospect;
};

const ProspectEstimatesTable: FC<ProspectEstimatesTableProps> = ({
  prospect,
}) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [sortKey, setSortKey] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<SortDirection | undefined>(
    undefined,
  );
  const navigate = useNavigate();
  const pageSize = 3;

  const {
    execute: getEstimates,
    state: { data },
  } = useApi<Estimate[]>(`/estimates`);

  const estimates = data?.value;

  useEffect(() => {
    getEstimates({
      query: {
        prospect_id: prospect.id,
        page: page !== undefined ? page + 1 : 1,
        items: pageSize,
        sort: sortKey,
        direction: sortDirection,
      },
    });
  }, [page, sortKey, sortDirection]);

  const columns = useMemo<ColumnDef<Estimate>[]>(
    () => [
      {
        header: t('estimate.name'),
        accessorKey: 'name',
        enableSorting: false,
        size: 250,
      },
      {
        header: t('estimate.price'),
        accessorKey: 'price',
        size: 100,
        cell: ({ row }) => `${row.original.price} €`,
      },
      {
        header: t('estimate.created_at'),
        accessorKey: 'created_at',
        cell: ({ row }) =>
          format(new Date(row.original.created_at), "dd/MM/YYY 'à' HH:mm"),
      },
      {
        header: t('estimate.updated_at'),
        accessorKey: 'updated_at',
        cell: ({ row }) =>
          format(new Date(row.original.updated_at), "dd/MM/YYY 'à' HH:mm"),
      },
      {
        header: '',
        accessorKey: 'id',
        size: 100,
        enableSorting: false,
        cell: ({ row }) => (
          <Flex justify="end" expand>
            <Button
              content={t('view')}
              size="small"
              primary
              onClick={() =>
                navigate(
                  getRouteWithParams(AdminAppRoutes.ESTIMATES_DETAILS, {
                    id: row.original.id,
                  }),
                )
              }
            />
          </Flex>
        ),
      },
    ],
    [history],
  );

  return <Table data={estimates || []} columns={columns} />;
};

export default ProspectEstimatesTable;
