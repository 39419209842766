import { FC } from 'react';

// App
import AdminApp from 'AdminApp';
import ClientApp from 'ClientApp';
import PublicApp from 'PublicApp';

// Components
import FullLoader from 'components/Loader/FullLoader';
import { ScrollToTop } from 'components/Misc';

import analytics from 'utils/analytics';
import { useAuth } from './hooks/useAuth';

const AppRouter: FC = () => {
  const { user, isLoading } = useAuth();

  if (user) {
    analytics
      ?.identify(
        user.id,
        {
          id: user.id,
          email: user.email,
          name: user.full_name,
          role: user.role,
        },
        {
          plugins: {
            all: false,
          },
        },
      )
      .then();
  }

  if (isLoading) {
    return <FullLoader />;
  }

  return (
    <>
      <ScrollToTop />
      {user ? (
        user.role === 'CUSTOMER' ? (
          <ClientApp />
        ) : (
          <AdminApp />
        )
      ) : (
        <PublicApp />
      )}
    </>
  );
};

export default AppRouter;
