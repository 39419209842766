import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Components
import { theme } from 'theme';
import { Col, Container, Flex, Line, Row, Text } from 'components/Layout';
import { Button, IconButton } from 'components/Buttons';
import { Permission } from 'components/User';
import { Chip } from 'components/Cards';

import { PersonalInformations } from 'container/client';

import { useNavigate } from 'react-router-dom';
import { useViewport } from 'hooks/useViewport';
import { ClientAppRoutes } from 'ClientApp';
import { useAuth } from '../../hooks/useAuth';
import { useApi } from '../../hooks/useApi';
import { Role, User } from '../../types/resources';
import ChangePasswordForm from '../../container/client/User/ChangePasswordForm';

const CustomContainer = styled(Container)`
  padding-top: ${theme.spacing.space56};
  padding-bottom: ${theme.spacing.space32};
`;

const Account: FC = () => {
  const { logout } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useViewport();
  const { execute: meRequest, state: meState } = useApi<User>('/auth/user');
  const me = meState.data?.value;

  useEffect(() => {
    meRequest();
  }, []);

  if (!me) return null;

  return (
    <>
      <Permission roles={[Role.CUSTOMER]}>
        <CustomContainer fluid={'xl'}>
          <Row marginBottom={{ xs: 'space40' }}>
            <Col xs={12}>
              <Flex justify="between" alignItems="center" width="100%">
                <Text content={t('user.my_profile')} fontStyle="heading2" />
                <Button
                  content={t('logout')}
                  iconRight={{ name: 'Logout' }}
                  primary
                  onClick={() => {
                    logout();
                  }}
                />
              </Flex>
            </Col>
          </Row>
          <Row marginBottom={{ xs: 'space40' }}>
            <Col xs={12}>
              <Line />
            </Col>
          </Row>
          <Row marginBottom={{ xs: 'space40' }}>
            <Col xs={12}>
              <PersonalInformations user={me} />
            </Col>
          </Row>
          <Row marginBottom={{ xs: 'space40' }}>
            <Col xs={12}>
              <Line />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={8} vertical>
              <Flex alignItems="center">
                <IconButton
                  iconName="Danger"
                  rounded
                  backgroundColor={theme.colors.red1}
                  color={theme.colors.white}
                />
                <Text
                  content={t('user.abandon_opportunity')}
                  fontStyle="heading5"
                  marginLeft={{ xs: 'space16' }}
                />
              </Flex>
              <Text
                content={t('user.abandon_opportunity_warning')}
                fontStyle="body2"
                marginTop={{ xs: 'space24' }}
              />
            </Col>
            <Col
              xs={12}
              md={4}
              justify="end"
              align="center"
              marginTop={{ xs: 'space16', md: 'none' }}
            >
              <Button
                content={t('user.abandon_opportunity')}
                iconRight={{ name: 'Danger' }}
                onClick={() => {
                  navigate(ClientAppRoutes.MESSAGING, {
                    state: {
                      defaultMessage: t(
                        'user.abandon_opportunity_message',
                      ) as string,
                    },
                  });
                }}
                fullWidth={isMobile}
              />
            </Col>
          </Row>
        </CustomContainer>
      </Permission>

      <Permission roles={[Role.ADMIN, Role.MANAGER, Role.LAWYER, Role.SALES]}>
        <Row marginBottom={{ xs: 'space48' }}>
          <Col xs={12} vertical>
            <Text
              fontStyle="heading2"
              weight="bold"
              marginBottom={{ xs: 'space24' }}
              content={t('user.title')}
            />
            <Chip content={t(`role.${me.role.toLocaleLowerCase()}`)} />
          </Col>
        </Row>
        <Row marginBottom={{ xs: 'space16' }}>
          <Col xs={12}>
            <PersonalInformations user={me} editable />
          </Col>
        </Row>
        <Row marginBottom={{ xs: 'space16' }}>
          <Col xs={12}>
            <ChangePasswordForm />
          </Col>
        </Row>
      </Permission>
    </>
  );
};

export default Account;
