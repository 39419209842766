import { FC, useMemo } from 'react';

import { Col, Row } from 'components/Layout';
import { PaymentCard, PriceCard } from 'components/Cards';
import Ghost from 'components/Loading/Ghost';
import { getPricePerSpouse } from 'utils/math';
import { formatPrice } from 'utils/format';
import { Estimate, EstimateStatus } from '../../../types/resources';

export interface PricesProps {
  fullyPaid: boolean;
  priceTwelveTimes: string;
  priceFourTimes: string;
  pricePerSpouse: string;
  totalPrice: number;
}

interface PriceResumeProps {
  data?: Estimate;
  loading: boolean;
}

const PriceResume: FC<PriceResumeProps> = ({ data, loading }) => {
  const prices: PricesProps = useMemo(() => {
    return {
      fullyPaid:
        data?.price === data?.amount_paid &&
        data?.status === EstimateStatus.WON,
      priceTwelveTimes: formatPrice(parseInt(`${data?.price}`) / 12 / 2),
      priceFourTimes: formatPrice(parseInt(`${data?.price}`) / 4 / 2),
      pricePerSpouse: getPricePerSpouse(data?.price || 0),
      totalPrice: data?.price || 0,
    };
  }, [data]);

  if (!data || loading)
    return (
      <Row marginBottom={{ xs: 'space16', md: 'space24' }}>
        <Col xs={12} md={6} marginBottom={{ xs: 'space16', md: 'none' }}>
          <Ghost width="100%" height={348} shape="rect" rx={24} />
        </Col>
        <Col xs={12} md={6}>
          <Ghost width="100%" height={348} shape="rect" rx={24} />
        </Col>
      </Row>
    );

  return (
    <Row marginBottom={{ xs: 'space16', md: 'space24' }}>
      <Col xs={12} md={6}>
        <PriceCard prices={prices} />
      </Col>
      <Col xs={12} md={6}>
        <PaymentCard prices={prices} />
      </Col>
    </Row>
  );
};

export default PriceResume;
