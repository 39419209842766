import { env } from '../config/env';
import {
  FetchOptions,
  RequestError,
  useFetch,
  UseFetchReturn,
} from './useFetch';
import { useContext, useRef } from 'react';
import { AuthContext } from '../context';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

export function useApi<T = unknown>(
  endpoint: string,
  options?: FetchOptions<T>,
): UseFetchReturn<T> {
  const { t } = useTranslation();
  const { setUser } = useContext(AuthContext);

  return useFetch<T>(`${env.api.url}/api`, endpoint, {
    ...(options ?? {}),
    onError: (err) => {
      if (err instanceof RequestError && err.response.status === 401) {
        setUser(null);
        localStorage.setItem('user', JSON.stringify(null));
        options?.onError
          ? options.onError(err)
          : toast.error(t('errors.unauthenticated'));
      } else if (err instanceof RequestError && err.response.status === 403) {
        options?.onError
          ? options.onError(err)
          : toast.error(t('errors.forbidden'));
      } else if (err instanceof RequestError && err.response.status >= 500) {
        options?.onError
          ? options.onError(err)
          : toast.error(t('errors.server'));
      } else if (err instanceof TypeError) {
        options?.onError
          ? options.onError(err)
          : toast.error(t('errors.no_connectivity'));
      } else {
        options?.onError
          ? options.onError(err)
          : toast.error(t('errors.unknown'));
      }
    },
  });
}

type PromiseHandlers<T> = {
  resolve: (value: T) => void;
  reject: (error: any) => void;
};

export const useApiAsync = <T,>(
  endpoint: string,
  options?: FetchOptions<T>,
) => {
  const promiseHandlersRef = useRef<PromiseHandlers<T> | null>(null);

  const { execute, state } = useApi<T>(endpoint, {
    ...options,
    onSuccess: async (data) => {
      promiseHandlersRef.current?.resolve(data.value);
      await options?.onSuccess?.(data);
    },
    onError: (error) => {
      promiseHandlersRef.current?.reject(error);
      options?.onError?.(error);
    },
  });

  const executeAsync = async (
    requestOptions?: Parameters<typeof execute>[0],
  ) => {
    return new Promise<T>((resolve, reject) => {
      promiseHandlersRef.current = { resolve, reject };
      execute(requestOptions);
    });
  };

  return {
    execute: executeAsync,
    state,
  };
};
