import React, { FC, ReactNode } from 'react';
import { Row as BootstrapRow } from 'react-bootstrap';
import styled from 'styled-components';
import { theme } from 'theme';
import { Alignments, Positions } from 'theme/styles/flex';
import { lg } from 'theme/styles/mediaQueries';
import {
  BreakpointsAlignments,
  BreakpointsSpacing,
  ConvertedAlignments,
  convertSpacing,
  getAlignments,
  printBreakpointRules,
} from 'theme/styles/responsive';

// Props
export interface RowProps {
  position?: Positions;
  align?: Alignments;
  justify?: Alignments;
  wrap?: string;
  marginBottom?: BreakpointsSpacing;
  marginTop?: BreakpointsSpacing;
  marginRight?: BreakpointsSpacing;
  marginLeft?: BreakpointsSpacing;
  alignBreakpoints?: BreakpointsAlignments;
  overflowX?: string;
  fullHeight?: boolean;
  fullWidth?: boolean;
  children: ReactNode;
  noGutters?: boolean;
  className?: string;
}

// Styled row props
interface StyledRowProps {
  position?: string;
  wrap?: string;
  overflowX?: string;
  alignBreakpoints?: ConvertedAlignments;
  fullHeight?: boolean;
  fullWidth?: boolean;
  children: ReactNode;
}

// Styled row
const StyledRow = styled(BootstrapRow)<StyledRowProps>`
  position: ${(props) => props.$position};
  justify-content: ${(props) => props.$justification};
  align-items: ${(props) => props.$alignment};
  flex-wrap: ${(props) => props.$wrap};
  overflow-x: ${(props) => props.$overflowX};
  ${(props) => props.$fullHeight && 'height: 100%'};

  ${(props) => printBreakpointRules(props.$marginTop)};
  ${(props) => printBreakpointRules(props.$marginBottom)};
  ${(props) => printBreakpointRules(props.$marginLeft)};
  ${(props) => printBreakpointRules(props.$marginRight)};
  ${(props) => printBreakpointRules(props.$alignBreakpoints)};

  ${(props) =>
    lg(`
      ${props.$fullWidth && 'width: 100%'};
    `)}
`;

// Row
export const Row: FC<RowProps> = ({
  position,
  align,
  justify,
  alignBreakpoints,
  wrap,
  marginBottom,
  marginTop,
  marginLeft,
  marginRight,
  overflowX = 'none',
  fullHeight = false,
  fullWidth,
  children,
  noGutters,
  className,
}) => {
  return (
    <StyledRow
      $alignBreakpoints={getAlignments(alignBreakpoints)}
      $position={position && theme.positions[position]}
      $justification={theme.alignments[justify || 'start']}
      $alignment={theme.alignments[align || 'start']}
      $wrap={wrap}
      $marginTop={convertSpacing('margin-top', marginTop)}
      $marginBottom={convertSpacing('margin-bottom', marginBottom)}
      $marginLeft={convertSpacing('margin-left', marginLeft)}
      $marginRight={convertSpacing('margin-right', marginRight)}
      $overflowX={overflowX}
      $fullHeight={fullHeight}
      $fullWidth={fullWidth}
      noGutters={noGutters}
      className={className}
    >
      {children}
    </StyledRow>
  );
};
