import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconlyIcon } from 'types/react-iconly';
import styled from 'styled-components';

import { ClientCard } from 'components/Cards';

const Card = styled(ClientCard)`
  height: auto;
`;

const AdministrativeDocumentsCard: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();
  const buttons = [
    {
      content: t('opportunity.see_all_administrative_documents'),
      primary: true,
      iconRight: { name: IconlyIcon.ArrowRightSquare },
      fullWidth: true,
    },
  ];
  const onClick = () => {
    navigate({
      pathname: location.pathname,
      search: '?view=administrative-documents',
    });
  };
  return (
    <Card
      iconName={IconlyIcon.Folder}
      title={t('administrative_documents.documents')}
      buttons={buttons}
      onClick={onClick}
    />
  );
};

export default AdministrativeDocumentsCard;
