import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Components
import { Info } from 'components/Text/Info';
import { Flex } from 'components/Layout/Flex';
import { Col, Container, Row } from 'components/Layout';

import { PriceResume, ResumeFooter } from 'container/public';
import { useNavigate, useParams } from 'react-router-dom';
import RecapSituationQuote from './RecapSituationQuote';
import { Page404 } from 'pages/common';
import analytics from 'utils/analytics';
import { Button } from 'components/Buttons';
import { useAuth } from '../../../hooks/useAuth';
import {
  EstimateFull,
  Option,
  Plan,
  Product,
  ProductType,
  Role,
} from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';

// Placeholder for GhostForm height in container
const GhostFormFooter = styled.div`
  height: 87px;
`;

function isOption(product: Product): product is Option {
  return product.type === ProductType.OPTION;
}

function isPlan(product: Product): product is Plan {
  return product.type === ProductType.PLAN;
}

const QuoteResume: FC = () => {
  const { t } = useTranslation();

  const { id: estimateId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuth();
  const isAdmin = user?.role === Role.ADMIN;
  const { execute: getProducts, state: getProductsState } = useApi<Product[]>(
    '/products',
    { cache: true },
  );
  const products = getProductsState.data?.value ?? [];
  const options = products.filter(isOption) ?? [];
  const plans = products.filter(isPlan) ?? [];
  const loading = false;

  const {
    execute: getEstimate,
    state: { error, data },
  } = useApi<EstimateFull>(`/estimates/${estimateId}`);
  const estimate = data?.value;

  useEffect(() => {
    analytics?.track('devis_step_recapitulatif');
    getEstimate();
    getProducts({ query: { enabled: true, sort: 'price', direction: 'asc' } });
  }, []);

  if (error) {
    return <Page404 isAdmin={false} full />;
  }

  return (
    <>
      <Container fluid="xl">
        <Row
          marginBottom={{ xs: 'space40' }}
          marginTop={
            !isAdmin ? { xs: 'space40', md: 'space72' } : { xs: 'none' }
          }
        >
          {isAdmin ? (
            <Button
              content="Retour"
              iconLeft={{ name: 'ArrowLeft' }}
              size="small"
              onClick={() => navigate(-1)}
            />
          ) : (
            <Col xs={12} md={6}>
              <Flex direction={{ xs: 'column' }}>
                <Info
                  message={t('emma.from')}
                  displayTitle={true}
                  displayImage={true}
                  description={t('quote.resume.info_description')}
                />
              </Flex>
            </Col>
          )}
        </Row>
        <PriceResume data={estimate} loading={loading} />
        <RecapSituationQuote
          options={options}
          plans={plans}
          products={products}
          data={estimate}
          loading={loading}
          refetch={getEstimate}
        />
        <GhostFormFooter />
      </Container>
      {!isAdmin && <ResumeFooter data={estimate} loading={loading} />}
    </>
  );
};

export default QuoteResume;
