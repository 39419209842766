import { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Container
import { PaymentsList, PaymentsListFilters } from 'container/admin';

// Components
import { Flex } from 'components/Layout/Flex';
import { Text } from 'components/Layout/Text';
import { Button } from '../../components/Buttons';
import { AdminAppRoutes } from '../../AdminApp';
import { useNavigate } from 'react-router-dom';
import { PaymentFull } from '../../types/resources';
import { useApi } from '../../hooks/useApi';
import { SearchParamsProvider } from '../../context';

export type PaymentsFilters = {
  sort: string;
  direction: string;
  page?: number;
  customer_id?: string;
  estimate_id?: string;
  status?: string;
};

const Payments: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    execute: getPayments,
    state: { data },
  } = useApi<PaymentFull[]>('/payments');
  const payments = data?.value ?? [];
  const total = data?.pagination?.total ?? 0;

  return (
    <SearchParamsProvider<PaymentsFilters>>
      <Flex justify="between">
        <Text
          content={`${t('payments.title')} (${total})`}
          fontStyle="heading2"
        />
        <Button
          content={t('payments.add.new')}
          iconLeft={{ name: 'Plus' }}
          onClick={() => navigate(AdminAppRoutes.PAYMENTS_ADD)}
        />
      </Flex>
      <PaymentsListFilters />
      <Flex marginTop={{ xs: 'space32' }}>
        <PaymentsList
          pageSize={10}
          fetch={getPayments}
          items={payments}
          total={total}
        />
      </Flex>
    </SearchParamsProvider>
  );
};

export default Payments;
