import { FC } from 'react';

import InputSelect from 'components/FormTemplate/Fields/InputSelect';

interface Option {
  value: string;
  label: string;
}

interface DropdownProps {
  options: Option[];
  id: string;
  onChange: (value: string) => void;
}

const SelectDropdown: FC<DropdownProps> = ({ options, id, onChange }) => {
  const currentOption = options.find((option) => {
    return option.value === id;
  });

  return (
    <InputSelect
      options={options}
      onChange={onChange}
      placeholder={currentOption?.label}
      value={currentOption?.value}
    />
  );
};

export default SelectDropdown;
