import { FC } from 'react';

// Components
import { theme } from 'theme';
import SingleForm from 'container/client/SingleForm/SingleForm';
import { Button } from 'components/Buttons';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AdminAppRoutes } from 'AdminApp';

const Form: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      {location.pathname === AdminAppRoutes.CLIENT_FORM && (
        <Button
          content={t('back_to_verify')}
          iconLeft={{ name: 'ArrowLeft' }}
          backgroundColor={theme.colors.white}
          textColor={theme.colors.black}
          borderColor={theme.colors.gray3}
          size="small"
          onClick={() => navigate(-1)}
        />
      )}
      <SingleForm />
    </>
  );
};

export default Form;
