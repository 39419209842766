import { FC, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

// Component
import { Flex, Text } from 'components/Layout';
import { ConfirmForm } from 'components/ConfirmPopin/ConfirmPopin';
import { Modal } from 'components/Modal';
import { Button } from 'components/Buttons';

//data
import { Breadcrumbs } from 'container/admin';

// Utils
import { AdminAppRoutes } from 'AdminApp';
import { theme } from 'theme';
import { useApi } from '../../hooks/useApi';
import InputSelectAsync from '../../components/FormTemplate/Fields/InputSelectAsync';
import { Customer, Estimate, PaymentStatus } from '../../types/resources';
import InputSelect from '../../components/FormTemplate/Fields/InputSelect';
import { InputCurrency } from '../../components/FormTemplate/Fields/InputCurrency';
import { InputText } from '../../components/FormTemplate/Fields/InputText';

const FullWidth = styled.div`
  grid-column: 1/-1;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 38px 32px;
  width: 928px;
  margin-top: ${theme.spacing.space48};
  margin-bottom: ${theme.spacing.space32};
`;

type CreatePaymentInput = {
  customer_id: string;
  estimate_id: string;
  amount: number;
  status: PaymentStatus;
  alma_id?: string;
  paypal_id?: string;
};

const PaymentsAdd: FC = () => {
  const {
    register,
    formState: { errors },
    control,
    watch,
    handleSubmit,
    setValue,
  } = useForm<CreatePaymentInput>();
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [result, setResult] = useState<CreatePaymentInput>();
  const navigate = useNavigate();

  const paymentStatusOptions = Object.values(PaymentStatus).map((status) => ({
    value: status,
    label: t(`payments.statuses.${status}`),
  }));

  const { execute: getCustomers } = useApi<Customer[]>(
    '/customers?sort=full_name&direction=asc',
  );

  const { execute: getEstimates, state: getEstimatesState } =
    useApi<Estimate[]>('/estimates');

  const estimates = getEstimatesState.data?.value ?? [];

  const estimateOptions = estimates.map((e) => ({
    value: e.id,
    label: `${e.name} (${e.price}€)`,
  }));

  const onSubmit = (data: CreatePaymentInput) => {
    setResult(data);
    setModalIsOpen(true);
  };

  const onClose = () => {
    setModalIsOpen(false);
  };

  const {
    execute: createPaymentRequest,
    state: { loading },
  } = useApi('/payments', { method: 'POST' });

  const goBack = () => {
    navigate(AdminAppRoutes.PAYMENTS);
  };

  const createPayment = async () => {
    if (result) {
      createPaymentRequest({
        body: {
          ...result,
          paypal_id: result.paypal_id || undefined,
          alma_id: result.alma_id || undefined,
        },
        onSuccess: () => {
          goBack();
        },
      });
    }
  };

  const handleCustomerChange = (val?: Customer['id']) => {
    getEstimates({
      query: { customer_id: val ? val : undefined },
      onSuccess: (result) => {
        if (result.value.length === 1) {
          setValue('estimate_id', result.value[0].id);
        }
      },
    });
  };

  const handleCustomerSearch = (
    val: string,
    callback: (options: any[]) => void,
  ) => {
    const query: Record<string, string> = {};
    if (val) {
      query.search = val;
    }

    getCustomers({
      query,
      onSuccess: (result) => {
        callback(
          result.value.map((c) => ({
            value: c.id,
            label: c.full_name,
          })),
        );
      },
    });
  };

  return (
    <Container>
      <Modal opened={modalIsOpen} onClose={onClose}>
        <ConfirmForm
          title={t('payments.add.confirm_title')}
          description={t('payments.add.confirm_description')}
          confirmText={t('payments.add.confirm')}
          handleSubmit={createPayment}
          setModalIsOpen={setModalIsOpen}
          loading={loading}
        />
      </Modal>
      <Breadcrumbs />
      <Flex direction={{ xs: 'row' }} alignItems="center">
        <Text
          content={t('payments.add.title')}
          fontStyle="heading2"
          weight="bold"
          marginRight={{ xs: 'space32' }}
        />
      </Flex>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="customer_id"
          rules={{ required: t('error.form.required').toString() }}
          render={({ field, fieldState: { error } }) => (
            <InputSelectAsync
              label={t('payments.customer')}
              placeholder={t('payments.customer')}
              loadOptions={handleCustomerSearch}
              defaultOptions
              error={error}
              {...field}
              onChange={(e) => {
                field.onChange(e);
                handleCustomerChange(e);
              }}
              required
            />
          )}
        />

        <Controller
          control={control}
          name="estimate_id"
          rules={{ required: t('error.form.required').toString() }}
          render={({ field, fieldState: { error } }) => (
            <InputSelect
              key={Date.now()}
              label={t('payments.estimate')}
              placeholder={t('payments.estimate')}
              options={estimateOptions}
              error={error}
              {...field}
              disabled={!watch('customer_id')}
              required
            />
          )}
        />

        <InputText
          {...register('paypal_id')}
          error={errors.paypal_id}
          label={t('payments.add.paypal_id')}
          placeholder={t('payments.add.paypal_id')}
          width="100%"
          disabled={!watch('estimate_id')}
        />
        <InputText
          {...register('alma_id')}
          error={errors.alma_id}
          label={t('payments.add.alma_id')}
          placeholder={t('payments.add.alma_id')}
          width="100%"
          disabled={!watch('estimate_id')}
        />

        <Controller
          control={control}
          name="amount"
          rules={{
            required: t('error.form.required').toString(),
          }}
          render={({ field, fieldState: { error } }) => (
            <InputCurrency
              {...field}
              value={String(field.value)}
              onChange={(value) => {
                setValue(`amount`, value);
              }}
              error={error}
              label={t('payments.amount')}
              placeholder={t('payments.amount')}
              width="100%"
              disabled={!watch('estimate_id')}
              required
            />
          )}
        />

        <Controller
          control={control}
          name="status"
          rules={{ required: t('error.form.user_role').toString() }}
          render={({ field, fieldState: { error } }) => (
            <InputSelect
              label={t('payments.status')}
              placeholder={t('payments.status')}
              options={paymentStatusOptions}
              error={error}
              {...field}
              onChange={(e) => {
                field.onChange(e);
              }}
              required
            />
          )}
        />

        <FullWidth>
          <Flex>
            <Button
              onClick={goBack}
              content={t('back')}
              marginRight={{ sm: 'space24' }}
            />
            <Button
              content={t('payments.add.title')}
              type="submit"
              $loading={loading}
              primary
            />
          </Flex>
        </FullWidth>
      </Form>
    </Container>
  );
};

export default PaymentsAdd;
