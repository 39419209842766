import { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Container
import { CustomersList, CustomersListFilters } from 'container/admin';

// Components
import { Flex } from 'components/Layout/Flex';
import { Text } from 'components/Layout/Text';
import { Button } from '../../components/Buttons';
import { AdminAppRoutes } from '../../AdminApp';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import { Customer } from '../../types/resources';
import { SearchParamsProvider } from '../../context';

export type CustomersFilters = {
  search?: string;
  sort: string;
  direction: string;
  page?: number;
};

const Customers: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    execute: getCustomers,
    state: { data },
  } = useApi<Customer[]>('/customers');
  const customers = data?.value ?? [];
  const total = data?.pagination?.total ?? 0;

  return (
    <SearchParamsProvider<CustomersFilters>>
      <Flex justify="between">
        <Text
          content={`${t('customer.customers')} (${total})`}
          fontStyle="heading2"
        />
        <Button
          content={t('customer.add.new')}
          iconLeft={{ name: 'Plus' }}
          onClick={() => navigate(AdminAppRoutes.CUSTOMERS_ADD)}
        />
      </Flex>
      <CustomersListFilters />
      <Flex marginTop={{ xs: 'space32' }}>
        <CustomersList
          pageSize={10}
          fetch={getCustomers}
          items={customers}
          total={total}
        />
      </Flex>
    </SearchParamsProvider>
  );
};

export default Customers;
