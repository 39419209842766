import React, {
  forwardRef,
  MouseEventHandler,
  PropsWithChildren,
  RefObject,
} from 'react';
import styled from 'styled-components';

// Components
import { theme } from 'theme';
import { Icon, IconProps } from 'components/Images/Icon';

// Utils
import { getColors } from 'utils/layout';

import {
  BreakpointsSpacing,
  ConvertedSpacing,
  convertSpacing,
  printBreakpointRules,
} from 'theme/styles/responsive';
import { Flex } from '../Layout/Flex';
import Loader from 'react-loader-spinner';
import { md } from 'theme/styles/mediaQueries';
import { Spacing } from '../../theme/styles/spacing';

interface ButtonContainerProps {
  id?: string;
  form?: string;
  value?: string | number;
  backgroundColor?: string;
  borderColor?: string;
  textColor?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  bdRadius?: '12px' | '16px' | '20px';
  ftSize?: string;
  size?: 'small' | 'medium';
  marginTop: ConvertedSpacing;
  marginBottom: ConvertedSpacing;
  marginRight: ConvertedSpacing;
  marginLeft: ConvertedSpacing;
  $loading?: boolean;
  boxShadow?: string;
  primary: boolean | undefined;
}

const LoaderWrapper = styled(Loader)`
  position: absolute;
`;

const ButtonContainer = styled.button<ButtonContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  height: auto;
  padding: ${({ size }) => (size === 'small' ? '8px 16px' : '16px 24px')};
  color: ${({ textColor, $loading }) => ($loading ? 'transparent' : textColor)};
  background-color: ${({ backgroundColor }) =>
    backgroundColor && backgroundColor};
  border: 1px solid ${({ borderColor }) => borderColor && borderColor};
  border-radius: ${({ bdRadius }) => bdRadius && bdRadius};
  font-weight: ${theme.weight.bold};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  white-space: nowrap;
  pointer-events: ${(props) => props.disabled && 'none'};
  box-shadow: ${(props) => props.boxShadow && props.boxShadow};
  font-size: ${theme.font.body2.fontSize};
  transition: opacity 0.2s ease-in-out;

  ${(props) =>
    md(`
      font-size: ${props.ftSize};
    `)}

  ${(props) => printBreakpointRules(props.marginTop)};
  ${(props) => printBreakpointRules(props.marginBottom)};
  ${(props) => printBreakpointRules(props.marginRight)};
  ${(props) => printBreakpointRules(props.marginLeft)};

  &:hover {
    opacity: ${(props) => (props.primary ? 0.9 : 0.75)};
  }
`;

export interface ButtonProps {
  id?: string;
  content?: string;
  fullWidth?: boolean;
  form?: string;
  value?: string | number;
  backgroundColor?: string;
  textColor?: string;
  borderColor?: string;
  primary?: boolean;
  disabled?: boolean;
  marginTop?: BreakpointsSpacing;
  marginBottom?: BreakpointsSpacing;
  marginRight?: BreakpointsSpacing;
  marginLeft?: BreakpointsSpacing;
  type?: 'button' | 'submit';
  iconLeft?: IconProps;
  iconRight?: IconProps;
  iconRightMargin?: Spacing;
  borderRadius?: '12px' | '16px' | '20px';
  fontSize?: string;
  size?: 'small' | 'medium';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  $loading?: boolean;
  boxShadow?: string;
  className?: string;
  ref?: RefObject<HTMLButtonElement>;
  hide?: boolean;
}

export const Button = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<ButtonProps>
>(
  (
    {
      id,
      type = 'button',
      form,
      content,
      children,
      value,
      backgroundColor,
      borderColor,
      textColor,
      primary,
      disabled,
      iconRight,
      iconRightMargin = 'space16',
      iconLeft,
      borderRadius,
      fontSize,
      size = 'medium',
      onClick,
      fullWidth,
      marginTop,
      marginBottom,
      marginLeft,
      marginRight,
      $loading = false,
      boxShadow,
      className,
      hide = false,
    },
    ref,
  ) => {
    const { bgColor, bdColor, txtColor } = getColors(
      primary,
      disabled,
      backgroundColor,
      textColor,
      borderColor,
    );
    const bdRadius = borderRadius
      ? borderRadius
      : size === 'small'
      ? '12px'
      : '16px';

    const ftSize = fontSize
      ? fontSize
      : size === 'small'
      ? theme.font.body2.fontSize
      : theme.font.body1.fontSize;

    const getSpinnerColor = () => {
      if (disabled) return theme.colors.black;
      if (primary) return theme.colors.white;
      return theme.colors.salmon1;
    };

    return (
      <ButtonContainer
        ref={ref}
        id={id}
        backgroundColor={bgColor}
        borderColor={bdColor}
        textColor={txtColor}
        type={type}
        form={form}
        onClick={onClick}
        value={value}
        disabled={disabled}
        fullWidth={fullWidth}
        size={size}
        bdRadius={bdRadius}
        ftSize={ftSize}
        marginTop={convertSpacing('margin-top', marginTop)}
        marginBottom={convertSpacing('margin-bottom', marginBottom)}
        marginLeft={convertSpacing('margin-left', marginLeft)}
        marginRight={convertSpacing('margin-right', marginRight)}
        $loading={$loading}
        boxShadow={boxShadow}
        primary={primary}
        className={className}
        hidden={hide}
      >
        {$loading && (
          <LoaderWrapper
            type="TailSpin"
            color={getSpinnerColor()}
            height={24}
            width={24}
          />
        )}
        {iconLeft && (
          <Flex marginRight={{ xs: content ? 'space16' : 'none' }}>
            <Icon {...iconLeft} />
          </Flex>
        )}
        {content ?? children}
        {iconRight && (
          <Flex marginLeft={{ xs: iconRightMargin }}>
            <Icon {...iconRight} />
          </Flex>
        )}
      </ButtonContainer>
    );
  },
);

Button.displayName = 'Button';
