import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// Components
import Table from 'components/Table/Table';

import { TextEllipsis } from 'components/Text';
import { IconButton, OpportunityStatusButton } from 'components/Buttons';
import { getTypeByStatus } from 'utils/status';
import { useNavigate } from 'react-router-dom';
import { Card } from 'components/Cards';
import { Flex, Text } from 'components/Layout';
import { theme } from 'theme';
import { getRouteWithParams } from 'utils/router';
import { AdminAppRoutes } from 'AdminApp';
import { ProcedureFull } from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';
import { ColumnDef } from '@tanstack/react-table';
import { CircleAlert } from 'lucide-react';

const CustomCard = styled(Card)`
  padding: 0;

  tbody {
    max-height: 220px;
  }
`;

const Head = styled(Flex)`
  padding: ${theme.spacing.space16} ${theme.spacing.space24};
`;

const WithErrorOpportunities: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showTable, toggleShowTable] = useState<boolean>(true);

  const { execute: getProcedures, state: getProceduresState } =
    useApi<ProcedureFull[]>(`/procedures/errors`);
  const procedures = getProceduresState.data?.value ?? [];

  useEffect(() => {
    getProcedures();
  }, []);

  const columns = useMemo<ColumnDef<ProcedureFull>[]>(
    () => [
      {
        header: t('opportunity.columns.name') || 'Noms',
        accessorKey: 'reference',
        enableSorting: false,
        cell: ({ row }) => (
          <TextEllipsis
            fontStyle="body2"
            content={`${row.original.reference} - ${row.original.name}`}
            weight="medium"
          />
        ),
      },
      {
        header: t('opportunity.columns.step') || 'Étape du dossier',
        id: 'step.type',
        accessorKey: 'current_step',
        enableSorting: false,
        cell: ({ row }) => (
          <TextEllipsis
            fontStyle="body2"
            content={t(`opportunity.steps.${row.original.current_step.step}`)}
            weight="medium"
          />
        ),
      },
      {
        header: t('opportunity.columns.step_status') || 'Statut',
        id: 'step.status',
        accessorKey: 'current_step',
        enableSorting: false,
        size: 100,
        cell: ({ row }) => (
          <OpportunityStatusButton
            type={getTypeByStatus(row.original.current_step.status)}
            text={t(`enums:${row.original.current_step.status}`)}
          />
        ),
      },
      {
        header: t('opportunity.columns.signature_errors') || 'Erreur Docusign',
        enableSorting: false,
        accessorKey: 'signature_errors',
        size: 100,
        cell: ({ row }) => (
          <div className="tw-flex tw-flex-row items-center tw-gap-2">
            {row.original.signature_errors.map((e, index) => (
              <CircleAlert key={index} size={24} color={theme.colors.red1} />
            ))}
          </div>
        ),
      },
      {
        header: t('opportunity.columns.letter_errors') || 'Erreur AR24',
        enableSorting: false,
        accessorKey: 'letter_errors',
        size: 100,
        cell: ({ row }) => (
          <div className="tw-flex tw-flex-row items-center tw-gap-2">
            {row.original.letter_errors.map((e, index) => (
              <CircleAlert key={index} size={24} color={theme.colors.red1} />
            ))}
          </div>
        ),
      },
    ],
    [history],
  );

  const onRowClick = (opportunity: ProcedureFull) => {
    navigate({
      pathname: getRouteWithParams(AdminAppRoutes.CASES_DETAILS, {
        id: opportunity.id,
      }),
    });
  };

  return (
    <CustomCard>
      <Head
        alignItems="center"
        onClick={() => toggleShowTable(!showTable)}
        cursor="pointer"
      >
        <Flex direction={{ xs: 'column' }}>
          <Flex alignItems="center">
            <IconButton
              iconName="Folder"
              color={theme.colors.salmon2}
              backgroundColor={theme.colors.salmon3}
              rounded
              stroke="regular"
            />
            <Flex alignItems="end">
              <Text
                content={t('opportunity.opportunities_with_errors')}
                fontStyle="heading5"
                marginRight={{ xs: 'space8' }}
                marginLeft={{ xs: 'space8' }}
                style={{ lineHeight: '1.125rem' }}
              />
              <Text
                fontStyle="body3"
                style={{ lineHeight: '0.75rem' }}
                content={t('opportunities', {
                  count: getProceduresState.data?.value.length ?? 0,
                })}
              />
            </Flex>
          </Flex>
        </Flex>
        {/* <Flex marginLeft={{ xs: 'auto' }} marginRight={{ xs: 'none' }}>
          {data && data.opportunities.totalCount > 0 && (
            <Flex>
              <Icon name={showTable ? 'ChevronDown' : 'ChevronUp'} />
            </Flex>
          )}
        </Flex> */}
      </Head>
      <Flex
        paddingLeft={{ xs: 'space24' }}
        paddingRight={{ xs: 'space24' }}
        paddingBottom={{ xs: 'space24' }}
      >
        {showTable && (
          <Table
            loading={getProceduresState.loading}
            data={procedures || []}
            columns={columns}
            onRowClick={onRowClick}
          />
        )}
      </Flex>
    </CustomCard>
  );
};

export default WithErrorOpportunities;
