import React, {
  ChangeEvent,
  forwardRef,
  InputHTMLAttributes,
  MouseEventHandler,
  useRef,
  useState,
} from 'react';

import { FieldError } from 'react-hook-form';
import { Flex } from 'components/Layout/Flex';
import { FontStyles } from 'theme/styles/fonts';
import { Label } from 'components/Text/Label';
import RequiredField from 'components/Forms/Utils/RequiredField';
import { Text } from 'components/Layout/Text';
// Types
import { Weights } from 'theme/styles/size';
// Utils
import { determineIfIsFieldErrorOrString } from 'utils/errors';
import styled from 'styled-components';
// Components
import { theme } from 'theme';
import { Icon, IconProps } from '../../Images/Icon';
import { Spacing } from '../../../theme/styles/spacing';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  $size: Size;
  withError?: boolean;
  width?: string;
}

const Input = styled.input<InputProps>`
  display: none;
`;

type Size = 'small' | 'medium';

export interface InputFileProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
  label?: string;
  buttonLabel?: string;
  buttonPrimary?: boolean;
  buttonSize?: 'small' | 'medium';
  buttonIconRight?: IconProps;
  buttonIconRightMargin?: Spacing;
  hideFiles?: boolean;
  placeholder?: string;
  description?: string;
  descriptionFontStyle?: FontStyles;
  $size?: Size;
  width?: string;
  error?: FieldError | string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  required?: boolean;
  labelWeight?: Weights;
  labelUppercase?: boolean;
  labelFontStyle?: FontStyles;
  onChange: (file: File) => void;
  value?: File;
  preview?: string;
}

export const InputFilePreview = forwardRef((props: InputFileProps, ref) => {
  const {
    placeholder,
    label,
    buttonLabel,
    buttonPrimary,
    buttonSize = 'medium',
    buttonIconRight,
    buttonIconRightMargin,
    hideFiles,
    description,
    descriptionFontStyle = 'body2',
    error,
    $size = 'medium',
    required,
    onChange,
    width,
    labelFontStyle,
    labelWeight,
    disabled,
    value,
    ...otherProps
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const [preview, setPreview] = useState<string | null>(
    otherProps.preview ?? null,
  );

  const onClick = () => {
    inputRef.current?.click();
  };

  const _onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (!file) {
      return;
    }

    onChange(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Flex direction={{ xs: 'column' }} width="100%">
      {label && (
        <Flex marginBottom={{ xs: 'space8' }}>
          <RequiredField required={required}>
            <Label
              dangerouslySetInnerHTML={{ __html: label }}
              fontStyle={labelFontStyle}
              weight={labelWeight}
            />
          </RequiredField>
        </Flex>
      )}
      {description && (
        <Flex marginBottom={{ xs: 'space8' }}>
          <Text fontStyle={descriptionFontStyle} content={description} />
        </Flex>
      )}
      <div className="tw-flex tw-flex-row tw-gap-8 tw-items-center">
        <button
          className="tw-bg-green1 tw-rounded-2xl tw-px-8 tw-py-4 tw-flex tw-gap-8 tw-justify-center tw-items-center tw-text-white"
          type={'button'}
          onClick={onClick}
        >
          <Icon name="Folder" />
          <span>{buttonLabel ?? 'Sélectionner un fichier'}</span>
        </button>

        <Input
          ref={inputRef}
          type={'file'}
          placeholder={placeholder}
          $size={$size}
          width={width}
          onChange={_onChange}
          withError={!!error}
          disabled={disabled}
          {...otherProps}
        />
        {preview ? (
          <img
            src={preview}
            alt="Preview"
            className="tw-max-w-full tw-h-auto tw-max-h-14 tw-mx-auto"
          />
        ) : (
          <span>Aucun fichier sélectionné</span>
        )}
      </div>
      {error && (
        <Text
          content={
            error && determineIfIsFieldErrorOrString(error)
              ? error.message
              : error
          }
          fontStyle="body2"
          color={theme.colors.red1}
          marginTop={{ xs: 'space8' }}
        />
      )}
    </Flex>
  );
});

InputFilePreview.displayName = 'InputFilePreview';
