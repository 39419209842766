import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// Components
import { theme } from 'theme';
import { Flex, Line, Text } from 'components/Layout';
import { IconButton } from 'components/Buttons/IconButton';
import { Card } from 'components/Cards';
import CircleProgress from 'components/Progress/CircleProgress';
import { Step, Stepper } from 'components/Stepper';

// Container
import SpecificationCardList, {
  Specification,
} from 'container/client/ClientHome/SpecificationCardList';
import { Ghost } from 'components/Loading';
import { PublicAppRoutes } from 'PublicApp';
import { useNavigate } from 'react-router-dom';
import { getRouteWithParams } from 'utils/router';
import { EstimateFull, ProcedureStepStatus } from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';

const FlexPadding = styled(Flex)`
  padding: ${theme.spacing.space24};
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  border: 1px solid ${theme.colors.gray3};
  border-radius: ${theme.spacing.space16};
  padding: 12px;
  cursor: pointer;
`;

interface StepOpportunityProps {
  dealId?: string | null;
}

const DATA_STEPS = [
  'FORM',
  'DOCUMENT',
  'VERIFICATION',
  'WRITING_AGREEMENT',
  'REVIEW_AGREEMENT',
  'AGREEMENT_SENT',
  'SIGNATURE',
  'ARCHIVE_SIGN',
  'NOTARY',
  'MARITAL_STATUS',
  'FINAL_SENDING',
  'CLOSED',
];

const LeadDashboard: FC<StepOpportunityProps> = ({ dealId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { execute: getEstimate, state: getEstimateState } =
    useApi<EstimateFull>(`/estimates/${dealId}`);
  const estimate = getEstimateState.data?.value;
  const loading = getEstimateState.loading;

  useEffect(() => {
    if (dealId) {
      getEstimate();
    }
  }, [dealId]);

  const handleNewQuoteClick = useCallback(async () => {
    navigate(PublicAppRoutes.QUOTE, {
      state: {
        previousDealId: dealId,
      },
    });
  }, [dealId]);

  if (loading) return <Ghost width="100%" height={563} shape="rect" rx={24} />;

  const nbAdult = parseInt(`${estimate?.adult_children}`) ?? 0;
  const nbYoung = parseInt(`${estimate?.minor_children}`) ?? 0;
  const totalChildren = nbAdult + nbYoung;

  const getSteps = (): Step[] => {
    const steps: Step[] = DATA_STEPS.map((el) => ({
      title: t(`opportunity.steps_client.${el}`),
      active: false,
      hideButton: true,
      isDone: false,
      status: ProcedureStepStatus.LOCKED,
      displayedStatus: t(`opportunity.status.LOCKED`),
      onClick: () => {},
      updated_at: '',
    }));

    if (dealId) {
      steps.unshift({
        title: t(`opportunity.steps_client.PAYMENT`),
        active: true,
        hideButton: false,
        isDone: false,
        status: ProcedureStepStatus.IN_PROGRESS,
        displayedStatus: t(`opportunity.status.IN_PROGRESS`),
        onClick: () =>
          navigate(
            getRouteWithParams(PublicAppRoutes.QUOTE_RESUME, {
              id: dealId,
            }),
          ),
        updated_at: '',
      });
    }

    return steps;
  };

  const steps = getSteps();

  const specifications: Specification[] = [
    {
      icon: 'Ticket',
      title: t('opportunity.package'),
      value: estimate?.plan.name || '',
      description: estimate?.plan.description || '',
      hasInfo: true,
    },
    {
      icon: 'Document',
      title: t('opportunity.option_chosen', {
        count: estimate?.options.length,
      }),
      value:
        estimate?.options
          ?.map((option) => t(`enums:${option.code}`))
          .join(' et ') || t('opportunity.no_option'),
      description: estimate?.options
        ?.map((option) => option.description)
        .join('\n'),
      hasInfo: Boolean(estimate?.options),
    },
    {
      icon: 'People',
      title: t('opportunity.children'),
      value:
        totalChildren > 0
          ? t('opportunity.nb_child_plural', {
              nbAdult: nbAdult || 0,
              nbYoung: nbYoung || 0,
            })
          : t('opportunity.nb_child'),
    },
    {
      icon: 'Home',
      title: t('opportunity.properties'),
      value: estimate?.has_real_estate
        ? t('opportunity.have_properties')
        : t('opportunity.no_properties'),
    },
  ];

  return (
    <Card padding="0">
      <FlexPadding justify="between">
        <Flex
          direction={{ xs: 'column' }}
          style={{ gap: theme.spacing.space24 }}
        >
          <Flex>
            <IconButton
              iconName="Folder"
              color={theme.colors.salmon1}
              backgroundColor={theme.colors.salmon3}
              rounded
              stroke="regular"
            />
            <Flex alignItems="center">
              <Text
                content={t('opportunity.folder_status_no_ref')}
                fontStyle={'heading5'}
                marginLeft={{ xs: 'space16' }}
                marginRight={{ xs: 'space16' }}
              />
            </Flex>
          </Flex>

          <Flex width="700px" marginRight={{ xs: 'space64' }}>
            <Button onClick={handleNewQuoteClick}>
              <IconButton
                color={theme.colors.black}
                backgroundColor="transparent"
                iconName="ArrowRight"
              />
              <Text
                fontStyle="body2"
                content={
                  !dealId
                    ? 'Pour démarrer votre dossier, veuillez vérifier votre éligibilité au service'
                    : 'Pour démarrer votre procédure, procéder au paiement ci-dessous'
                }
                weight="medium"
                color={theme.colors.black}
              />

              <Flex marginLeft={{ xs: 'space16' }} alignItems="center">
                <Text
                  fontStyle="body3"
                  content={!dealId ? 'Éligibilité' : 'Refaire un devis'}
                  weight="bold"
                  whiteSpace="nowrap"
                  color={theme.colors.salmon1}
                  transform="uppercase"
                />
              </Flex>
            </Button>
          </Flex>
        </Flex>

        <FlexPadding width="225px" marginLeft={{ xs: 'space32' }}>
          <Flex alignItems="center" height="100%">
            <Text
              content={t('opportunity.opportunity_progress')}
              fontStyle="heading5"
            />
            <CircleProgress percentage={0} />
          </Flex>
        </FlexPadding>
      </FlexPadding>
      <Flex marginBottom={{ xs: 'space8' }}>
        <Line />
      </Flex>
      <FlexPadding direction={{ xs: 'column' }}>
        {dealId && (
          <Flex
            justify="start"
            style={{ gap: theme.spacing.space48 }}
            marginBottom={{ xs: 'space32' }}
          >
            <SpecificationCardList specifications={specifications} />
          </Flex>
        )}
        <Stepper steps={steps} isClient={true} />
      </FlexPadding>
    </Card>
  );
};

export default LeadDashboard;
