import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Flex } from 'components/Layout/Flex';
import { Text } from 'components/Layout/Text';
import {
  Breadcrumbs,
  EstimateUpdateForm,
  PaymentsList,
  ProspectsList,
} from 'container/admin';
import format from 'date-fns/format';
import { useNavigate, useParams } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import { InlineButton } from '../../components/Buttons';
import { theme } from '../../theme';
import {
  Department,
  EstimateFull,
  Option,
  PaymentFull,
  Plan,
  ProductType,
  Prospect,
} from '../../types/resources';
import styled from 'styled-components';
import { env } from '../../config/env';
import { Chip } from '../../components/Cards';
import { SearchParamsProvider } from '../../context';
import { ProspectsFilters } from './Prospects';
import { PaymentsFilters } from './Payments';

const ActionsContainer = styled.div`
  margin-top: ${theme.spacing.space48};
  border-radius: 8px;
  padding: ${theme.spacing.space16};
  background-color: ${theme.colors.salmon3};
`;

const EstimatesDetails: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { id: estimateId } = useParams<{ id: string }>();
  const { execute: getEstimate, state: getEstimateState } =
    useApi<EstimateFull>(`/estimates/${estimateId}`);
  const estimate = getEstimateState.data?.value;

  const { execute: getDepartments, state: getDepartmentsState } = useApi<
    Department[]
  >(`/departments`, { cache: true });
  const departments = getDepartmentsState.data?.value;
  const { execute: getPlans, state: getPlansState } = useApi<Plan[]>(
    `/plans?enabled=true`,
    {
      cache: true,
    },
  );
  const plans = getPlansState.data?.value;
  const { execute: getOptions, state: getOptionsState } = useApi<Option[]>(
    `/products?type=${ProductType.OPTION}&enabled=true`,
    {
      cache: true,
    },
  );
  const options = getOptionsState.data?.value;

  const {
    execute: getPayments,
    state: { data: paymentsData },
  } = useApi<PaymentFull[]>('/payments');
  const payments = paymentsData?.value ?? [];
  const paymentsTotal = paymentsData?.pagination?.total ?? 0;

  const {
    execute: getProspects,
    state: { data: prospectsData },
  } = useApi<Prospect[]>('/prospects');
  const prospects = prospectsData?.value ?? [];
  const prospectsTotal = prospectsData?.pagination?.total ?? 0;

  useEffect(() => {
    getEstimate();
    getDepartments();
    getPlans();
    getOptions();
  }, []);

  const handleViewInPipedrive = useCallback(() => {
    if (estimate?.pipedrive_deal_id) {
      window.open(
        `${env.pipedrive.url}/deal/${estimate.pipedrive_deal_id}`,
        '_blank',
      );
    }
  }, [estimate?.pipedrive_deal_id]);

  const goToForcePayment = useCallback(() => {
    if (estimate) {
      navigate(`/affaires/creation-dossier/${estimate.id}`);
    }
  }, [estimate]);

  return (
    <>
      <Breadcrumbs />

      {estimate && (
        <Flex marginBottom={{ xs: 'space24' }} justify="between">
          <Flex>
            <Text content={t('estimate.details.title')} fontStyle="heading2" />

            <Flex marginLeft={{ xs: 'space16' }}>
              <Chip content={t(`estimate.statuses.${estimate.status}`)} />
            </Flex>
          </Flex>

          <Flex direction={{ xs: 'column' }} alignItems={'end'}>
            <Text
              content={t('updated_at', {
                updated_at: format(
                  new Date(estimate.updated_at),
                  "dd/MM/YYY 'à' HH:mm",
                ),
              })}
              fontStyle="body3"
            />
            <Text
              content={t('estimate.pipedrive_sync_date', {
                pipedrive_sync_date: estimate.pipedrive_sync_date
                  ? format(
                      new Date(estimate.pipedrive_sync_date),
                      "dd/MM/YYY 'à' HH:mm",
                    )
                  : t('never').toLowerCase(),
              })}
              fontStyle="body3"
            />
          </Flex>
        </Flex>
      )}

      {estimate && departments && plans && options && (
        <>
          <Flex marginTop={{ xs: 'space48' }}>
            <Text content={t('estimate.information')} fontStyle="heading3" />
          </Flex>
          <EstimateUpdateForm
            estimate={estimate}
            departments={departments}
            plans={plans}
            options={options}
          />
        </>
      )}

      {estimate && (
        <>
          <Flex marginTop={{ xs: 'space48' }}>
            <Text content={t('payments.title')} fontStyle="heading3" />
          </Flex>
          <SearchParamsProvider<PaymentsFilters>>
            <PaymentsList
              pageSize={3}
              estimateId={estimate.id}
              fetch={getPayments}
              items={payments}
              total={paymentsTotal}
            />
          </SearchParamsProvider>
        </>
      )}

      {estimate && (
        <>
          <Flex marginTop={{ xs: 'space48' }}>
            <Text content={t('prospect.prospects')} fontStyle="heading3" />
          </Flex>
          <SearchParamsProvider<ProspectsFilters>>
            <ProspectsList
              search={null}
              estimate_id={estimate.id}
              pageSize={3}
              fetch={getProspects}
              items={prospects}
              total={prospectsTotal}
            />
          </SearchParamsProvider>
        </>
      )}

      <ActionsContainer>
        <Flex direction={{ xs: 'column' }}>
          <InlineButton
            onClick={goToForcePayment}
            iconColor={theme.colors.salmon1}
            text={t('estimate.details.force_payment')}
            backgroundColor={theme.colors.white}
            iconName="ArrowRight"
            hoverBackgroundColor={theme.colors.salmon2}
            hoverIconColor={theme.colors.white}
            style={{ width: 'fit-content' }}
          />
        </Flex>

        {estimate?.pipedrive_deal_id && (
          <Flex direction={{ xs: 'column' }}>
            <InlineButton
              onClick={handleViewInPipedrive}
              iconColor={theme.colors.salmon1}
              text={t('prospect.pipedrive_label')}
              backgroundColor={theme.colors.white}
              iconName="ArrowRight"
              hoverBackgroundColor={theme.colors.salmon2}
              hoverIconColor={theme.colors.white}
              style={{ width: 'fit-content' }}
            />
          </Flex>
        )}
      </ActionsContainer>
    </>
  );
};

export default EstimatesDetails;
