import React, { FC, ReactElement } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import styled from 'styled-components';

// Components
import UnregisterHeader from 'components/Layout/Header/UnregisterHeader';

// Pages
import {
  AccountActivation,
  ForgottenPassword,
  PasswordChange,
  Quote,
  QuoteResumePage,
  Register,
  SignIn,
} from 'pages/public';

// Schema
import Router from 'types/router.types';
import SentryWrapper from './config/sentry-route';

const Container = styled.div`
  padding-top: 70px;
  height: auto;
`;

export enum PublicAppRoutes {
  REGISTER = '/inscription',
  SIGN_IN = '/connexion',
  FORGOTTEN_PASSWORD = '/mot-de-passe-oublie',
  PASSWORD_CHANGE = '/reinitialisation-mot-de-passe',
  ACCOUNT_ACTIVATION = '/activation-compte',
  QUOTE = '/devis',
  QUOTE_RESUME = '/recapitulatif-devis/:id',
}

export const publicRoutes: Record<PublicAppRoutes, Router> = {
  [PublicAppRoutes.REGISTER]: {
    routeProps: {
      path: PublicAppRoutes.REGISTER,
      element: <Register />,
    },
    isMobile: true,
  },
  [PublicAppRoutes.SIGN_IN]: {
    routeProps: {
      path: PublicAppRoutes.SIGN_IN,
      element: <SignIn />,
    },
    isMobile: true,
  },
  [PublicAppRoutes.FORGOTTEN_PASSWORD]: {
    routeProps: {
      path: PublicAppRoutes.FORGOTTEN_PASSWORD,
      element: <ForgottenPassword />,
    },
    isMobile: true,
  },
  [PublicAppRoutes.PASSWORD_CHANGE]: {
    routeProps: {
      path: PublicAppRoutes.PASSWORD_CHANGE,
      element: <PasswordChange />,
    },
    isMobile: true,
  },
  [PublicAppRoutes.ACCOUNT_ACTIVATION]: {
    routeProps: {
      path: PublicAppRoutes.ACCOUNT_ACTIVATION,
      element: <AccountActivation />,
    },
    isMobile: true,
  },
  [PublicAppRoutes.QUOTE]: {
    routeProps: {
      path: PublicAppRoutes.QUOTE,
      element: <Quote />,
    },
    isMobile: true,
  },
  [PublicAppRoutes.QUOTE_RESUME]: {
    routeProps: {
      path: PublicAppRoutes.QUOTE_RESUME,
      element: <QuoteResumePage />,
    },
    isMobile: true,
  },
};

const PublicApp: FC = () => {
  const location = useLocation();

  return (
    <>
      <UnregisterHeader />
      <Container>
        <Routes>
          {Object.values(publicRoutes).map((route) => (
            <Route
              key={`clientRoute-${route.routeProps.path}`}
              path={route.routeProps.path}
              element={
                <SentryWrapper
                  element={route.routeProps.element as ReactElement}
                />
              }
            />
          ))}
          <Route
            path="*"
            element={
              <Navigate
                to={{
                  pathname: PublicAppRoutes.SIGN_IN,
                  search: `from=${location.pathname}`,
                }}
              />
            }
          />
        </Routes>
      </Container>
    </>
  );
};

export default PublicApp;
