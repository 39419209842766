import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Component
import { Flex, Text } from 'components/Layout';
import { ConfirmForm } from 'components/ConfirmPopin/ConfirmPopin';
import { Modal } from 'components/Modal';

//data
import { Breadcrumbs, EstimateCreateForm } from 'container/admin';

// Utils
import { AdminAppRoutes } from 'AdminApp';
import { useApi } from '../../hooks/useApi';
import { Department, Option, Plan, ProductType } from '../../types/resources';
import { CreateEstimateInput } from '../../container/admin/estimate/EstimateCreateForm';
import toast from 'react-hot-toast';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const EstimatesAdd: FC = () => {
  const { t } = useTranslation();

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [result, setResult] = useState<CreateEstimateInput>();
  const navigate = useNavigate();

  const { execute: getDepartments, state: getDepartmentsState } = useApi<
    Department[]
  >(`/departments`, { cache: true });
  const departments = getDepartmentsState.data?.value;
  const { execute: getPlans, state: getPlansState } = useApi<Plan[]>(
    `/plans?enabled=true`,
    {
      cache: true,
    },
  );
  const plans = getPlansState.data?.value;
  const { execute: getOptions, state: getOptionsState } = useApi<Option[]>(
    `/products?type=${ProductType.OPTION}&enabled=true`,
    {
      cache: true,
    },
  );
  const options = getOptionsState.data?.value;

  const onSubmit = (data: CreateEstimateInput) => {
    setResult(data);
    setModalIsOpen(true);
  };

  const onClose = () => {
    setModalIsOpen(false);
  };

  const {
    execute: createEstimateRequest,
    state: { loading },
  } = useApi('/estimates', {
    method: 'POST',
    onSuccess: () => {
      toast.success(t('estimate.create_success'));
      goBack();
    },
  });

  const goBack = () => {
    navigate(AdminAppRoutes.ESTIMATE);
  };

  const createEstimate = async () => {
    if (result) {
      createEstimateRequest({ body: result });
    }
  };

  useEffect(() => {
    getDepartments();
    getPlans();
    getOptions();
  }, []);

  return (
    <Container>
      <Modal opened={modalIsOpen} onClose={onClose}>
        <ConfirmForm
          title={t('estimate.add_confirm_title')}
          description={t('estimate.add_confirm_description')}
          confirmText={t('estimate.add_confirm')}
          handleSubmit={createEstimate}
          setModalIsOpen={setModalIsOpen}
          loading={loading}
        />
      </Modal>

      <Breadcrumbs />

      <Flex
        direction={{ xs: 'row' }}
        alignItems="center"
        marginBottom={{ xs: 'space16' }}
      >
        <Text
          content={t('estimate.add')}
          fontStyle="heading2"
          weight="bold"
          marginRight={{ xs: 'space32' }}
        />
      </Flex>

      {departments && plans && options && (
        <EstimateCreateForm
          departments={departments}
          plans={plans}
          options={options}
          onSubmit={onSubmit}
        />
      )}
    </Container>
  );
};

export default EstimatesAdd;
