import { FC } from 'react';
import styled from 'styled-components';

import logoBlack from 'assets/logo/logo-black.svg';

import { ClientAppRoutes } from 'ClientApp';

import { RegisterForm } from 'container/public';

import { Text } from 'components/Layout';

import { md } from 'theme/styles/mediaQueries';
import { theme } from 'theme';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';
import { Navigate } from 'react-router-dom';

const BackgroundWrapper = styled.div`
  background-image: url('/auth-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;

  ${md(`
    padding: ${theme.spacing.space40} 0;
  `)}
`;

const FormWrapper = styled.div`
  margin: auto;
  max-width: 600px;
  padding: ${theme.spacing.space56} ${theme.spacing.space40};
  background: ${theme.colors.gray1};
  border: 1px solid ${theme.colors.gray3};

  ${md(`
    border-radius: ${theme.spacing.space24};
  `)}
`;

const Image = styled.img``;

const Register: FC = () => {
  const { user } = useAuth();
  const { t } = useTranslation();

  if (user) {
    return <Navigate to={ClientAppRoutes.HOME} />;
  }

  return (
    <>
      <BackgroundWrapper>
        <FormWrapper>
          <Image src={logoBlack} alt="WeDivorce" />
          <Text
            content={t('register.title')}
            fontStyle="heading4"
            color={theme.colors.black}
            marginTop={{ xs: 'space32' }}
            marginBottom={{ xs: 'space24' }}
          />
          <Text
            content={t('register.subTitle')}
            fontStyle="body1"
            color={theme.colors.black}
            marginBottom={{ xs: 'space32' }}
          />
          <RegisterForm />
        </FormWrapper>
      </BackgroundWrapper>
    </>
  );
};

export default Register;
