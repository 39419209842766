import React, { FC, useEffect, useState } from 'react';
import { Flex, Text } from 'components/Layout';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from 'components/Buttons';
import InputCheckbox from 'components/FormTemplate/Fields/InputCheckbox';
import { InputTel } from 'components/FormTemplate/Fields/InputTel';
import { InputText } from 'components/FormTemplate/Fields/InputText';
import { regexes } from 'utils/regex';
import styled from 'styled-components';
import { theme } from 'theme';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { handleRegisterEvent } from 'utils/analytics';
import { EstimateFull } from '../../types/resources';
import { useApi } from '../../hooks/useApi';
import { useAuth } from '../../hooks/useAuth';
import { isValidPhoneNumber } from 'react-phone-number-input';

enum RegisterFormTerms {
  BusinessRelationship = 'businessRelationship',
  BusinessEmail = 'businessEmail',
  Terms = 'terms',
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.space16};
`;

const StyledInputCheckboxWrapper = styled.div`
  a {
    color: inherit;
    text-decoration: underline;
  }
`;

interface LocationState {
  from: string;
  email: string;
  phone: string;
  estimate?: EstimateFull;
}

type PasswordInputType = 'password' | 'text';

type RegisterUserInput = {
  accept_marketing_contact?: boolean;
  accept_data_business_usage: boolean;
  accept_terms_date: boolean;
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  password_confirmation: string;
  phone: string;
};

const RegisterForm: FC = () => {
  const { t } = useTranslation();
  const { authenticateUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationState | undefined;
  const {
    register,
    setValue,
    control,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm<RegisterUserInput>();
  const [passwordType, setPasswordType] =
    useState<PasswordInputType>('password');
  const [confirmPasswordType, setConfirmPasswordType] =
    useState<PasswordInputType>('password');
  const { execute: registerCustomer, state: registerCustomerState } = useApi(
    `/customers/register`,
    { method: 'POST' },
  );

  const toggleTerm = (term: RegisterFormTerms) => {
    switch (term) {
      case RegisterFormTerms.BusinessRelationship:
        setValue(
          'accept_data_business_usage',
          !getValues('accept_data_business_usage'),
        );
        break;
      case RegisterFormTerms.BusinessEmail:
        setValue(
          'accept_marketing_contact',
          !getValues('accept_marketing_contact'),
        );
        break;
      case RegisterFormTerms.Terms:
        setValue('accept_terms_date', !getValues('accept_terms_date'));
        break;
      default:
        return;
    }
  };

  const onSubmit = async (data: RegisterUserInput) => {
    if (
      isFormValid() &&
      data &&
      getValues('accept_terms_date') &&
      getValues('accept_data_business_usage')
    ) {
      registerCustomer({
        body: {
          accept_marketing_contact: data.accept_marketing_contact,
          accept_data_business_usage: new Date(),
          accept_terms_date: new Date(),
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          password: data.password,
          phone: data.phone,
          estimate_id: state?.estimate?.id,
        },
        onSuccess: () => {
          handleRegisterEvent();
          authenticateUser({
            onSuccess: () => {
              navigate('/');
            },
          });
        },
        onError: () => {
          setError('email', {
            type: 'custom',
            message: t('register.form.errors.email').toString(),
          });
        },
      });
    }
  };

  const isFormValid = () => {
    let isValid = true;

    if (!getValues('accept_terms_date')) {
      setValue('accept_terms_date', false);
      isValid = false;
    }
    if (!getValues('accept_data_business_usage')) {
      setValue('accept_data_business_usage', false);
      isValid = false;
    }
    if (!getValues('phone')) {
      setError('phone', {
        type: 'custom',
        message: t('error.form.required').toString(),
      });
      isValid = false;
    }

    return isValid;
  };

  useEffect(() => {
    if (state) {
      setValue('email', state?.email);
      setValue('phone', state?.phone);
    }
  }, [state]);

  return (
    <Form onSubmit={handleSubmit(onSubmit, isFormValid)}>
      <InputText
        {...register('first_name', {
          required: t('error.form.required').toString(),
        })}
        error={errors.first_name}
        label={t('register.form.firstname')}
        labelFontStyle="body1"
        labelWeight="medium"
        placeholder={t('register.form.firstname_placeholder')}
        width="100%"
        required
      />
      <InputText
        {...register('last_name', {
          required: t('error.form.required').toString(),
        })}
        error={errors.last_name}
        label={t('register.form.lastname')}
        labelFontStyle="body1"
        labelWeight="medium"
        placeholder={t('register.form.lastname_placeholder')}
        width="100%"
        required
      />
      <InputText
        {...register('email', {
          required: t('error.form.required').toString(),
          pattern: {
            value: regexes.email,
            message: t('error.form.email').toString(),
          },
        })}
        error={errors.email}
        label={t('register.form.email')}
        labelFontStyle="body1"
        labelWeight="medium"
        placeholder={t('register.form.email_placeholder')}
        width="100%"
        required
      />
      <Controller
        key={`field_phone`}
        name={'phone'}
        rules={{
          required: t('error.form.required'),
          validate: (value: string | undefined) => {
            if (value && !isValidPhoneNumber(value)) {
              return t('error.form.phone').toString();
            }
          },
        }}
        control={control}
        render={() => (
          <InputTel
            type="tel"
            error={errors.phone}
            label={t('register.form.phone')}
            labelFontStyle="body1"
            labelWeight="medium"
            placeholder={t('register.form.phone_placeholder')}
            width="100%"
            value={state?.phone}
            onChange={(value) => {
              setValue('phone', value);
              clearErrors('phone');
            }}
            required
          />
        )}
      />
      <InputText
        {...register('password', {
          required: t('error.form.required').toString(),
          pattern: {
            value: regexes.password,
            message: t('error.form.password').toString(),
          },
        })}
        type={passwordType}
        error={errors.password}
        label={t('register.form.password')}
        labelFontStyle="body1"
        labelWeight="medium"
        placeholder={t('register.form.password_placeholder')}
        width="100%"
        icon={{
          name: passwordType === 'password' ? 'Hide' : 'Show',
        }}
        onClick={() => {
          setPasswordType((prev) =>
            prev === 'password' ? 'text' : 'password',
          );
        }}
        required
      />
      <InputText
        {...register('password_confirmation', {
          required: t('error.form.required').toString(),
          validate: (val: string) => {
            if (watch('password') != val) {
              return t('error.form.password_no_match').toString();
            }
          },
        })}
        type={confirmPasswordType}
        error={errors.password_confirmation}
        label={t('register.form.password_confirmation')}
        labelFontStyle="body1"
        labelWeight="medium"
        placeholder={t('register.form.password_confirmation_placeholder')}
        width="100%"
        icon={{
          name: confirmPasswordType === 'password' ? 'Hide' : 'Show',
        }}
        onClick={() => {
          setConfirmPasswordType((prev) =>
            prev === 'password' ? 'text' : 'password',
          );
        }}
        required
      />
      <InputCheckbox
        label={t(`register.form.terms.checkbox1`)}
        fontStyle="body3"
        onChange={() => toggleTerm(RegisterFormTerms.BusinessRelationship)}
        align="center"
        error={
          watch('accept_data_business_usage') !== undefined
            ? !watch('accept_data_business_usage')
            : undefined
        }
        checked={watch('accept_data_business_usage') ?? false}
      />
      <InputCheckbox
        label={t(`register.form.terms.checkbox2`)}
        fontStyle="body3"
        onChange={() => toggleTerm(RegisterFormTerms.BusinessEmail)}
        align="center"
      />
      <StyledInputCheckboxWrapper>
        <InputCheckbox
          label={t(`register.form.terms.checkbox3`)}
          fontStyle="body3"
          onChange={() => toggleTerm(RegisterFormTerms.Terms)}
          align="center"
          error={
            watch('accept_terms_date') !== undefined
              ? !watch('accept_terms_date')
              : undefined
          }
          checked={watch('accept_terms_date') ?? false}
          dangerouslySetInnerHTML
        />
      </StyledInputCheckboxWrapper>
      <Flex marginTop={{ xs: 'space16' }}>
        <Button
          content={t('register.form.submit')}
          type="submit"
          $loading={registerCustomerState.loading}
          fullWidth
          iconLeft={{ name: 'ArrowRightSquare' }}
          primary
        />
      </Flex>
      <Text
        content={t('register.form.submit_subtitle')}
        fontStyle="body2"
        color={theme.colors.gray4}
        textAlign="center"
      />
    </Form>
  );
};

export default RegisterForm;
