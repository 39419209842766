import { FC, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { theme } from 'theme';

import { Col, Container, Flex, Row } from 'components/Layout';
import { AppointmentInfoCard } from 'components/Cards';
import {
  AppointmentCard,
  AppointmentTakingCard,
  ViewGate,
} from 'container/common';
import { useCustomer } from '../../hooks/useCustomer';
import {
  Appointment,
  DivorceAgreementFull,
  ProcedureFull,
  ProcedureStepType,
} from '../../types/resources';
import { useApi } from '../../hooks/useApi';

const CustomContainer = styled(Container)`
  padding-top: ${theme.spacing.space32};
  padding-bottom: ${theme.spacing.space32};
`;

const ClientAppointment: FC = () => {
  const { customer } = useCustomer();

  const { execute: getProcedure, state: getProcedureState } =
    useApi<ProcedureFull>('');
  const procedure = getProcedureState.data?.value;

  const { execute: getAppointment, state: getAppointmentState } =
    useApi<Appointment>('');
  const appointment = getAppointmentState.data?.value;

  const { execute: getDivorceAgreement, state: getDivorceAgreementState } =
    useApi<DivorceAgreementFull>(
      `/procedures/${procedure?.id}/divorce-agreements/${procedure?.divorce_agreement_id}`,
    );
  const divorceAgreement = getDivorceAgreementState.data?.value;

  const getProcedureCb = useCallback(() => {
    if (customer?.procedure_id) {
      getProcedure({
        endpoint: `/procedures/${customer.procedure_id}`,
        query: { customer_id: customer.id },
      });
    }
  }, [customer?.procedure_id]);

  useEffect(() => {
    getProcedureCb();
  }, [customer?.procedure_id]);

  useEffect(() => {
    if (procedure?.signature_appointment_id) {
      getAppointment({
        endpoint: `/procedures/${procedure.id}/appointments/${procedure.signature_appointment_id}`,
      });
    }

    if (procedure?.divorce_agreement_id) {
      getDivorceAgreement();
    }
  }, [procedure]);

  if (!procedure) return null;

  return (
    <ViewGate procedure={procedure} steps={[ProcedureStepType.SIGNATURE]}>
      <CustomContainer fluid="xl">
        <Row>
          <Col xs={12} md={4} alignSelf="auto">
            <AppointmentTakingCard
              procedure={procedure}
              refetchProcedure={getProcedureCb}
              agreement={divorceAgreement}
            />
          </Col>
          <Col vertical alignSelf="auto" xs={12} md={8}>
            <Flex marginBottom={{ xs: 'space32' }}>
              <AppointmentInfoCard />
            </Flex>
            <Flex marginBottom={{ xs: 'space32' }}>
              <AppointmentCard
                procedure={procedure}
                refetchProcedure={getProcedureCb}
                appointment={appointment}
              />
            </Flex>
          </Col>
        </Row>
      </CustomContainer>
    </ViewGate>
  );
};

export default ClientAppointment;
