import { ChangeEvent, FC } from 'react';

import { InputText } from 'components/FormTemplate/Fields/InputText';
import { Flex } from 'components/Layout';

interface CommentInputProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  value: string;
}

const CommentInput: FC<CommentInputProps> = ({
  onChange,
  placeholder,
  value,
}) => {
  return (
    <Flex alignItems="center" marginBottom={{ xs: 'space16' }}>
      <InputText
        placeholder={placeholder}
        style={{ width: '100%' }}
        onChange={onChange}
        value={value}
      />
    </Flex>
  );
};

export default CommentInput;
