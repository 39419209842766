import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Text } from 'components/Layout';
import { Modal } from 'components/Modal';
import { ModalHeader } from '.';
import { Button } from 'components/Buttons';
import { theme } from 'theme';

interface ConsultationModalProps {
  onClose: () => void;
  onSubmit: () => void;
  opened: boolean;
  loading?: boolean;
}

const ConsultationModal: FC<ConsultationModalProps> = ({
  onClose,
  onSubmit,
  opened,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal opened={opened} onClose={onClose}>
        <ModalHeader
          content={t('opportunity.consultation.modal.title')}
          iconName="Danger"
        />
        <Text
          content={t('opportunity.consultation.modal.description')}
          fontStyle="body1"
          color={theme.colors.gray6}
          marginBottom={{ xs: 'space16' }}
        />
        <Flex direction={{ xs: 'column', md: 'row' }}>
          <Button
            content={t('opportunity.consultation.modal.quit')}
            marginRight={{ xs: 'none', md: 'space16' }}
            marginBottom={{ xs: 'space16', md: 'none' }}
            onClick={onClose}
          />
          <Button
            primary
            content={t('opportunity.consultation.modal.ask')}
            onClick={() => onSubmit()}
            $loading={loading}
          />
        </Flex>
      </Modal>
    </>
  );
};

export default ConsultationModal;
