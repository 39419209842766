import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { getRouteWithParams } from 'utils/router';
import { AdminAppRoutes } from 'AdminApp';
import { DocumentFull } from '../../../types/resources';
import { formatDocumentTitle } from '../../../utils/format';
import SelectDropdown from '../../../components/Select/SelectDropdown';

interface DocumentsDropdownProps {
  opportunityId: string;
  document: DocumentFull;
  documents: DocumentFull[];
}

const DocumentsDropdown: FC<DocumentsDropdownProps> = ({
  opportunityId,
  document,
  documents,
}) => {
  const navigate = useNavigate();

  if (documents.length === 0) return <></>;

  const options = documents.map((document) => ({
    value: document.id,
    label: formatDocumentTitle(document),
  }));

  const onChange = (value: string) => {
    navigate(
      getRouteWithParams(AdminAppRoutes.DOCUMENT, {
        opportunityId,
        documentId: value,
      }),
    );
  };

  return (
    <SelectDropdown options={options} id={document.id} onChange={onChange} />
  );
};

export default DocumentsDropdown;
