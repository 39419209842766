import * as Sentry from '@sentry/react';
import { ComponentType, FC, ReactElement } from 'react';

interface WrapperProps {
  element: ReactElement;
}

const SentryWrapper: FC<WrapperProps> = ({ element }) => {
  const Component: FC = () => element;
  const TrackedComponent = Sentry.withSentryRouting(
    Component as ComponentType<any>,
  );
  return <TrackedComponent />;
};

export default SentryWrapper;
