import { FC, useCallback, useEffect } from 'react';

// Components
import { Container } from 'components/Layout';

// Containers
import { DocumentsList } from 'container/client';
import { ViewGate } from 'container/common';

// Data
import { useCustomer } from '../../hooks/useCustomer';
import {
  ProcedureFull,
  ProcedureStepStatus,
  ProcedureStepType,
} from '../../types/resources';
import { useApi } from '../../hooks/useApi';
import { useNavigate } from 'react-router-dom';
import { ClientAppRoutes } from '../../ClientApp';

const ClientDocuments: FC = () => {
  const { customer } = useCustomer();
  const navigate = useNavigate();
  const { execute: getProcedure, state: getProcedureState } =
    useApi<ProcedureFull>('');
  const procedure = getProcedureState.data?.value;

  const getProcedureCb = useCallback(() => {
    if (customer?.procedure_id)
      getProcedure({
        endpoint: `/procedures/${customer.procedure_id}`,
        query: { customer_id: customer.id },
      });
  }, [customer]);

  useEffect(() => {
    getProcedureCb();
  }, [customer?.procedure_id]);

  if (!procedure) return null;

  if (
    procedure.current_step.step === ProcedureStepType.DOCUMENT &&
    procedure.current_step.status === ProcedureStepStatus.LOCKED
  ) {
    navigate(ClientAppRoutes.HOME);
  }

  return (
    <ViewGate procedure={procedure} steps={[ProcedureStepType.DOCUMENT]}>
      <Container>
        <DocumentsList
          procedure={procedure}
          refetchProcedure={getProcedureCb}
        />
        {/* <PaymentWarningModal opportunityId={opportunityId} /> */}
      </Container>
    </ViewGate>
  );
};

export default ClientDocuments;
