import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components
import { theme } from 'theme';
import { Container } from 'components/Layout/Container';

// Containers
import { FormsList, FormsProgression } from 'container/client';
import { Col, Flex, Requires, Row, Text } from 'components/Layout';
import { Button, InlineButton } from 'components/Buttons';
import { useCustomer } from '../../hooks/useCustomer';
import { useApi } from '../../hooks/useApi';
import {
  FormFull,
  ProcedureFull,
  ProcedureStepFull,
  ProcedureStepType,
} from '../../types/resources';

import { Modal, ModalHeader } from '../../components/Modal';
import toast from 'react-hot-toast';

const CustomContainer = styled(Container)`
  padding-top: ${theme.spacing.space32};
`;

const ClientForms: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { customer } = useCustomer();

  const [unlockWarningOpen, setUnlockWarningOpen] = useState<boolean>(false);
  const openUnlockWarningModal = () => {
    setUnlockWarningOpen(true);
  };
  const closeUnlockWarningModal = () => {
    setUnlockWarningOpen(false);
  };

  const { execute: getProcedure, state: getProcedureState } =
    useApi<ProcedureFull>('');
  const procedure = getProcedureState.data?.value;

  const { execute: unlockForms, state: unlockFormsState } = useApi(
    `/procedures/${procedure?.id}/forms/unlock`,
    { method: 'POST' },
  );
  const { execute: validateForms, state: validateFormsState } = useApi(
    `/procedures/${procedure?.id}/forms/validate`,
    { method: 'POST' },
  );
  const { execute: getForms, state: getFormsState } = useApi<FormFull[]>(
    `/procedures/${customer?.procedure_id}/forms`,
  );

  const { execute: getStep, state: getStepState } = useApi<ProcedureStepFull>(
    `/procedures/${customer?.procedure_id}/steps/${ProcedureStepType.FORM}`,
  );

  const step = getStepState.data?.value;
  const forms = getFormsState.data?.value ?? [];
  const loading = unlockFormsState.loading || validateFormsState.loading;

  const getProcedureCb = useCallback(() => {
    if (customer?.procedure_id) {
      getProcedure({
        endpoint: `/procedures/${customer.procedure_id}`,
      });
    }
  }, [customer?.procedure_id]);

  const getFormsCb = useCallback(() => {
    if (customer?.procedure_id) {
      getForms({
        endpoint: `/procedures/${customer.procedure_id}/forms`,
      });
    }
  }, [customer?.procedure_id]);

  const getStepCb = useCallback(() => {
    if (customer?.procedure_id) {
      getStep({
        endpoint: `/procedures/${customer?.procedure_id}/steps/${ProcedureStepType.FORM}`,
      });
    }
  }, [customer?.procedure_id]);

  const onValidateCb = useCallback(() => {
    if (customer?.procedure_id) {
      validateForms({
        endpoint: `/procedures/${customer.procedure_id}/forms/validate`,
        onSuccess: () => {
          getFormsCb();
          getStepCb();
          toast.success(
            t('forms_opportunity.progress.spouse_validate.success'),
          );
        },
      });
    }
  }, [customer?.procedure_id]);

  const onUnlockCb = useCallback(() => {
    if (customer?.procedure_id) {
      unlockForms({
        endpoint: `/procedures/${customer.procedure_id}/forms/unlock`,
        onSuccess: () => {
          getFormsCb();
          getStepCb();
          closeUnlockWarningModal();
        },
      });
    }
  }, [customer?.procedure_id]);

  useEffect(() => {
    if (customer?.procedure_id) {
      getFormsCb();
      getProcedureCb();
      getStepCb();
    }
  }, [customer?.procedure_id, location]);

  const formsStep = procedure?.steps.find(
    (s) => s.step === ProcedureStepType.FORM,
  );

  if (!procedure || !formsStep || !step) return null;

  return (
    <CustomContainer>
      <Row>
        <Col xs={12} marginBottom={{ xs: 'space24' }} mobileOnly>
          <InlineButton
            iconName="ArrowLeft"
            iconColor={theme.colors.salmon2}
            backgroundColor={theme.colors.salmon3}
            text={t('back_folder')}
            onClick={() => {
              navigate('/');
            }}
            hoverBackgroundColor={theme.colors.salmon2}
            hoverIconColor={theme.colors.white}
          />
        </Col>
        <Requires value={procedure}>
          <Col
            xs={12}
            md={5}
            lg={4}
            marginBottom={{ xs: 'space24', md: 'none' }}
          >
            <FormsProgression
              procedure={procedure}
              forms={forms}
              step={step}
              onUnlock={openUnlockWarningModal}
              onValidate={onValidateCb}
              loading={loading}
            />
          </Col>
          <Col xs={12} md={7} lg={8}>
            <FormsList procedure={procedure} step={step} forms={forms} />
          </Col>
        </Requires>
      </Row>

      <Modal onClose={closeUnlockWarningModal} opened={unlockWarningOpen}>
        <ModalHeader
          iconName={'Danger'}
          content={t('forms_opportunity.progress.unlock_warning_title')}
        />

        <Text fontStyle="body1" marginTop={{ xs: 'space16' }}>
          <Trans i18nKey="forms_opportunity.progress.unlock_warning_description" />
        </Text>

        <Flex marginTop={{ xs: 'space40' }} justify={'end'}>
          <Button
            content={t('forms_opportunity.progress.unlock_warning_confirm')}
            marginRight={{ xs: 'space16' }}
            onClick={onUnlockCb}
            $loading={loading}
          />
          <Button
            content={t('forms_opportunity.progress.unlock_warning_cancel')}
            onClick={closeUnlockWarningModal}
            primary
          />
        </Flex>
      </Modal>
    </CustomContainer>
  );
};

export default ClientForms;
