import { FC, useState } from 'react';
import { Flex } from 'components/Layout';

import { Button } from 'components/Buttons';
import { InputText } from 'components/FormTemplate/Fields/InputText';
import styled from 'styled-components';
import { theme } from 'theme';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { regexes } from '../../utils/regex';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.space16};
`;

type PasswordInputType = 'password' | 'text';

type PasswordChangeProps = {
  hash: string;
};

type PasswordChangeFormData = {
  password: string;
  passwordConfirmation: string;
};

const PasswordChangeForm: FC<PasswordChangeProps> = ({ hash }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<PasswordChangeFormData>();
  const [passwordType, setPasswordType] =
    useState<PasswordInputType>('password');
  const [passwordConfirmType, setPasswordConfirmType] =
    useState<PasswordInputType>('password');
  const { passwordResetConfirm, isLoading } = useAuth();

  const goToHome = () => {
    navigate('/');
  };

  const onSubmit = async (data: PasswordChangeFormData) => {
    if (data) {
      passwordResetConfirm({
        token: hash,
        password: data.password,
        onSuccess: goToHome,
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <InputText
        {...register('password', {
          required: t('error.form.required').toString(),
          pattern: {
            value: regexes.password,
            message: t('error.form.password').toString(),
          },
        })}
        type={passwordType}
        error={errors.password}
        labelFontStyle="body1"
        labelWeight="medium"
        label={t('passwordChange.form.password_placeholder')}
        placeholder={t('passwordChange.form.password_placeholder')}
        width="100%"
        icon={{
          name: passwordType === 'password' ? 'Hide' : 'Show',
        }}
        onClick={() => {
          setPasswordType((prev) =>
            prev === 'password' ? 'text' : 'password',
          );
        }}
        required
      />
      <InputText
        {...register('passwordConfirmation', {
          required: t('error.form.required').toString(),
          validate: (val: string) => {
            if (watch('password') != val) {
              return t('error.form.password_no_match').toString();
            }
          },
        })}
        type={passwordConfirmType}
        error={errors.passwordConfirmation}
        labelFontStyle="body1"
        labelWeight="medium"
        label={t('passwordChange.form.password_confirmation_placeholder')}
        placeholder={t('passwordChange.form.password_confirmation_placeholder')}
        width="100%"
        icon={{
          name: passwordConfirmType === 'password' ? 'Hide' : 'Show',
        }}
        onClick={() => {
          setPasswordConfirmType((prev) =>
            prev === 'password' ? 'text' : 'password',
          );
        }}
        required
      />
      <Flex marginTop={{ xs: 'space16' }}>
        <Button
          content={t('passwordChange.form.submit')}
          type="submit"
          $loading={isLoading}
          fullWidth
          iconLeft={{ name: 'ArrowRightSquare' }}
          primary
        />
      </Flex>
    </Form>
  );
};

export default PasswordChangeForm;
