import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Controller, useForm } from 'react-hook-form';
import { InputText } from 'components/FormTemplate/Fields/InputText';
import { AdminAppRoutes } from 'AdminApp';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from 'theme';
import { toString } from 'lodash';
import { TextArea } from 'components/FormTemplate/Fields/TextArea';
import { Flex } from 'components/Layout';
import { Button } from 'components/Buttons';
import { InputCurrency } from 'components/FormTemplate/Fields/InputCurrency';
import { useApi } from '../../../hooks/useApi';
import InputCheckbox from '../../../components/FormTemplate/Fields/InputCheckbox';
import { Product } from '../../../types/resources';
import InputSelect from '../../../components/FormTemplate/Fields/InputSelect';

type UpdateProductInput = {
  description?: string | null;
  id: string;
  label?: string | null;
  name?: string | null;
  type?: 'PRODUCT' | 'OPTION' | 'PLAN';
  short_name?: string | null;
  price?: number | null;
  enabled?: boolean | null;
};

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 38px 32px;
  width: 928px;
  margin-top: ${theme.spacing.space48};
`;

type ProductsUpdateFormProps = {
  product: Product;
};

const ProductsUpdateForm: FC<ProductsUpdateFormProps> = ({ product }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    control,
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<UpdateProductInput>({ defaultValues: product });
  const {
    execute: updateProduct,
    state: { loading, success },
  } = useApi(`/products/${product.id}`, { method: 'PATCH' });

  const productTypesOptions = [
    {
      value: 'PRODUCT',
      label: t(`product.types.product`),
    },
    {
      value: 'OPTION',
      label: t(`product.types.option`),
    },
    {
      value: 'PLAN',
      label: t(`product.types.plan`),
    },
  ];

  const onSubmit = async (data: UpdateProductInput) => {
    if (data) {
      updateProduct({
        body: {
          enabled: data.enabled,
          name: data.name,
          price: data.price,
          label: data.label ? data.label : undefined,
          description: data.description ? data.description : undefined,
          short_name: data.short_name ? data.short_name : undefined,
        },
      });
    }
  };

  const goBack = () => {
    navigate(AdminAppRoutes.PRODUCT);
  };

  useEffect(() => {
    if (success) {
      goBack();
    }
  }, [success]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <InputText
        {...register('name', {
          required: t('error.form.required').toString(),
        })}
        error={errors.name}
        label={t('product.name')}
        placeholder={t('product.name')}
        width="100%"
      />
      <InputText
        disabled
        value={product?.code}
        label={t('product.code')}
        width="100%"
      />
      <Controller
        control={control}
        name="type"
        rules={{ required: t('error.form.user_role').toString() }}
        render={({ field, fieldState: { error } }) => (
          <InputSelect
            label={t('product.type')}
            placeholder={t('product.type')}
            options={productTypesOptions}
            error={error}
            {...field}
            onChange={(e) => {
              field.onChange(e);
            }}
            required
            disabled
          />
        )}
      />
      <Controller
        control={control}
        name="price"
        render={({ field, fieldState: { error } }) => (
          <InputCurrency
            {...field}
            value={toString(field.value)}
            onChange={(value) => {
              setValue(`price`, value);
            }}
            error={error}
            label={t('product.price')}
            placeholder={t('product.price')}
            width="100%"
          />
        )}
      />
      <TextArea
        {...register('description')}
        error={errors.description}
        label={t('product.description')}
        placeholder={t('product.description')}
        width="100%"
      />
      <TextArea
        {...register('label')}
        error={errors.label}
        label={t('product.label')}
        placeholder={t('product.label')}
        width="100%"
      />
      <InputText
        {...register('short_name')}
        error={errors.short_name}
        label={t('product.short_name')}
        placeholder={t('product.short_name')}
        width="100%"
      />
      <Controller
        control={control}
        name="enabled"
        render={({ field, fieldState: { error } }) => (
          <InputCheckbox
            {...field}
            checked={!!field.value}
            onChange={(value) => {
              setValue(`enabled`, value);
            }}
            fontWeight="bold"
            justify="center"
            align="center"
            checkPoint
            error={!!error}
            label={t(`product.enabled`)}
          />
        )}
      />

      <Flex>
        <Button
          onClick={goBack}
          content={t('back')}
          marginRight={{ sm: 'space24' }}
        />
        <Button
          content={t('product.update')}
          type="submit"
          $loading={loading}
          primary
        />
      </Flex>
    </Form>
  );
};

export default ProductsUpdateForm;
