import { FC, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { theme } from 'theme';
import { ClientCard } from 'components/Cards';
import { Flex, Text } from 'components/Layout';

import { IconButton } from 'components/Buttons';
import { ConsultationModal } from 'components/Modal';
import { useNavigate } from 'react-router-dom';
import { ClientAppRoutes } from 'ClientApp';

interface ConsultationCardProps {
  nbConsultation: number;
  opportunityId: string;
  emailTo: string;
  userId: string;
}

const AlertCard = styled(Flex)`
  padding: ${theme.spacing.space16};
  border-radius: ${theme.spacing.space24};
  background-color: ${theme.colors.beige};
`;

const ConsultationCard: FC<ConsultationCardProps> = ({ nbConsultation }) => {
  const { t } = useTranslation();
  const [modalState, setModalState] = useState(false);
  const [loading] = useState(false);
  const navigate = useNavigate();

  const onClick = () => {
    setModalState(true);
  };

  const getCardButton = () => {
    if (nbConsultation) {
      return [
        {
          content: t('opportunity.consultation.plan'),
          primary: true,
          fullWidth: true,
          disabled: false,
        },
      ];
    }
    return [
      {
        content: t('opportunity.consultation.buy'),
        primary: true,
        fullWidth: true,
        disabled: false,
      },
    ];
  };

  const getDescription = () => {
    if (nbConsultation) {
      return t('opportunity.consultation.description');
    }
    return t('opportunity.consultation.no_consultation');
  };

  const onClose = () => {
    setModalState(false);
  };

  const onSubmit = () => {
    navigate(ClientAppRoutes.MESSAGING, {
      state: {
        defaultMessage: t('opportunity.consultation.mail.body'),
      },
    });
  };

  const cardButton = getCardButton();
  const description = getDescription();

  return (
    <>
      <ClientCard
        iconName="Calling"
        title={t('opportunity.consultation.title')}
        onClick={onClick}
        buttonsDirections={{ xs: 'column' }}
        buttons={cardButton}
      >
        <Text
          content={description}
          fontStyle="body2"
          color={theme.colors.gray6}
          marginBottom={{ xs: 'space32' }}
        />
        {nbConsultation >= 1 && (
          <AlertCard alignItems="center" direction={{ xs: 'row' }}>
            <IconButton
              iconName="Notification"
              color={theme.colors.black}
              backgroundColor={theme.colors.gray3}
              rounded
            />
            <Text
              content={t('opportunity.consultation.consultation_count', {
                count: nbConsultation,
              })}
              fontStyle="body1"
              weight="bold"
              marginLeft={{ xs: 'space16' }}
            />
          </AlertCard>
        )}
      </ClientCard>
      <ConsultationModal
        opened={modalState}
        onClose={onClose}
        onSubmit={onSubmit}
        loading={loading}
      />
    </>
  );
};

export default ConsultationCard;
