import { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Container
import { NotificationsList, NotificationsListFilters } from 'container/admin';

// Components
import { Flex } from 'components/Layout/Flex';
import { Text } from 'components/Layout/Text';
import { useApi } from '../../hooks/useApi';
import { Notification } from '../../types/resources';
import { SearchParamsProvider } from '../../context';

export type NotificationsFilters = {
  search?: string;
  sort: string;
  direction: string;
  page?: number;
  type?: string;
  status?: string;
};

const Notifications: FC = () => {
  const { t } = useTranslation();
  const pageSize = 10;
  const {
    execute: getNotifications,
    state: { data },
  } = useApi<Notification[]>('/notifications');
  const notifications = data?.value ?? [];
  const total = data?.pagination?.total ?? 0;

  return (
    <SearchParamsProvider<NotificationsFilters>>
      <Flex justify="between">
        <Text
          content={`${t('notification.title')} (${total})`}
          fontStyle="heading2"
        />
      </Flex>
      <NotificationsListFilters />

      <Flex marginTop={{ xs: 'space32' }}>
        <NotificationsList
          pageSize={pageSize}
          fetch={getNotifications}
          items={notifications}
          total={total}
        />
      </Flex>
    </SearchParamsProvider>
  );
};

export default Notifications;
