import { FC, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import { Button } from 'components/Buttons/Button';
import Table from 'components/Table/Table';
import { Flex } from 'components/Layout/Flex';

import { AdminAppRoutes } from 'AdminApp';
import { getRouteWithParams } from 'utils/router';
import { Icon } from '../../../components/Images';
import { theme } from '../../../theme';
import { Department, Role, User } from '../../../types/resources';
import { ExecuteFunction } from '../../../hooks/useFetch';
import { ColumnDef, OnChangeFn, SortingState } from '@tanstack/react-table';
import { parseInt } from 'lodash';
import { useSearchParamsContext } from '../../../context';
import { UsersFilters } from '../../../pages/admin/Users';

type UsersListProps = {
  pageSize: number;
  departments: Department[];
  fetch: ExecuteFunction<User[]>;
  total: number;
  items: User[];
};

const UsersList: FC<UsersListProps> = ({
  pageSize,
  departments,
  fetch,
  items,
  total,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    filters: currentFilters,
    updateFilters,
    removeFilter,
    getFilter,
  } = useSearchParamsContext<UsersFilters>();

  useEffect(() => {
    fetch({
      query: {
        items: pageSize,
        role: currentFilters.role || undefined,
        roles: [Role.ADMIN, Role.LAWYER, Role.MANAGER, Role.SALES],
        department_code: currentFilters.department || undefined,
        sort: currentFilters.sort,
        direction: currentFilters.direction,
        search: currentFilters.search || undefined,
        page: currentFilters.page || 1,
      },
    });
  }, [currentFilters]);

  const columns = useMemo<ColumnDef<User>[]>(
    () => [
      {
        header: t('user.last_name'),
        accessorKey: 'last_name',
        size: 125,
      },
      {
        header: t('user.first_name'),
        accessorKey: 'first_name',
        size: 125,
      },
      {
        header: t('user.department'),
        accessorKey: 'department_code',
        size: 175,
        cell: ({ row }) =>
          departments.find((d) => d.code === row.original.department_code)
            ?.full_name ?? '',
      },
      {
        header: t('user.user_role'),
        accessorKey: 'role',
        size: 175,
        enableSorting: false,
        cell: ({ row }) => t(`role.${row.original.role.toLowerCase()}`),
      },
      {
        header: t('user.activated'),
        accessorKey: 'activated',
        size: 100,
        cell: ({ row }) => (
          <Icon
            name={row.original.activated ? 'check' : 'close'}
            fill="none"
            primaryColor={
              row.original.activated ? theme.colors.green3 : theme.colors.red1
            }
          />
        ),
      },
      {
        header: '',
        accessorKey: 'id',
        size: 100,
        enableSorting: false,
        cell: ({ row }) => (
          <Flex justify="end" expand>
            <Button
              content={t('view')}
              size="small"
              primary
              onClick={() =>
                navigate(
                  getRouteWithParams(AdminAppRoutes.USER_UPDATE_ID, {
                    id: row.original.id,
                  }),
                )
              }
            />
          </Flex>
        ),
      },
    ],
    [history],
  );

  const currentSorting: SortingState = (() => {
    const sortColumn = getFilter('sort') as string;
    const sortDirection = getFilter('direction') as string;

    if (sortColumn) {
      return [
        {
          id: sortColumn,
          desc: sortDirection === 'desc',
        },
      ];
    }
    return [];
  })();

  const handleSortingChange: OnChangeFn<SortingState> = useCallback(
    (updaterOrValue) => {
      const updatedSorting = Array.isArray(updaterOrValue)
        ? updaterOrValue
        : updaterOrValue([]);

      try {
        const sortInfo = updatedSorting[0];

        if (sortInfo) {
          updateFilters({
            sort: sortInfo.id,
            direction: sortInfo.desc ? 'desc' : 'asc',
          });
        }
      } catch (error) {
        console.error('Erreur lors du tri:', error);
      }
    },
    [updateFilters],
  );

  return (
    <Table
      data={items}
      columns={columns}
      onPaginationChange={(pageIndex) => updateFilters({ page: pageIndex })}
      pageCount={Math.ceil((total || 0) / pageSize)}
      pagination={{
        pageIndex: getFilter('page')
          ? parseInt(getFilter('page') as string)
          : 1,
        pageSize: pageSize,
      }}
      sorting={currentSorting}
      onRowClick={(item) => {
        navigate(
          getRouteWithParams(AdminAppRoutes.USER_UPDATE_ID, {
            id: item.id as string,
          }),
        );
      }}
      onSortingChange={handleSortingChange}
    />
  );
};

export default UsersList;
