import { FC } from 'react';

// Components
import MUIPagination from '@mui/material/Pagination';
import { Flex } from 'components/Layout';

interface PaginationProps {
  pageCount?: number;
  pageIndex?: number;
  gotoPage?: (pageIndex: number) => void;
}

const Pagination: FC<PaginationProps> = ({
  pageCount,
  pageIndex,
  gotoPage,
}) => {
  if (pageIndex === undefined || !pageCount || gotoPage === undefined) {
    return null;
  }

  return (
    <Flex justify="center" marginTop={{ xs: 'space24' }}>
      <MUIPagination
        page={pageIndex}
        count={pageCount}
        onChange={(_, value) => gotoPage(value)}
      />
    </Flex>
  );
};

export default Pagination;
