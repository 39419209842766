import React, { ChangeEvent, FC, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { InputText } from '../FormTemplate/Fields/InputText';
import { useTranslation } from 'react-i18next';
import { TextArea } from '../FormTemplate/Fields/TextArea';
import { Button, IconButton } from '../Buttons';
import {
  File as CustomFile,
  MessageTemplate,
  Role,
} from '../../types/resources';

import { Text } from '../Layout';
import { theme } from '../../theme';
import styled from 'styled-components';
import { useApi } from '../../hooks/useApi';
import { useAuth } from '../../hooks/useAuth';
import { downloadFileNew } from '../../utils/downloadFile';
import toast from 'react-hot-toast';

type MessageTemplateData = {
  title: string;
  content: string;
  attachments?: File[];
};

interface MessageTemplateFormProps {
  loading: boolean;
  onSubmit: (data: MessageTemplateData) => void;
  onDelete?: () => void;
  messageTemplate?: MessageTemplate;
  isModal?: boolean;
  onSelect?: (
    templateId: string | undefined,
    content: string,
    attachments?: CustomFile[],
  ) => void;
}

const DEFAULT_MAX_SIZE = 10 * 1000 * 1000; // 10 Mo
const ATTACHMENTS_LIMIT = 5;

type AttachmentFileProps = {
  attachment: File;
  onDelete: (attachment: File) => void;
};

const AttachmentContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${theme.spacing.space4};

  padding: ${theme.spacing.space4} ${theme.spacing.space8};
  background-color: ${theme.colors.green1};
  border-radius: 10px;
`;

const AttachmentFile: FC<AttachmentFileProps> = ({ attachment, onDelete }) => {
  return (
    <AttachmentContainer>
      <IconButton
        style={{ padding: 0 }}
        size={'xs'}
        iconName={'CloseSquare'}
        color={'white'}
        backgroundColor={'transparent'}
        onClick={() => onDelete(attachment)}
      />

      <Text
        fontStyle={'body3'}
        content={attachment.name}
        color={theme.colors.white}
      />
    </AttachmentContainer>
  );
};

const MessageTemplateForm: FC<MessageTemplateFormProps> = (props) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const isAdmin = user?.role === Role.ADMIN;
  const { loading, onSubmit, messageTemplate, onDelete, isModal, onSelect } =
    props;
  const [attachments, setAttachments] = useState<File[]>([]);
  const [templateFiles, setTemplateFiles] = useState<CustomFile[]>(
    messageTemplate?.attachments || [],
  );

  const { execute: downloadFile } = useApi<{ url: string }>(`/files`);

  const {
    execute: deleteMessageTemplateFile,
    state: { loading: deleteMessageTemplateFileLoading },
  } = useApi(`/conversations/models/${messageTemplate?.id}/file`, {
    method: 'DELETE',
  });

  const downloadAttachment = (attachment: CustomFile) => {
    if (attachment) {
      downloadFile({
        endpoint: `/conversations/models/${messageTemplate?.id}/files/${attachment.id}/download`,
        onSuccess: async (result) => {
          try {
            await downloadFileNew(
              result.value.url,
              attachment.name,
              undefined,
              true,
            );
          } catch (e) {
            console.error(e);
          }
        },
      });
    }
  };

  const {
    control,
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<MessageTemplateData>({
    defaultValues: {
      title: messageTemplate?.title,
      content: messageTemplate?.content,
    },
  });

  const onSubmitHandler: SubmitHandler<MessageTemplateData> = (data) => {
    onSubmit(data);
  };

  const handleAttachmentsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const eventFiles = e.target.files;

    if (!eventFiles) return;

    setAttachments((prevState) => {
      const filesArray = Array.from(eventFiles);
      const attachments = [...prevState];

      for (const file of filesArray) {
        const existing = attachments.find(
          (a) =>
            a.name === file.name &&
            a.size === file.size &&
            a.type === file.type,
        );

        if (file.size > DEFAULT_MAX_SIZE) {
          toast.error(t('files.too_large', { name: file.name }));
          continue;
        }

        if (!existing && attachments.length < ATTACHMENTS_LIMIT) {
          attachments.push(file);
        }
      }

      return attachments;
    });
  };

  const handleAttachmentsClick = () => {
    document?.getElementById('attachments')?.click();
  };

  const handleDeleteAttachment = (attachment: File) => {
    setAttachments((prevState) => prevState.filter((a) => a !== attachment));
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmitHandler)}
      className="tw-flex tw-flex-col tw-gap-8 tw-relative"
    >
      {isModal && (
        <div className="tw-flex tw-items-start tw-gap-4 tw-absolute -tw-top-16 tw-right-0">
          <Button
            iconLeft={{ name: 'Plus' }}
            content={t('add')}
            type="button"
            onClick={() =>
              onSelect?.(messageTemplate?.id, watch('content'), templateFiles)
            }
            primary
          />
          {isAdmin && (
            <Button
              iconLeft={{ name: 'Edit' }}
              content={t('modify')}
              type="submit"
            />
          )}
        </div>
      )}
      <InputText
        {...register('title', {
          required: t('error.form.required').toString(),
          maxLength: { value: 255, message: 'Texte trop long' },
        })}
        error={errors.title}
        label={t('message_template.form.title.label')}
        placeholder={t('message_template.form.title.placeholder')}
        width="100%"
        disabled={!isAdmin}
      />
      <span className="tw-absolute tw-right-0 tw-top-24 tw-text-xs">
        {watch('title') ? watch('title').length : 0}/255
      </span>
      <TextArea
        {...register('content', {
          required: t('error.form.required').toString(),
        })}
        error={errors.content}
        label={t('message_template.form.content.label')}
        placeholder={t('message_template.form.content.label')}
        width="100%"
        rows={10}
        disabled={!isAdmin}
      />

      <Controller
        control={control}
        name={'attachments'}
        render={({ field: { value, onChange, ...field } }) => {
          return (
            <input
              className="tw-hidden"
              {...field}
              id={'attachments'}
              type="file"
              multiple
              size={5}
              accept="image/jpeg, image/png, application/pdf"
              onChange={(event) => {
                onChange(event.target.files);
                handleAttachmentsChange(event);
              }}
            />
          );
        }}
      />
      <div className="tw-flex flex-row tw-gap-4">
        {isAdmin && (
          <IconButton
            iconName="Folder"
            stroke="regular"
            rounded
            onClick={handleAttachmentsClick}
            color={theme.colors.white}
            backgroundColor={theme.colors.green1}
          />
        )}
        {attachments.map((a, index) => (
          <AttachmentFile
            key={index}
            attachment={a}
            onDelete={handleDeleteAttachment}
          />
        ))}
      </div>
      <div className="tw-flex flex-row tw-gap-4">
        {templateFiles.map((file, index) => (
          <AttachmentContainer key={index}>
            <IconButton
              style={{ padding: 0 }}
              size={'xs'}
              iconName={'Delete'}
              color={'white'}
              backgroundColor={'transparent'}
              onClick={() =>
                deleteMessageTemplateFile({
                  endpoint: `/conversations/models/${messageTemplate?.id}/file/${file.id}`,
                  onSuccess: () => {
                    setTemplateFiles(
                      templateFiles.filter((tf) => tf.id !== file.id),
                    );
                    toast.success(t('documents.modal.success'));
                  },
                })
              }
            />
            <IconButton
              style={{ padding: 0 }}
              size={'xs'}
              iconName={'Download'}
              color={'white'}
              backgroundColor={'transparent'}
              onClick={() => downloadAttachment(file)}
            />
            <Text
              fontStyle={'body3'}
              content={file.name}
              color={theme.colors.white}
            />
          </AttachmentContainer>
        ))}
      </div>
      {!isModal &&
        (messageTemplate ? (
          <div className="tw-flex tw-items-start tw-gap-4">
            <Button
              content={t('message_template.edit.submit')}
              type="submit"
              $loading={loading}
              primary
            />
            <Button
              content={t('message_template.edit.delete')}
              onClick={onDelete}
              type="button"
              $loading={loading}
            />
          </div>
        ) : (
          <div className="tw-flex tw-items-start tw-gap-4">
            <Button
              content={t('message_template.add.submit')}
              type="submit"
              $loading={loading}
              primary
            />
          </div>
        ))}
    </form>
  );
};

export default MessageTemplateForm;
