import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Flex } from 'components/Layout/Flex';
import { InputText } from 'components/FormTemplate/Fields/InputText';
import InputSelect from 'components/FormTemplate/Fields/InputSelect';

// data
// Utils
import { theme } from 'theme';
import { Department, Role } from '../../../types/resources';
import { useSearchParamsContext } from '../../../context';
import { UsersFilters } from '../../../pages/admin/Users';

type UsersListFiltersProps = {
  departments: Department[];
};

const UsersListFilters: FC<UsersListFiltersProps> = ({ departments }) => {
  const { t } = useTranslation();
  const {
    filters: currentFilters,
    updateFilters,
    removeFilter,
    getFilter,
  } = useSearchParamsContext<UsersFilters>();

  const departmentOptions = departments.map((d) => ({
    value: d.code,
    label: d.full_name,
  }));
  const roleOptions = [
    {
      value: Role.ADMIN,
      label: t(`role.admin`),
    },
    {
      value: Role.LAWYER,
      label: t(`role.lawyer`),
    },
    {
      value: Role.MANAGER,
      label: t(`role.manager`),
    },
    {
      value: Role.SALES,
      label: t(`role.sales`),
    },
  ];

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateFilters({ search: e?.target.value });
  };

  const handleRoleChange = (val: Role) => {
    updateFilters({ role: val });
  };

  const handleDepartmentChange = (val: Department['code']) => {
    updateFilters({ department: val });
  };

  return (
    <Flex marginTop={{ xs: 'space40' }} justify="start">
      <Flex>
        <InputText
          onChange={handleSearchChange}
          label={t('filter.search')}
          labelUppercase={true}
          labelFontStyle="label"
          placeholder={t('filter.search_user')}
          width="288px"
          labelWeight="bold"
          $size="small"
          value={(getFilter('search') as string) || ''}
          icon={{ name: 'Search', primaryColor: theme.colors.salmon1 }}
        />
      </Flex>
      <Flex marginLeft={{ xs: 'space32' }}>
        <InputSelect
          onChange={handleRoleChange}
          options={roleOptions}
          label={t('user.user_role')}
          labelWeight="bold"
          labelUppercase={true}
          placeholder={t('filter.search_by_role')}
          width="256px"
          size="small"
          isClearable
          value={(getFilter('role') as string) || ''}
        />
      </Flex>
      <Flex marginLeft={{ xs: 'space32' }}>
        <InputSelect
          onChange={handleDepartmentChange}
          options={departmentOptions}
          placeholder={t('filter.search_by_department')}
          label={t('user.department')}
          labelWeight="bold"
          labelUppercase={true}
          width="296px"
          size="small"
          isClearable
          value={(getFilter('department') as string) || ''}
        />
      </Flex>
    </Flex>
  );
};

export default UsersListFilters;
