import { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Button } from 'components/Buttons';

// Data
// Utils
import { downloadFile } from 'utils/downloadFile';
import { PickKey } from 'types/typescript';
import { DocumentTemplateId } from '../../types/resources';

interface DocumentLinkProps {
  type?: DocumentTemplateId;
}

export type ValidDocumentTemplate = PickKey<
  typeof DocumentTemplateId,
  'DISCERNMENT_FORM' | 'COMPENSATORY_ALLOWANCE' | 'WEALTH_DECLARATION'
>;

const URLS: Record<ValidDocumentTemplate, string> = {
  DISCERNMENT_FORM: '/files/formulaire-discernement.pdf',
  WEALTH_DECLARATION: '/files/déclaration-patrimoine.pdf',
  COMPENSATORY_ALLOWANCE: '/files/déclaration-sur-lhonneur-art.272-CCiv.pdf',
};

const FILENAMES: Record<ValidDocumentTemplate, string> = {
  DISCERNMENT_FORM: 'formulaire-discernement.pdf',
  WEALTH_DECLARATION: 'déclaration-patrimoine.pdf',
  COMPENSATORY_ALLOWANCE: 'déclaration-sur-lhonneur-art.272-CCiv.pdf',
};

const DocumentLink: FC<DocumentLinkProps> = ({ type }) => {
  const { t } = useTranslation();
  if (!type) return null;
  const whitelist = [
    DocumentTemplateId.DISCERNMENT_FORM,
    DocumentTemplateId.WEALTH_DECLARATION,
    DocumentTemplateId.COMPENSATORY_ALLOWANCE,
  ];
  if (!whitelist.includes(type)) return null;
  const getUrl = () => {
    return `${window.location.host}${URLS[type as ValidDocumentTemplate]}`;
  };
  const onClick = () => {
    const secured =
      window.location.hostname === 'localhost' ? 'http://' : 'https://';
    const url = `${secured}${getUrl()}`;
    const filename = FILENAMES[type as ValidDocumentTemplate];
    downloadFile({ url, filename }).catch(() => {});
  };
  return (
    <Button
      onClick={onClick}
      content={t('documents.download_template')}
      iconLeft={{ name: 'Download' }}
    />
  );
};

export default DocumentLink;
