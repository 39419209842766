import { FC, useContext } from 'react';

import { isUserAllowed } from 'utils/user';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../context';
import { Role } from '../../types/resources';

interface PermissionProps {
  roles?: Role[];
  userId?: string;
  redirect?: string;
}

const Permission: FC<PermissionProps> = ({
  roles,
  children,
  userId,
  redirect,
}) => {
  const { user } = useContext(AuthContext);
  const getAllow = () => {
    let allow = false;
    if (roles) {
      allow = isUserAllowed(user?.role, roles);
    }
    if (userId) {
      allow = userId === user?.id;
    }
    return allow;
  };
  const allowed = getAllow();
  if (!allowed && redirect) return <Navigate to={redirect} />;
  if (!allowed) return null;
  return <>{children}</>;
};

export default Permission;
