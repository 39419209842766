import { FC, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { InlineWidget } from 'react-calendly';
import { useApi } from 'hooks/useApi';
import { ProcedureFull } from 'types/resources';
import { useCustomer } from 'hooks/useCustomer';
import { theme } from '../../theme';
import { IconButton } from 'components/Buttons';
import { Text } from 'components/Layout';
import cx from 'classnames';

const ClientSupportCall: FC = () => {
  const { t } = useTranslation();
  const { customer } = useCustomer();

  const { execute: getCustomerProcedure, state: getCustomerProcedureState } =
    useApi<ProcedureFull>(`/procedures/${customer?.procedure_id}`);
  const procedure = getCustomerProcedureState.data?.value;

  useEffect(() => {
    if (customer?.procedure_id) {
      getCustomerProcedure();
    }
  }, [customer?.procedure_id]);

  if (!procedure) {
    return null;
  }

  return (
    <div className="tw-w-full tw-flex tw-flex-col lg:tw-flex-row tw-max-w-[1360px] tw-items-start tw-mx-auto tw-py-4 sm:tw-py-8 tw-px-4 sm:tw-px-8 md:tw-px-16 lg:tw-px-4 xl:tw-px-0 tw-gap-8">
      {!procedure.premium && (
        <div className="tw-bg-white tw-rounded-3xl tw-p-4 tw-flex tw-flex-col tw-gap-4 tw-max-w-sm">
          <div className="tw-flex tw-flex-row tw-gap-4 tw-items-center">
            <IconButton
              size="large"
              iconName="headphones"
              color={theme.colors.white}
              backgroundColor={theme.colors.salmon1}
              rounded
            />
            <Text fontStyle="heading5">{t('support.title')}</Text>
          </div>
          <Text fontStyle="body2">
            <Trans i18nKey="support.description" />
          </Text>
          <a
            href="https://formalites.wedivorce.fr/produit/option-premium/"
            target="_blank"
            className="tw-bg-primary tw-text-white tw-rounded-xl tw-p-4 tw-font-bold tw-text-center tw-no-underline hover:tw-no-underline hover:tw-text-white"
            rel="noreferrer"
          >
            {t('support.cta')}
          </a>
          <Text fontStyle="body2">
            <Trans i18nKey="support.info" />
          </Text>
        </div>
      )}

      {procedure?.manager?.support_booking_url && (
        <div
          className={cx(
            'tw-w-full lg:tw-h-[calc(100vh-70px)] xl:tw-h-[calc(100vh-140px)] lg:-tw-mt-[66px] lg:-tw-mb-[30px] lg:-tw-mx-[10px] xl:-tw-mx-[50px] tw-relative',
            {
              'tw-opacity-50 tw-pointer-events-none tw-h-[calc(200vh-70px)]':
                !procedure.premium,
              'tw-h-[calc(200vh-70px)]': procedure.premium,
            },
          )}
        >
          <InlineWidget
            key={Date.now()}
            url={procedure.manager.support_booking_url}
            pageSettings={{
              backgroundColor: 'ffffff',
              hideEventTypeDetails: false,
              hideLandingPageDetails: false,
              hideGdprBanner: true,
              primaryColor: '00a2ff',
              textColor: '4d5055',
            }}
            styles={{ height: '100%' }}
          />
          {!procedure.premium && (
            <div className="tw-absolute tw-inset-0 tw-pointer-events-none"></div>
          )}
        </div>
      )}
    </div>
  );
};

export default ClientSupportCall;
