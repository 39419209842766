import { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Flex } from 'components/Layout/Flex';
import { Text } from 'components/Layout/Text';
import ProductsList from 'container/admin/lists/ProductsList';
import ProductsListFilter from 'container/admin/filters/ProductsListFilter';
import { Button } from '../../components/Buttons';
import { AdminAppRoutes } from '../../AdminApp';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import { Product } from '../../types/resources';
import { SearchParamsProvider } from '../../context';

export type ProductsFilters = {
  search?: string;
  type?: string;
  sort: string;
  direction: string;
  page?: number;
};

const Products: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const pageSize = 10;

  const {
    execute: getProducts,
    state: { data },
  } = useApi<Product[]>('/products');
  const products = data?.value ?? [];
  const total = data?.pagination?.total ?? 0;

  return (
    <SearchParamsProvider<ProductsFilters>>
      <Flex justify="between">
        <Text
          content={`${t('product.products')} (${total})`}
          fontStyle="heading2"
        />
        <Button
          content={t('product.add_new')}
          iconLeft={{ name: 'Plus' }}
          onClick={() => navigate(AdminAppRoutes.PRODUCTS_ADD)}
        />
      </Flex>
      <ProductsListFilter />

      <Flex marginTop={{ xs: 'space32' }}>
        <ProductsList
          pageSize={pageSize}
          fetch={getProducts}
          items={products}
          total={total}
        />
      </Flex>
    </SearchParamsProvider>
  );
};

export default Products;
