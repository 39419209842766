import { FC } from 'react';
import { useParams } from 'react-router-dom';

/** Container */
import { Breadcrumbs, OpportunityGate } from 'container/admin';

/** Components */
import { Requires, Text } from 'components/Layout';
import { AdminConversation } from 'container/admin/conversation/AdminConversation';
import { useTranslation } from 'react-i18next';

const OpportunityMessagingPage: FC = () => {
  const { t } = useTranslation();
  const { id: procedureId } = useParams<{ id: string }>();

  return (
    <Requires value={procedureId}>
      <OpportunityGate opportunityId={procedureId!}>
        <Breadcrumbs procedureId={procedureId} />
        <Text
          fontStyle="heading2"
          content={t('opportunity.conversation.title')}
          marginBottom={{ xs: 'space32' }}
        />
        <AdminConversation opportunityId={procedureId!} />
      </OpportunityGate>
    </Requires>
  );
};

export default OpportunityMessagingPage;
