import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Controller, useForm } from 'react-hook-form';
import { InputText } from 'components/FormTemplate/Fields/InputText';
import { AdminAppRoutes } from 'AdminApp';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from 'theme';
import { Flex } from 'components/Layout';
import { Button } from 'components/Buttons';
import { useApi } from '../../../hooks/useApi';
import { Department, Estimate, Option, Plan } from '../../../types/resources';
import InputCheckbox from '../../../components/FormTemplate/Fields/InputCheckbox';
import InputSelect from '../../../components/FormTemplate/Fields/InputSelect';
import { InputCurrency } from '../../../components/FormTemplate/Fields/InputCurrency';
import { toString } from 'lodash';
import toast from 'react-hot-toast';

const FullWidth = styled.div`
  grid-column: 1/-1;
`;

type UpdateEstimateInput = {
  name?: string;
  split_payments?: boolean;
  has_real_estate?: boolean;
  adult_children?: number;
  minor_children?: number;
  department_code?: Department['code'];
  plan_id?: Plan['id'];
  options_ids: Option['id'][];
};

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 38px 32px;
  width: 928px;
  margin-top: ${theme.spacing.space8};
`;

type EstimateUpdateFormProps = {
  estimate: Estimate;
  departments: Department[];
  plans: Plan[];
  options: Option[];
};

const EstimateUpdateForm: FC<EstimateUpdateFormProps> = ({
  estimate,
  departments,
  plans,
  options,
}) => {
  const {
    control,
    setValue,
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm<UpdateEstimateInput>({
    defaultValues: {
      ...estimate,
      split_payments: estimate.split_payments ?? undefined,
    },
  });
  const [price, setPrice] = useState<number>(estimate.price);
  const departmentOptions = departments.map((d) => ({
    value: d.code,
    label: d.full_name,
  }));
  const planOptions = plans.map((p) => ({
    value: p.id,
    label: p.name,
  }));
  const optionOptions = options.map((o) => ({
    value: o.id,
    label: o.name,
  }));
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    execute: updateEstimate,
    state: { loading },
  } = useApi(`/estimates/${estimate.id}`, { method: 'PATCH' });
  const { execute: estimatePriceRequest } = useApi<Pick<Estimate, 'price'>>(
    `/estimates/price`,
    {
      method: 'POST',
      onSuccess: (result) => {
        setPrice(result.value.price);
      },
    },
  );

  const onSubmit = async (data: UpdateEstimateInput) => {
    if (data) {
      updateEstimate({
        body: {
          split_payments: data.split_payments,
          has_real_estate: data.has_real_estate,
          adult_children: data.adult_children,
          minor_children: data.minor_children,
          department_code: data.department_code,
          plan_id: data.plan_id,
          options_ids: data.options_ids,
        },
        onSuccess: () => {
          toast.success(t('estimate.details.update_success'));
          goBack();
        },
        onError: () => {
          toast.error(t('estimate.details.update_error'));
        },
      });
    }
  };

  const goBack = () => {
    navigate(AdminAppRoutes.ESTIMATE);
  };

  useEffect(() => {
    watch((value) => {
      estimatePriceRequest({
        body: {
          split_payments: value.split_payments,
          has_real_estate: value.has_real_estate,
          adult_children: value.adult_children,
          minor_children: value.minor_children,
          department_code: value.department_code,
          plan_id: value.plan_id,
          options_ids: value.options_ids,
        },
      });
    });
  }, [watch]);

  useEffect(() => {
    estimatePriceRequest({
      body: {
        split_payments: estimate.split_payments,
        has_real_estate: estimate.has_real_estate,
        adult_children: estimate.adult_children,
        minor_children: estimate.minor_children,
        department_code: estimate.department_code,
        plan_id: estimate.plan_id,
        options_ids: estimate.options_ids,
      },
    });
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <InputText
        {...register('name', {
          required: t('error.form.required').toString(),
        })}
        error={errors.name}
        label={t('estimate.name')}
        placeholder={t('estimate.name')}
        width="100%"
        disabled
      />
      <Controller
        control={control}
        name="department_code"
        rules={{ required: t('error.form.department_code').toString() }}
        render={({ field, fieldState: { error } }) => (
          <InputSelect
            label={t('estimate.department_code')}
            placeholder={t('estimate.department_code')}
            options={departmentOptions}
            error={error}
            {...field}
            onChange={(e) => {
              field.onChange(e);
            }}
            required
          />
        )}
      />
      <Controller
        control={control}
        name="plan_id"
        rules={{ required: t('error.form.plan_id').toString() }}
        render={({ field, fieldState: { error } }) => (
          <InputSelect
            label={t('estimate.plan_id')}
            placeholder={t('estimate.plan_id')}
            options={planOptions}
            error={error}
            {...field}
            onChange={(e) => {
              field.onChange(e);
            }}
            required
          />
        )}
      />
      <Controller
        control={control}
        name="options_ids"
        render={({ field, fieldState: { error } }) => (
          <InputSelect
            label={t('estimate.options_ids')}
            placeholder={t('estimate.options_ids')}
            options={optionOptions}
            error={error}
            {...field}
            onChange={(e) => {
              field.onChange(e);
            }}
            required
            isMulti
          />
        )}
      />
      <InputText
        {...register('adult_children', {
          required: t('error.form.required').toString(),
        })}
        type={'number'}
        error={errors.name}
        label={t('estimate.adult_children')}
        placeholder={t('estimate.adult_children')}
        width="100%"
        min="0"
        max="20"
      />
      <InputText
        {...register('minor_children', {
          required: t('error.form.required').toString(),
        })}
        type={'number'}
        error={errors.name}
        label={t('estimate.minor_children')}
        placeholder={t('estimate.minor_children')}
        width="100%"
        min={0}
      />
      <Controller
        control={control}
        name="has_real_estate"
        rules={{
          validate: (value) => {
            if (value === undefined) {
              return t('error.form.checkbox').toString();
            }
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <InputCheckbox
            {...field}
            checked={!!field.value}
            onChange={(value) => {
              setValue(`has_real_estate`, !!value);
            }}
            fontWeight="bold"
            align="center"
            checkPoint
            error={!!error}
            label={t(`estimate.has_real_estate`)}
          />
        )}
      />
      <Controller
        control={control}
        name="split_payments"
        rules={{
          validate: (value) => {
            if (value === undefined) {
              return t('error.form.checkbox').toString();
            }
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <InputCheckbox
            {...field}
            checked={!!field.value}
            onChange={(value) => {
              setValue(`split_payments`, !!value);
            }}
            fontWeight="bold"
            align="center"
            checkPoint
            error={!!error}
            label={t(`estimate.split_payments`)}
          />
        )}
      />

      <InputCurrency
        value={toString(price)}
        onChange={() => {}}
        label={t('estimate.price')}
        placeholder={t('estimate.price')}
        width="100%"
        disabled
      />

      <InputCurrency
        value={String(estimate.amount_paid)}
        onChange={() => {}}
        label={t('estimate.amount_paid')}
        placeholder={t('estimate.amount_paid')}
        width="100%"
        disabled
      />

      <FullWidth>
        <Flex>
          <Button
            onClick={goBack}
            content={t('back')}
            marginRight={{ sm: 'space24' }}
          />
          <Button
            content={t('estimate.details.submit_update')}
            type="submit"
            $loading={loading}
            primary
          />
        </Flex>
      </FullWidth>
    </Form>
  );
};

export default EstimateUpdateForm;
