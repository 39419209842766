import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { getRouteWithParams } from 'utils/router';
import { AdminAppRoutes } from 'AdminApp';

import { FormFull } from '../../../types/resources';
import { formatFormTitle } from '../../../utils/format';
import SelectDropdown from '../../../components/Select/SelectDropdown';

interface FormsDropdownProps {
  procedure_id: string;
  forms: FormFull[];
  form: FormFull;
}

const FormsDropdown: FC<FormsDropdownProps> = ({
  procedure_id,
  forms,
  form,
}) => {
  const navigate = useNavigate();

  if (forms.length === 0) return <></>;

  const options = forms.map((form) => {
    return {
      value: form.id,
      label: formatFormTitle(form),
    };
  });
  const onChange = (value: string) => {
    navigate({
      pathname: getRouteWithParams(AdminAppRoutes.FORM, {
        opportunityId: procedure_id,
        formId: value,
      }),
      search: `?switchForm=true`,
    });
  };
  return <SelectDropdown options={options} id={form.id} onChange={onChange} />;
};

export default FormsDropdown;
