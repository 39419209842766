import { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import Table from 'components/Table/Table';

import format from 'date-fns/format';
import { Notification } from '../../../types/resources';
import { ExecuteFunction } from '../../../hooks/useFetch';
import { ColumnDef, OnChangeFn, SortingState } from '@tanstack/react-table';
import { useSearchParamsContext } from '../../../context';
import { NotificationsFilters } from '../../../pages/admin/Notifications';
import { parseInt } from 'lodash';

type NotificationsListProps = {
  pageSize: number;
  fetch: ExecuteFunction<Notification[]>;
  total: number;
  items: Notification[];
};

const NotificationsList: FC<NotificationsListProps> = ({
  pageSize,
  fetch,
  total,
  items,
}) => {
  const { t } = useTranslation();
  const {
    filters: currentFilters,
    updateFilters,
    removeFilter,
    getFilter,
  } = useSearchParamsContext<NotificationsFilters>();

  useEffect(() => {
    fetch({
      query: {
        items: pageSize,
        sort: currentFilters.sort,
        direction: currentFilters.direction,
        search: currentFilters.search || undefined,
        type: currentFilters.type || undefined,
        status: currentFilters.status || undefined,
        page: currentFilters.page || 1,
      },
    });
  }, [currentFilters]);

  const columns = useMemo<ColumnDef<Notification>[]>(
    () => [
      {
        header: t('notification.type'),
        accessorKey: 'type',
        enableSorting: false,
        size: 180,
        cell: ({ row }) => t(`notification.types.${row.original.type}`),
      },
      {
        header: t('notification.status'),
        accessorKey: 'status',
        size: 80,
        cell: ({ row }) => t(`notification.statuses.${row.original.status}`),
      },
      {
        header: t('notification.email'),
        accessorKey: 'email',
        size: 150,
        enableSorting: false,
      },
      {
        header: t('notification.created_at'),
        accessorKey: 'created_at',
        size: 100,
        cell: ({ row }) =>
          row.original.created_at
            ? format(new Date(row.original.created_at), "dd/MM/YYY 'à' HH:mm")
            : 'N/A',
      },
    ],
    [history],
  );

  const currentSorting: SortingState = (() => {
    const sortColumn = getFilter('sort') as string;
    const sortDirection = getFilter('direction') as string;

    if (sortColumn) {
      return [
        {
          id: sortColumn,
          desc: sortDirection === 'desc',
        },
      ];
    }
    return [];
  })();

  const handleSortingChange: OnChangeFn<SortingState> = useCallback(
    (updaterOrValue) => {
      const updatedSorting = Array.isArray(updaterOrValue)
        ? updaterOrValue
        : updaterOrValue([]);

      try {
        const sortInfo = updatedSorting[0];

        if (sortInfo) {
          updateFilters({
            sort: sortInfo.id,
            direction: sortInfo.desc ? 'desc' : 'asc',
          });
        }
      } catch (error) {
        console.error('Erreur lors du tri:', error);
      }
    },
    [updateFilters],
  );

  return (
    <Table
      data={items || []}
      columns={columns}
      onPaginationChange={(pageIndex) => updateFilters({ page: pageIndex })}
      pageCount={Math.ceil((total || 0) / pageSize)}
      pagination={{
        pageIndex: getFilter('page')
          ? parseInt(getFilter('page') as string)
          : 1,
        pageSize: pageSize,
      }}
      sorting={currentSorting}
      onSortingChange={handleSortingChange}
    />
  );
};

export default NotificationsList;
