import { forwardRef, InputHTMLAttributes, useEffect, useState } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import fr from 'react-phone-number-input/locale/fr.json';

import styled from 'styled-components';
import { FieldError } from 'react-hook-form';

import RequiredField from 'components/Forms/Utils/RequiredField';
import { Flex, Text } from 'components/Layout';
import { Label } from 'components/Text/Label';
import { InputStyle } from 'components/FormTemplate/Fields/Input';

import { determineIfIsFieldErrorOrString } from 'utils/errors';

import { theme } from 'theme';
import { FontStyles } from 'theme/styles/fonts';
import { Weights } from 'theme/styles/size';

const Tel = styled.div<{ error: boolean }>`
  .PhoneInput {
    input {
      ${InputStyle}
      padding-left: 56px;
      border: ${(props) => props.error && `1px solid ${theme.colors.red1}`};

      &:read-only {
        cursor: not-allowed;
        background-color: ${theme.colors.gray2};
      }
    }
    .PhoneInputCountry {
      position: absolute;
      height: ${theme.spacing.space64};
      border-top-left-radius: ${theme.spacing.space16};
      border-bottom-left-radius: ${theme.spacing.space16};
      border: ${(props) =>
        props.error
          ? `1px solid ${theme.colors.red1}`
          : `1px solid ${theme.colors.gray3}`};

      border-right: none;
      background-color: ${theme.colors.gray2};
      width: 49px;
      padding: 0 8px 0 10px;

      &.open {
        border-top-left-radius: ${theme.spacing.space16};
        border-bottom-left-radius: ${theme.spacing.space16};
        background-color: ${theme.colors.gray2};
      }
      &.open .selected-flag {
        border-top-left-radius: ${theme.spacing.space16};
        border-bottom-left-radius: ${theme.spacing.space16};
        background-color: ${theme.colors.gray2};
      }

      .PhoneInputCountrySelectArrow {
        border-style: solid;
        border-top-width: 0;
        border-left-width: 0;
        border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
        border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
      }
    }
  }
`;

type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
  label?: string;
  error?: FieldError | string;
  value?: string;
  onChange?: (value: string) => void;
  labelFontStyle?: FontStyles;
  labelWeight?: Weights;
};

export const InputTel = forwardRef((props: InputProps, ref) => {
  const {
    required,
    onChange,
    disabled,
    placeholder,
    label,
    labelFontStyle,
    labelWeight,
    error,
    value = '',
    ...otherProps
  } = props;

  const [updatedValue, setUpdatedValue] = useState<string>(value);

  useEffect(() => {
    setUpdatedValue(value);
  }, [value]);

  const _onChange = (newValue: string) => {
    setUpdatedValue(newValue);

    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <Flex direction={{ xs: 'column' }} position="relative" expand>
      {label && (
        <Flex alignItems="center" marginBottom={{ xs: 'space8' }}>
          <RequiredField required={required}>
            <Label
              dangerouslySetInnerHTML={{ __html: label }}
              fontStyle={labelFontStyle}
              weight={labelWeight}
            />
          </RequiredField>
        </Flex>
      )}

      <Tel error={!!error}>
        <PhoneInput
          ref={ref as any}
          placeholder={placeholder}
          disabled={disabled}
          value={updatedValue}
          onChange={_onChange}
          defaultCountry="FR"
          international={false}
          countrySelectProps={{ unicodeFlags: true }}
          labels={fr}
          {...otherProps}
        />
      </Tel>

      {error && (
        <Text
          content={
            error && determineIfIsFieldErrorOrString(error)
              ? error.message
              : error
          }
          fontStyle="body2"
          color={theme.colors.red1}
          marginTop={{ xs: 'space8' }}
        />
      )}
    </Flex>
  );
});

InputTel.displayName = 'InputTel';
