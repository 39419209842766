import { FC, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

/** Components */
/** Container */
import { FormsAnswers, FormsDropdown } from 'container/admin';
import { Col, FullPage, Row } from 'components/Layout';
import { Container } from 'react-bootstrap';
import { getRouteWithParams } from 'utils/router';
import { Views } from 'types/nav';
import { useTranslation } from 'react-i18next';
import { AdminAppRoutes } from 'AdminApp';
import { useApi } from '../../hooks/useApi';
import { FormFull, ProcedureFull } from '../../types/resources';

const FormPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { opportunityId } = useParams<{ opportunityId: string }>();
  const { formId: id } = useParams<{ formId: string }>();

  const search = useLocation().search;
  const switchForm: Boolean = !!new URLSearchParams(search).get('switchForm');

  const { execute: getForms, state: getFormsState } = useApi<FormFull[]>(
    `/procedures/${opportunityId}/forms`,
  );
  const forms = getFormsState.data?.value;

  const { execute: getForm, state: getFormState } = useApi<FormFull>(
    `/procedures/${opportunityId}/forms/${id}`,
  );
  const form = getFormState.data?.value;

  const { execute: getProcedure, state: getProcedureState } =
    useApi<ProcedureFull>(`/procedures/${opportunityId}`);
  const procedure = getProcedureState.data?.value;

  useEffect(() => {
    getProcedure();
    getForms();
  }, []);

  useEffect(() => {
    getForm();
  }, [id]);

  const onChangePage = () => {
    navigate({
      pathname: getRouteWithParams(AdminAppRoutes.CASES_DETAILS, {
        id: opportunityId!,
      }),
      search: `?view=${Views.Forms}`,
    });
  };

  if (!forms || !form || !procedure) return null;

  return (
    <FullPage
      headerIcon="ArrowLeft"
      headerButton={t('forms_opportunity.back')}
      headerTitle={t('forms_opportunity.verify')}
      onClose={onChangePage}
    >
      <Container>
        {switchForm && (
          <Row marginBottom={{ xs: 'space56' }}>
            <Col xs={12}>
              <FormsDropdown
                procedure_id={opportunityId!}
                forms={forms}
                form={form}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12} vertical>
            <FormsAnswers procedure={procedure} form={form} />
          </Col>
        </Row>
      </Container>
    </FullPage>
  );
};

export default FormPage;
