import { FC, useEffect } from 'react';

import { SearchParamsProvider } from '../../context';
import { CasesList } from '../../container/admin';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useAuth } from '../../hooks/useAuth';
import { isUserAllowed } from '../../utils/user';
import { Lawyer, Role } from '../../types/resources';
import { useApi } from '../../hooks/useApi';

export type CasesFilters = {
  search?: string;
  step_type?: string;
  step_status?: string;
  express?: boolean;
  premium?: boolean;
  tp?: boolean;
  not_ext?: boolean;
  manager_id?: string;
  lawyer_id?: string;
  signed_lawyer_fee?: 'COMPLETE' | 'PARTIAL' | 'INCOMPLETE';
  payment?: 'COMPLETE' | 'PARTIAL';
  consents?: 'RECEIVED' | 'UNRECEIVED' | 'P_RECEIVED' | 'NOT_CONCERNED';
  letters?:
    | 'RECEIVED'
    | 'UNRECEIVED'
    | 'P_RECEIVED'
    | 'NOT_CONCERNED'
    | 'NEGLECTED'
    | 'P_NEGLECTED';
  // columns: string[];
  sort: string;
  direction: string;
  page?: number;
};

const Cases: FC = () => {
  const query = useQueryParams();
  const { user } = useAuth();

  const hasUnprocessedMessages = !query.has('has_unprocessed_messages')
    ? undefined
    : query.get('has_unprocessed_messages') === 'true';

  const hasUnprocessedComments = !query.has('has_unprocessed_comments')
    ? undefined
    : query.get('has_unprocessed_comments') === 'true';

  const lawyerId = isUserAllowed(user?.role, [Role.ADMIN])
    ? query.get('lawyer_id')
    : isUserAllowed(user?.role, [Role.LAWYER])
    ? user?.id
    : undefined;

  const managerId = isUserAllowed(user?.role, [Role.ADMIN])
    ? query.get('manager_id')
    : isUserAllowed(user?.role, [Role.MANAGER])
    ? user?.id
    : undefined;

  const { execute: getLawyers, state: getLawyersState } =
    useApi<Lawyer[]>(`/lawyers`);

  // const { execute: getManagers, state: getManagersState } =
  //   useApi<Manager[]>(`/managers`);

  useEffect(() => {
    getLawyers();
    // getManagers();
  }, []);

  return (
    <SearchParamsProvider<CasesFilters>>
      <CasesList
        hasUnprocessedMessages={hasUnprocessedMessages}
        hasUnprocessedComments={hasUnprocessedComments}
        lawyerId={lawyerId}
        managerId={managerId}
        lawyers={getLawyersState.data?.value}
        // managers={getManagersState.data?.value}
      />
    </SearchParamsProvider>
  );
};

export default Cases;
