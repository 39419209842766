import { FC, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';

// Components
import { Button } from 'components/Buttons/Button';
import Table from 'components/Table/Table';
import { Flex } from 'components/Layout/Flex';

import { AdminAppRoutes } from 'AdminApp';
import { getRouteWithParams } from 'utils/router';
import { env } from '../../../config/env';
import { Prospect } from '../../../types/resources';
import { ExecuteFunction } from '../../../hooks/useFetch';
import { ColumnDef, OnChangeFn, SortingState } from '@tanstack/react-table';
import { useSearchParamsContext } from '../../../context';
import { CustomersFilters } from '../../../pages/admin/Customers';
import { parseInt } from 'lodash';

type ProspectsListProps = {
  pageSize: number;
  search: string | null;
  estimate_id?: string;
  fetch: ExecuteFunction<Prospect[]>;
  total: number;
  items: Prospect[];
};

const ProspectsList: FC<ProspectsListProps> = ({
  pageSize,
  search,
  estimate_id,
  fetch,
  total,
  items,
}) => {
  const { t } = useTranslation();
  const {
    filters: currentFilters,
    updateFilters,
    removeFilter,
    getFilter,
  } = useSearchParamsContext<CustomersFilters>();

  const navigate = useNavigate();

  useEffect(() => {
    fetch({
      query: {
        estimate_id,
        items: pageSize,
        sort: currentFilters.sort,
        direction: currentFilters.direction,
        search: currentFilters.search || undefined,
        page: currentFilters.page || 1,
      },
    });
  }, [currentFilters]);

  const columns = useMemo<ColumnDef<Prospect>[]>(
    () => [
      {
        header: t('prospect.email'),
        accessorKey: 'email',
        size: 200,
      },
      {
        header: t('prospect.phone'),
        accessorKey: 'phone',
      },
      {
        header: t('prospect.created_at'),
        accessorKey: 'created_at',
        cell: ({ row }) =>
          format(new Date(row.original.created_at), "dd/MM/YYY 'à' HH:mm"),
      },
      {
        header: t('prospect.pipedrive_link'),
        accessorKey: 'pipedrive_person_id',
        enableSorting: false,
        cell: ({ row }) =>
          row.original.pipedrive_person_id ? (
            <a
              href={`${env.pipedrive.url}/person/${row.original.pipedrive_person_id}`}
              target="_blank"
              rel="noreferrer"
            >
              {t('prospect.pipedrive_label')}
            </a>
          ) : null,
      },
      {
        header: '',
        accessorKey: 'id',
        size: 100,
        enableSorting: false,
        cell: ({ row }) => (
          <Flex justify="end" expand>
            <Button
              content={t('view')}
              size="small"
              primary
              onClick={() =>
                navigate(
                  getRouteWithParams(AdminAppRoutes.PROSPECTS_DETAILS, {
                    id: row.original.id,
                  }),
                )
              }
            />
          </Flex>
        ),
      },
    ],
    [history],
  );

  const currentSorting: SortingState = (() => {
    const sortColumn = getFilter('sort') as string;
    const sortDirection = getFilter('direction') as string;

    if (sortColumn) {
      return [
        {
          id: sortColumn,
          desc: sortDirection === 'desc',
        },
      ];
    }
    return [];
  })();

  const handleSortingChange: OnChangeFn<SortingState> = useCallback(
    (updaterOrValue) => {
      const updatedSorting = Array.isArray(updaterOrValue)
        ? updaterOrValue
        : updaterOrValue([]);

      try {
        const sortInfo = updatedSorting[0];

        if (sortInfo) {
          updateFilters({
            sort: sortInfo.id,
            direction: sortInfo.desc ? 'desc' : 'asc',
          });
        }
      } catch (error) {
        console.error('Erreur lors du tri:', error);
      }
    },
    [updateFilters],
  );

  return (
    <Table
      data={items}
      columns={columns}
      onPaginationChange={(pageIndex) => updateFilters({ page: pageIndex })}
      pageCount={Math.ceil((total || 0) / pageSize)}
      pagination={{
        pageIndex: getFilter('page')
          ? parseInt(getFilter('page') as string)
          : 1,
        pageSize: pageSize,
      }}
      sorting={currentSorting}
      onSortingChange={handleSortingChange}
    />
  );
};

export default ProspectsList;
