import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';

// Data
// Components
import { List, ListItem } from 'components/List';
import { Flex, Requires, Text } from 'components/Layout';

// Utils
import { theme } from 'theme';
import config from 'config/app';
import { Procedure, ProcedureStepType } from '../../../types/resources';
import DocumentPreviewModal from '../../../components/Modal/DocumentPreviewModal';

interface FormalityFileProps {
  procedure: Procedure;
  step: ProcedureStepType;
  letter: {
    date?: string | null;
    document_id?: string | null;
  };
}

const FormalityFile: FC<FormalityFileProps> = ({ procedure, letter, step }) => {
  const { t } = useTranslation();
  const [modalState, setModalState] = useState<boolean>(false);

  const { date, document_id } = letter;

  const onOpen = () => {
    setModalState(true);
  };

  const onClose = () => {
    setModalState(false);
  };

  const onPreview = () => {
    onOpen();
  };

  return (
    <Flex direction={{ xs: 'column' }} expand>
      <List title={t(`formality_letters.file_${step}.title`)}>
        <Flex expand>
          <ListItem
            iconName="Document"
            title={t(`formality_letters.document_name_${step}`)}
            buttons={[
              {
                content: t(`formality_letters.file_${step}.see`),
                onClick: onPreview,
                primary: true,
                disabled: !document_id,
              },
            ]}
            iconColor={theme.colors.salmon1}
          >
            {date && (
              <Text
                fontStyle="body2"
                weight="medium"
                content={format(new Date(date), config.dateFormat)}
                marginRight={{ xs: 'space32' }}
                color={theme.colors.gray6}
              />
            )}
          </ListItem>
          <Requires value={document_id}>
            {document_id && (
              <DocumentPreviewModal
                opened={modalState}
                onClose={onClose}
                procedure={procedure}
                documentId={document_id}
              />
            )}
          </Requires>
        </Flex>
      </List>
    </Flex>
  );
};

export default FormalityFile;
