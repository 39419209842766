import { UiSchemaCustom } from '.';

const spouses: UiSchemaCustom[] = [
  {
    'ui:order': ['PARTIE2_CIVILITE', 'PARTIE2_NOM', 'PARTIE2_PRENOM'],
    PARTIE2_CIVILITE: {
      'ui:widget': 'radio',
    },
    PARTIE2_PRENOM: {
      'ui:field': 'CustomField',
      'ui:placeholder': 'Jean, David, Jacques',
      'ui:options': {
        uppercase: true,
      },
    },
    PARTIE2_NOM: {
      'ui:field': 'CustomField',
      'ui:placeholder': 'Durand',
      'ui:options': {
        uppercase: true,
      },
    },
  },
  {
    'ui:order': [
      'PARTIE2_DATE_NAISSANCE',
      'PARTIE2_VILLE_NAISSANCE',
      'WD_PARTIE2_PAYS_NAISSANCE',
      'PARTIE2_DPT_NAISSANCE',
      'PARTIE2_NATIONALITE',
      'PARTIE2_COMPREHENSION',
      'WD_PARTIE2_STATUT_PRO',
      'PARTIE2_PROFESSION',
    ],

    PARTIE2_PROFESSION: {
      'ui:field': 'CustomField',
      'ui:placeholder': 'Musicien',
    },
    WD_PARTIE2_STATUT_PRO: {
      'ui:widget': 'select',
      'ui:placeholder': 'Choisir un statut professionnel',
    },
    PARTIE2_DATE_NAISSANCE: {
      'ui:field': 'CustomDatepicker',
      'ui:placeholder': 'Choisir une date',
      'ui:options': {
        maxToday: true,
        openTo: 'year',
        view: ['year', 'month', 'day'],
      },
    },
    PARTIE2_VILLE_NAISSANCE: {
      'ui:field': 'CustomField',
      'ui:placeholder': 'Paris',
      'ui:options': {
        uppercase: true,
      },
    },
    PARTIE2_DPT_NAISSANCE: {
      'ui:widget': 'select',
      'ui:placeholder': 'Choisir un département de naissance',
    },
    WD_PARTIE2_PAYS_NAISSANCE: {
      'ui:widget': 'select',
      'ui:placeholder': 'Choisir un pays de naissance',
    },
    PARTIE2_NATIONALITE: {
      'ui:widget': 'select',
      'ui:placeholder': 'Choisir une nationalité',
    },
    PARTIE2_COMPREHENSION: {
      'ui:widget': 'radio',
    },
  },
  {
    'ui:order': [
      'PARTIE2_ADRESSE',
      'PARTIE2_COMPLEMENT_ADRESSE',
      'PARTIE2_CP',
      'PARTIE2_VILLE',
    ],
    PARTIE2_VILLE: {
      'ui:field': 'CustomField',
      'ui:placeholder': 'Paris',
      'ui:options': {
        uppercase: true,
      },
    },
    PARTIE2_CP: {
      'ui:field': 'CustomField',
      'ui:placeholder': '75000',
    },
    PARTIE2_ADRESSE: {
      'ui:field': 'CustomField',
      'ui:placeholder': '2 rue de la paix',
      'ui:options': {
        uppercase: true,
      },
    },
    PARTIE2_COMPLEMENT_ADRESSE: {
      'ui:field': 'CustomField',
      'ui:placeholder': 'Bâtiment B',
      'ui:options': {
        uppercase: true,
      },
    },
  },
  {
    'ui:order': [
      'PARTIE2_POST_IS_TRUE',
      'PARTIE2_DATE_POST',
      'PARTIE2_ADRESSE_POST',
      'PARTIE2_VILLE_POST',
      'PARTIE2_CP_POST',
    ],
    PARTIE2_VILLE_POST: {
      'ui:field': 'CustomField',
      'ui:options': {
        label: true,
        inputType: 'text',
        uppercase: true,
      },
      'ui:placeholder': 'Paris',
    },
    PARTIE2_CP_POST: {
      'ui:field': 'CustomField',
      'ui:options': {
        label: true,
        inputType: 'text',
      },
      'ui:placeholder': '75000',
    },
    PARTIE2_ADRESSE_POST: {
      'ui:field': 'CustomField',
      'ui:options': {
        label: true,
        inputType: 'text',
        uppercase: true,
      },
      'ui:placeholder': '2 rue de la paix',
    },
    PARTIE2_DATE_POST: {
      'ui:field': 'CustomDatepicker',
      'ui:placeholder': 'Choisir une date',
      'ui:options': {
        inputFormat: 'MM/yyyy',
        mask: '__/____',
        view: ['month', 'year'],
      },
    },
    PARTIE2_POST_IS_TRUE: {
      'ui:widget': 'radio',
    },
  },
  {
    'ui:order': [
      'PARTIE2_TYPE_REVENU',
      'PARTIE2_NET_MENSUEL',
      'REVENUS2_YESNO',
      'REVENUS2',
    ],
    PARTIE2_NET_MENSUEL: {
      'ui:field': 'CustomCurrency',
      'ui:placeholder': '1 600',
    },
    PARTIE2_TYPE_REVENU: {
      'ui:widget': 'select',
      'ui:placeholder': 'Choisir un type de revenu',
    },
    REVENUS2_YESNO: {
      'ui:widget': 'radio',
    },
    REVENUS2: {
      items: {
        'ui:order': ['TYPE', 'MONTANT'],
        'ui:options': {
          fullWidth: true,
          withoutMargin: true,
        },
        MONTANT: {
          'ui:field': 'CustomCurrency',
          'ui:placeholder': '1600',
        },
        TYPE: {
          'ui:widget': 'select',
          'ui:placeholder': 'Choisir un type de revenu',
        },
      },
    },
  },
];

export default spouses;
