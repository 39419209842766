import {
  ChangeEvent,
  forwardRef,
  InputHTMLAttributes,
  MouseEventHandler,
  useRef,
  useState,
} from 'react';

import { FieldError } from 'react-hook-form';
import { Flex } from 'components/Layout/Flex';
import { FontStyles } from 'theme/styles/fonts';
import { Label } from 'components/Text/Label';
import RequiredField from 'components/Forms/Utils/RequiredField';
import { Text } from 'components/Layout/Text';
// Types
import { Weights } from 'theme/styles/size';
// Utils
import { determineIfIsFieldErrorOrString } from 'utils/errors';
import styled from 'styled-components';
// Components
import { theme } from 'theme';
import { Button } from '../../Buttons';
import { IconProps } from '../../Images/Icon';
import { Spacing } from '../../../theme/styles/spacing';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  $size: Size;
  withError?: boolean;
  width?: string;
}

const Input = styled.input<InputProps>`
  display: none;
`;

type Size = 'small' | 'medium';

export interface InputFileProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
  label?: string;
  buttonLabel?: string;
  buttonPrimary?: boolean;
  buttonSize?: 'small' | 'medium';
  buttonIconRight?: IconProps;
  buttonIconRightMargin?: Spacing;
  hideFiles?: boolean;
  placeholder?: string;
  description?: string;
  descriptionFontStyle?: FontStyles;
  $size?: Size;
  width?: string;
  error?: FieldError | string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  required?: boolean;
  labelWeight?: Weights;
  labelUppercase?: boolean;
  labelFontStyle?: FontStyles;
  onChange: (files: File[]) => void;
  value?: File[];
}

export const InputFile = forwardRef((props: InputFileProps, ref) => {
  const {
    placeholder,
    label,
    buttonLabel,
    buttonPrimary,
    buttonSize = 'medium',
    buttonIconRight,
    buttonIconRightMargin,
    hideFiles,
    description,
    descriptionFontStyle = 'body2',
    error,
    $size = 'medium',
    required,
    onChange,
    width,
    labelFontStyle,
    labelWeight,
    disabled,
    value,
    ...otherProps
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<File[]>(value ?? []);

  const onClick = () => {
    inputRef.current?.click();
  };

  const _onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const eventFiles = event.target.files;
    const filesArr = [];

    if (eventFiles) {
      for (let i = 0; i < eventFiles.length; i++) {
        const file = eventFiles.item(i);
        if (file) {
          filesArr.push(file);
        }
      }
    }

    setFiles(filesArr);
    onChange(filesArr);
  };

  return (
    <Flex direction={{ xs: 'column' }} width="100%">
      {label && (
        <Flex marginBottom={{ xs: 'space8' }}>
          <RequiredField required={required}>
            <Label
              dangerouslySetInnerHTML={{ __html: label }}
              fontStyle={labelFontStyle}
              weight={labelWeight}
            />
          </RequiredField>
        </Flex>
      )}
      {description && (
        <Flex marginBottom={{ xs: 'space8' }}>
          <Text fontStyle={descriptionFontStyle} content={description} />
        </Flex>
      )}
      <Button
        content={buttonLabel ?? 'Sélectionner un fichier'}
        type={'button'}
        size={buttonSize}
        primary={buttonPrimary ?? false}
        iconRight={buttonIconRight}
        iconRightMargin={buttonIconRightMargin}
        onClick={onClick}
      />
      {files.length > 0 &&
        !hideFiles &&
        files.map((f) => (
          <Text key={f.name} fontStyle={'body1'} content={f.name} />
        ))}
      <Input
        ref={inputRef}
        type={'file'}
        placeholder={placeholder}
        $size={$size}
        width={width}
        onChange={_onChange}
        withError={!!error}
        disabled={disabled}
        {...otherProps}
      />
      {error && (
        <Text
          content={
            error && determineIfIsFieldErrorOrString(error)
              ? error.message
              : error
          }
          fontStyle="body2"
          color={theme.colors.red1}
          marginTop={{ xs: 'space8' }}
        />
      )}
    </Flex>
  );
});

InputFile.displayName = 'InputFile';
