import * as Sentry from '@sentry/react';
import { Env } from './env';

export function initSentry(env: Env) {
  const isProd = env.envName === 'production';
  if (!isProd) {
    console.warn('sentry', 'initializing sentry');
  }

  if (Sentry.isInitialized()) {
    return;
  }

  // Sentry.init({
  //   enabled: !!env.sentry.dsn,
  //   dsn: env.sentry.dsn,
  //   environment: env.envName,
  //   release: env.version,
  //   integrations: [
  //     Sentry.reactRouterV6BrowserTracingIntegration({
  //       router: BrowserRouter,
  //     }),
  //     Sentry.browserProfilingIntegration(),
  //     Sentry.replayIntegration(),
  //   ],
  //
  //   attachStacktrace: true,
  //   tracesSampleRate: 1.0, //  Capture 100% of the transactions
  //
  //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //   // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  //
  //   replaysSessionSampleRate: 0.1,
  //   replaysOnErrorSampleRate: 1.0,
  // });
}
