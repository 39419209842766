import { FC } from 'react';
import { LocalizationMap, Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import fr_FR from '@react-pdf-viewer/locales/lib/fr_FR.json';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

interface PDFViewerProps {
  src: string;
  fileName: string;
  id: string;
}

const PDFViewer: FC<PDFViewerProps> = ({ src, fileName, id }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
      <div style={{ height: '100%', width: '100%' }}>
        <Viewer
          localization={fr_FR as unknown as LocalizationMap}
          fileUrl={src}
          plugins={[defaultLayoutPluginInstance]}
        />
      </div>
    </Worker>
  );
};

export default PDFViewer;
