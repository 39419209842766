import { Flex } from 'components/Layout/Flex';
import { FC } from 'react';
import SearchOpportunity from '../search/SearchOpportunity';

const CasesListOptionsFilter: FC = () => {
  return (
    <Flex direction={{ xs: 'row' }} alignItems="center" gap={{ xs: 'space16' }}>
      <SearchOpportunity />
    </Flex>
  );
};

export default CasesListOptionsFilter;
