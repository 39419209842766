import { FC } from 'react';

// Components
import { Col, Container, Row } from 'components/Layout';

// Containers
import { AdministrativeDocumentsList, FinalCard } from 'container/client';

const ClientFinal: FC = () => (
  <Container>
    <Row marginTop={{ xs: 'space32' }}>
      <Col xs={12} md={4}>
        <FinalCard />
      </Col>
      <Col xs={12} md={8}>
        <AdministrativeDocumentsList />
      </Col>
    </Row>
  </Container>
);

export default ClientFinal;
