import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';

import { theme } from 'theme';

import { Flex, Requires, Text } from 'components/Layout';
import { Button, IconButton, InlineButton } from 'components/Buttons';
import { InputText } from 'components/FormTemplate/Fields/InputText';
import { InputTel } from 'components/FormTemplate/Fields/InputTel';
import { regexes } from 'utils/regex';
import { Ghost } from 'components/Loading';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';
import { md } from 'theme/styles/mediaQueries';
import { ClientAppRoutes } from 'ClientApp';
import { useApi } from '../../../hooks/useApi';
import { User } from '../../../types/resources';
import toast from 'react-hot-toast';

const FormWrapper = styled.form`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: ${theme.spacing.space40} ${theme.spacing.space40};
  margin-bottom: ${theme.spacing.space40};

  ${md(`
    grid-template-columns: 1fr 1fr;
  `)}
`;

const CustomInput = styled(InputText)`
  width: 100%;
`;

export interface Field {
  type?: 'email' | 'text' | 'tel';
  name: 'first_name' | 'last_name' | 'email' | 'phone';
  regex?: RegExp;
  value: string;
  validate?: (value?: string | null) => boolean | string;
}

interface PersonalInformationsProps {
  editable?: boolean;
  user: User | null;
}

type UpdateUserInput = {
  last_name: string;
  first_name: string;
  email: string;
  phone: string;
};

const PersonalInformations: FC<PersonalInformationsProps> = ({
  editable,
  user,
}) => {
  const [edited, setEdited] = useState<boolean>();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    watch,
    setValue,
  } = useForm<UpdateUserInput>();

  watch((data) => {
    const keys = Object.keys(data);
    const hasBeenEdited = keys.some((key) => {
      const rkey = key as Field['name'];
      return !!data[rkey];
    });
    setEdited(hasBeenEdited);
  });

  const fields: Field[] = [
    {
      name: 'last_name',
      regex: regexes.lettersOnly,
      value: user?.last_name || '',
    },
    {
      name: 'first_name',
      regex: regexes.lettersOnly,
      value: user?.first_name || '',
    },
    {
      name: 'email',
      regex: regexes.email,
      value: user?.email || '',
      type: 'email',
    },
    {
      name: 'phone',
      // regex: regexes.phone,
      value: user?.phone || '',
      type: 'tel',
    },
  ];
  const disabledFields: string[] = ['email'];

  const { execute: updateUser, state: updateUserState } = useApi(`/auth/user`, {
    method: 'PATCH',
  });

  const onSubmit = async (data: UpdateUserInput) => {
    updateUser({
      body: data,
      onSuccess: () => {
        toast.success(t('account.update.submit_success'));
      },
      onError: () => {
        toast.error(t('account.update.submit_error'));
      },
    });
  };

  useEffect(() => {
    if (updateUserState.success) {
      setEdited(false);
    }
  }, [updateUserState.success]);

  const onModifyInformation = () => {
    navigate(ClientAppRoutes.MESSAGING);
  };

  return (
    <Flex direction={{ xs: 'column' }} flex={1}>
      <Flex marginBottom={{ xs: 'space24' }} alignItems="center">
        <Requires value={!editable}>
          <IconButton
            iconName="User"
            color={theme.colors.salmon2}
            backgroundColor={theme.colors.salmon3}
            rounded
          />
        </Requires>
        <Text
          fontStyle="heading4"
          content={t('user.personal_informations')}
          marginLeft={{ xs: editable ? 'none' : 'space16' }}
        />
      </Flex>
      <FormWrapper id="form" onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field) => {
          const { regex, name, value, type } = field;
          const pattern = regex
            ? {
                value: regex,
                message: t(`quote.payment.invoice.${name}_error`),
              }
            : undefined;
          if (!user) {
            return (
              <Flex direction={{ xs: 'column' }} key={name}>
                <Text
                  fontStyle="body1"
                  content={t(`user.${name}`)}
                  marginBottom={{ xs: 'space16' }}
                  weight="medium"
                />
                <Ghost width="100%" height="64px" shape="rect" rx={24} />
              </Flex>
            );
          }
          if (type === 'tel') {
            return (
              <Controller
                key={`field_${name}`}
                name={name}
                rules={{
                  pattern,
                  validate: (value?: string | null) => {
                    if (value && !isValidPhoneNumber(value)) {
                      return t('error.form.phone').toString();
                    }
                  },
                }}
                control={control}
                render={() => (
                  <InputTel
                    disabled={!editable || disabledFields.includes(name)}
                    value={getValues(name) || value}
                    onChange={(value) => {
                      setValue('phone', value);
                    }}
                    label={t(`user.${name}`)}
                    type={type}
                    error={errors[name]}
                    placeholder={t(`quote.payment.invoice.${name}_placeholder`)}
                    width="100%"
                  />
                )}
              />
            );
          }
          return (
            <Controller
              key={`field_${name}`}
              name={name}
              rules={{ pattern }}
              control={control}
              render={({ field }) => (
                <CustomInput
                  disabled={!editable || disabledFields.includes(name)}
                  value={getValues(name) ?? value}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  label={t(`user.${name}`)}
                  type={type}
                  error={errors[name]}
                  placeholder={t(`quote.payment.invoice.${name}_placeholder`)}
                  width="100%"
                />
              )}
            />
          );
        })}
      </FormWrapper>
      <Requires value={!editable}>
        <Flex direction={{ xs: 'column' }}>
          <Text
            fontStyle="body1"
            color={theme.colors.gray6}
            content={t('user.modify_information_warning')}
            marginBottom={{ xs: 'space40' }}
          />
          <Flex direction={{ xs: 'column', md: 'row' }} justify="between">
            <InlineButton
              onClick={onModifyInformation}
              iconColor={theme.colors.salmon1}
              text={t('user.ask_modification')}
              backgroundColor={theme.colors.salmon3}
              iconName="ArrowRight"
              hoverBackgroundColor={theme.colors.salmon2}
              hoverIconColor={theme.colors.white}
              noWrap={false}
            />
          </Flex>
        </Flex>
      </Requires>
      <Requires value={editable}>
        <Flex marginBottom={{ xs: 'space16' }}>
          <Button
            type="submit"
            content={t('user.valid_changes')}
            disabled={!edited}
            form="form"
            $loading={updateUserState.loading}
          />
        </Flex>
      </Requires>
    </Flex>
  );
};

export default PersonalInformations;
