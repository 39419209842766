import React, { FC, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useViewport } from 'hooks/useViewport';

// Components
import { theme } from 'theme';
import { Col, Container, Flex, Requires, Row, Text } from 'components/Layout';

// Container
import { RolesOpportunityList, StepperOpportunity } from 'container/common';
import {
  ClientCards,
  LeadDashboard,
  ManagerInfo,
  MobileClientHome,
} from 'container/client';
import { useApi } from '../../hooks/useApi';
import {
  CustomerEstimate,
  EstimateFull,
  ProcedureFull,
  ProcedureSpouse,
} from '../../types/resources';
import { useCustomer } from '../../hooks/useCustomer';
import { ClientAppRoutes } from '../../ClientApp';
import { useNavigate } from 'react-router-dom';

const CustomContainer = styled(Container)`
  padding-top: ${theme.spacing.space32};
  padding-bottom: ${theme.spacing.space32};
`;

const ClientHome: FC = () => {
  const { t } = useTranslation();
  const { customer } = useCustomer();
  const { isMobile } = useViewport();
  const navigate = useNavigate();

  const { execute: getCustomerProcedure, state: getCustomerProcedureState } =
    useApi<ProcedureFull>(`/procedures/${customer?.procedure_id}`);
  const procedure = getCustomerProcedureState.data?.value;

  const { execute: getEstimate, state: getEstimateState } =
    useApi<EstimateFull>(`/estimates/${procedure?.estimate_id}`);
  const estimate = getEstimateState.data?.value;

  const { execute: getProcedureSpouse, state: getProcedureSpouseState } =
    useApi<ProcedureSpouse>(
      `/procedures/${customer?.procedure_id}/spouses/${customer?.id}`,
    );
  const spouse = getProcedureSpouseState.data?.value;

  const { execute: getEstimateCustomers, state: getEstimateCustomersState } =
    useApi<CustomerEstimate[]>(
      `/estimates/${procedure?.estimate_id}/customers`,
    );
  const estimateCustomers = getEstimateCustomersState.data?.value ?? [];
  const customerPayment = estimateCustomers.find(
    (ec) => ec.customer_id === customer?.id && ec.amount_paid,
  );
  const customerHasPaid = customerPayment && customerPayment.amount_paid > 0;
  const hasToPay =
    estimate !== undefined &&
    Boolean(estimate.split_payments) &&
    !customerHasPaid;

  const getEstimateCb = useCallback(() => {
    if (procedure?.estimate_id) {
      getEstimate();
      getEstimateCustomers();
      getProcedureSpouse();
    }
  }, [procedure]);

  useEffect(() => {
    if (customer?.procedure_id) {
      getCustomerProcedure();
    }
  }, [customer?.procedure_id]);

  useEffect(() => {
    if (procedure) {
      getEstimateCb();
    }
  }, [procedure]);

  useEffect(() => {
    if (estimate) {
      getEstimateCustomers();
    }
  }, [estimate]);

  if (!customer) return null;

  const showLeadDashboard = !customer?.procedure_id;

  return (
    <CustomContainer fluid={'xl'}>
      <Row marginBottom={{ xs: 'space32' }}>
        <Col xs={12}>
          <Flex
            direction={{ xs: 'column', md: 'row' }}
            justify={isMobile ? 'start' : 'between'}
            alignItems={isMobile ? 'start' : 'center'}
            width="100%"
          >
            <Text
              content={t('welcome_name', {
                name: customer.full_name,
              })}
              fontStyle={isMobile ? 'heading4' : 'heading2'}
              marginBottom={{
                xs: showLeadDashboard ? 'none' : 'space24',
                md: 'none',
              }}
            />
            {procedure && (
              <ManagerInfo
                manager={procedure.manager}
                onBookMeeting={() => navigate(ClientAppRoutes.SUPPORT_CALL)}
              />
            )}
          </Flex>
        </Col>
      </Row>
      {showLeadDashboard ? (
        <>
          <Row marginBottom={{ xs: 'none', md: 'space32' }}>
            <Col xs={12} desktopOnly>
              <LeadDashboard dealId={customer?.current_estimate_id} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} mobileOnly>
              <MobileClientHome estimateId={customer?.current_estimate_id} />
            </Col>
          </Row>
        </>
      ) : (
        <Requires value={procedure}>
          <Row marginBottom={{ xs: 'none', md: 'space32' }}>
            <Col xs={12} desktopOnly>
              <StepperOpportunity
                estimate={estimate!}
                hasToPay={hasToPay}
                spouse={spouse}
                conversations={[]}
                procedure={procedure!}
              />
            </Col>
          </Row>
          <ClientCards
            procedure={procedure!}
            userId={customer.id}
            spouse={spouse}
          />
          <Row>
            <Col xs={12} desktopOnly>
              <RolesOpportunityList
                procedure={procedure!}
                estimate={estimate!}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} mobileOnly>
              <MobileClientHome procedure={procedure} />
            </Col>
          </Row>
        </Requires>
      )}
    </CustomContainer>
  );
};

export default ClientHome;
