import React, { FC, Fragment, MouseEventHandler, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Components
import { theme } from 'theme';
import { md } from 'theme/styles/mediaQueries';
import { DesktopOnly, Flex, MobileOnly, Text } from 'components/Layout';
import { Card } from 'components/Cards';
import { AddButton, IconButton } from 'components/Buttons';
import { InputText } from 'components/FormTemplate/Fields/InputText';
import { Icon } from 'components/Images/Icon';
import { Ghost } from 'components/Loading';

// Containers
import { AssignModalProps } from 'container/admin/AssignModal/AssignModal';

// Hooks
import { setIsModalOpen } from 'hooks/useModal';
import { useViewport } from 'hooks/useViewport';
import { getRouteWithParams } from 'utils/router';
import { AdminAppRoutes } from 'AdminApp';
import { useNavigate } from 'react-router-dom';
import { ProcedureFull, Role } from '../../../../types/resources';

const Block = styled(Flex)`
  width: 100%;
  padding: ${theme.spacing.space24} 0;
  border-bottom: 1px solid ${theme.colors.gray3};

  &:last-child {
    border: none;
  }

  ${md(`
    border-bottom: none;
  `)}
`;

const FlexWithBorder = styled(Flex)`
  width: 100%;
  border-bottom: 1px solid ${theme.colors.gray3};

  &:last-child {
    border: none;
  }
`;

const TabFlex = styled(Flex)`
  overflow-x: auto;
`;

const IconContainer = styled(Flex)<IconContainerProps>`
  transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 0.3s ease-in-out;
  padding: ${theme.spacing.space24};
  margin: -${theme.spacing.space24};
`;

const FlexWithPointer = styled(Flex)<FlexWithPointerProps>`
  cursor: ${(props) => props.onClick && 'pointer'};
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
`;

interface IconContainerProps {
  isOpen: boolean;
}

interface FlexWithPointerProps {
  onClick?: MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
}

interface UserBlockProps {
  user?: { id: string; email: string; first_name: string; last_name: string };
  isEditable?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
  isMobile?: boolean;
}

const UserBlock: FC<UserBlockProps> = ({
  user,
  isEditable,
  onClick,
  isMobile,
}) => {
  const [hoverState, setHoverState] = useState(false);

  return (
    <Button
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
    >
      <FlexWithPointer
        marginLeft={{ xs: 'none', md: isEditable ? 'none' : 'space8' }}
        alignItems="center"
        height="40px"
        onClick={onClick}
      >
        {isEditable ? (
          <IconButton
            iconName={'EditSquare'}
            color={hoverState ? theme.colors.salmon1 : theme.colors.black}
            backgroundColor={'transparent'}
            rounded
            size="medium"
            style={{ marginLeft: isMobile ? '-4px' : 'none' }}
          />
        ) : (
          <IconButton
            iconName={'User'}
            color={theme.colors.green1}
            backgroundColor={theme.colors.green2}
            rounded
            size="small"
            style={{ marginLeft: isMobile ? '-4px' : 'none' }}
            stroke="regular"
          />
        )}
        <Text
          content={`${user?.first_name} ${user?.last_name}`}
          fontStyle="body2"
          weight="medium"
          marginLeft={{ xs: isEditable ? 'none' : 'space16' }}
          color={isEditable ? theme.colors.black : theme.colors.gray6}
        />
      </FlexWithPointer>
    </Button>
  );
};

interface RenderColumnProps extends Columns {
  onClick: MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
}

const RenderColumn: FC<RenderColumnProps> = ({
  buttonTitle,
  userColumn,
  onClick,
  roleTitle,
  assignModalContent,
}) => {
  const { isMobile } = useViewport();
  return (
    <Block direction={{ xs: 'column', md: 'row' }} expand>
      <Flex direction={{ xs: 'column' }}>
        {userColumn?.length ? (
          userColumn?.map((user, index) => {
            return (
              <div key={user?.id}>
                {/* Title for MANAGER */}
                {[Role.MANAGER].includes(assignModalContent.type) && (
                  <MobileOnly>
                    <Text
                      content={roleTitle}
                      color={theme.colors.green1}
                      fontStyle="body2"
                      weight="medium"
                      marginLeft={{ xs: 'space8' }}
                    />
                  </MobileOnly>
                )}
                {/* User Block with edit button if LAWYER OR MANAGER OR CONTROL*/}
                {user && (
                  <Flex width="100%">
                    {(userColumn.length === 2 && index === 1) ||
                    [Role.MANAGER].includes(assignModalContent.type) ? (
                      <Flex marginTop={{ xs: 'space16' }}>
                        <UserBlock
                          user={user}
                          isEditable
                          onClick={onClick}
                          isMobile={isMobile}
                        />
                      </Flex>
                    ) : (
                      <>
                        {/* Icon for MANAGER */}
                        {[Role.MANAGER].includes(assignModalContent.type) ? (
                          <Flex
                            marginTop={{ xs: 'space16' }}
                            onClick={onClick}
                            style={{ cursor: 'pointer' }}
                          >
                            <UserBlock user={user} isEditable />
                          </Flex>
                        ) : (
                          <UserBlock user={user} />
                        )}
                      </>
                    )}
                  </Flex>
                )}

                {[Role.LAWYER].includes(assignModalContent.type) &&
                  userColumn.length !== 2 && (
                    <Flex marginTop={{ xs: 'space16' }}>
                      <AddButton onClick={onClick} content={buttonTitle} />
                    </Flex>
                  )}
              </div>
            );
          })
        ) : (
          <>
            <MobileOnly>
              <Text
                content={roleTitle}
                color={theme.colors.green1}
                fontStyle="body2"
                weight="medium"
                marginLeft={{ xs: 'space8' }}
              />
            </MobileOnly>
            <Flex marginTop={{ xs: 'space16' }}>
              <AddButton onClick={onClick} content={buttonTitle} />
            </Flex>
          </>
        )}
      </Flex>
    </Block>
  );
};

type UserColumns = (
  | { id: string; email: string; first_name: string; last_name: string }
  | undefined
  | null
)[];

interface Columns {
  key?: string;
  userColumn?: UserColumns;
  buttonTitle: string;
  assignModalContent: AssignModalProps['content'];
  roleTitle?: string;
}

interface OpportunitiesItemProps {
  cases: ProcedureFull[] | undefined;
  loading: boolean;
  handleOpenAssignModal: (
    assignModalContent: AssignModalProps['content'],
  ) => void;
}

const renderLoading = () => (
  <>
    <DesktopOnly>
      <FlexWithBorder
        justify="between"
        alignItems="center"
        style={{
          padding: `${theme.spacing.space16}`,
        }}
      >
        <Ghost width="100%" height={100} shape="rect" />
      </FlexWithBorder>
      <FlexWithBorder
        justify="between"
        alignItems="center"
        style={{
          padding: `${theme.spacing.space16}`,
        }}
      >
        <Ghost width="100%" height={100} shape="rect" />
      </FlexWithBorder>
    </DesktopOnly>
    <MobileOnly>
      <Flex width="100%" justify="between" alignItems="center">
        <Ghost width="100%" height={100} shape="rect" />
      </Flex>
      <Flex width="100%" justify="between" alignItems="center">
        <Ghost width="100%" height={100} shape="rect" />
      </Flex>
    </MobileOnly>
  </>
);

const OpportunitiesItem: FC<OpportunitiesItemProps> = ({
  cases,
  loading,
  handleOpenAssignModal,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean[]>([]); // which items is open or not
  if (cases?.length === 0)
    return (
      <Flex>
        <Text
          content={t('roles_opportunity.no_opportunity_found')}
          fontStyle="body2"
          color={theme.colors.red1}
          marginTop={{ xs: 'space16' }}
          marginBottom={{ xs: 'space16' }}
          marginRight={{ xs: 'space16' }}
          marginLeft={{ xs: 'space24' }}
        />
      </Flex>
    );
  return (
    <>
      {loading && renderLoading()}
      {cases?.map((c, index) => {
        const columns: Columns[] = [
          {
            key: `spouseLeadRole-${c.id}`,
            userColumn: [c.spouse1, c.lawyer1],
            buttonTitle: t('roles_opportunity.role.LAWYER'),
            assignModalContent: {
              title: t('opportunity.add_lawyer_spouse', {
                firstname: c.spouse1?.first_name,
                lastname: c.spouse1?.last_name,
              }),
              type: Role.LAWYER,
              role: Role.LAWYER,
              opportunityIds: [c.id],
              assignedRoleId: c.lawyer1_id,
              spouseId: c.spouse1_id,
            },
          },
          {
            key: `spouseFollowerRole-${c.id}`,
            userColumn: [c.spouse2, c.lawyer2],
            buttonTitle: t('roles_opportunity.role.LAWYER'),
            assignModalContent: {
              title: t('opportunity.add_lawyer_spouse', {
                firstname: c.spouse2?.first_name,
                lastname: c.spouse2?.last_name,
              }),
              type: Role.LAWYER,
              role: Role.LAWYER,
              opportunityIds: [c.id],
              assignedRoleId: c.lawyer2_id,
              spouseId: c.spouse2_id,
            },
          },
          {
            key: `managerRole-${c.id}`,
            userColumn: [c.manager],
            buttonTitle: t('roles_opportunity.role.MANAGER'),
            assignModalContent: {
              title: t('opportunity.add_manager'),
              type: Role.MANAGER,
              role: Role.MANAGER,
              opportunityIds: [c.id],
              assignedRoleId: c.manager?.id,
              spouseId: undefined,
            },
            roleTitle: t('roles_opportunity.role.MANAGER'),
          },
        ];

        return (
          <Fragment key={`opportunities-mobile-item-${c.id}-${index}`}>
            <Flex marginTop={{ xs: 'space8' }} mobileOnly>
              <Card padding={theme.spacing.space32}>
                <Flex alignItems="center" justify="between">
                  <Flex
                    direction={{ xs: 'column' }}
                    onClick={() => {
                      navigate({
                        pathname: getRouteWithParams(
                          AdminAppRoutes.CASES_DETAILS,
                          { id: c.id },
                        ),
                      });
                    }}
                  >
                    <Text
                      fontStyle="body2"
                      color={theme.colors.gray5}
                      content={`Dossier N° ${c.reference}`}
                      marginBottom={{ xs: 'space8' }}
                    />
                    <Text fontStyle="body1" weight="bold" content={c.name} />
                  </Flex>
                  <IconContainer
                    onClick={() => {
                      const updatedArray = [...isOpen];
                      updatedArray[index] = !updatedArray[index];
                      setIsOpen(updatedArray);
                    }}
                    isOpen={isOpen[index]}
                  >
                    <Icon
                      name="ChevronDown"
                      primaryColor={theme.colors.salmon1}
                    />
                  </IconContainer>
                </Flex>
                {isOpen[index] &&
                  columns.map(
                    ({
                      key,
                      userColumn,
                      buttonTitle,
                      assignModalContent,
                      roleTitle,
                    }) => (
                      <React.Fragment key={key}>
                        <RenderColumn
                          userColumn={userColumn?.filter((user) => user)}
                          buttonTitle={buttonTitle}
                          assignModalContent={assignModalContent}
                          roleTitle={roleTitle}
                          onClick={() =>
                            handleOpenAssignModal(assignModalContent)
                          }
                        />
                      </React.Fragment>
                    ),
                  )}
              </Card>
            </Flex>
            <FlexWithBorder
              key={`row-${c.id}`}
              alignItems="end"
              justify="between"
              desktopOnly
            >
              <Block alignSelf="start" marginTop={{ xs: 'space8' }}>
                <Flex
                  direction={{ xs: 'column' }}
                  justify="between"
                  onClick={() => {
                    navigate({
                      pathname: getRouteWithParams(
                        AdminAppRoutes.CASES_DETAILS,
                        { id: c.id },
                      ),
                    });
                  }}
                  cursor="pointer"
                >
                  <Text
                    content={c.name}
                    fontStyle="body2"
                    weight="bold"
                    marginLeft={{ xs: 'space24' }}
                  />
                  <Text
                    content={`N° ${c.reference}`}
                    fontStyle="body2"
                    weight="bold"
                    color={theme.colors.gray5}
                    marginLeft={{ xs: 'space24' }}
                    marginTop={{ xs: 'space40' }}
                  />
                </Flex>
              </Block>
              {columns.map(
                ({ key, userColumn, buttonTitle, assignModalContent }) => (
                  <React.Fragment key={key}>
                    <RenderColumn
                      userColumn={userColumn?.filter((user) => user)}
                      buttonTitle={buttonTitle}
                      assignModalContent={assignModalContent}
                      onClick={() => handleOpenAssignModal(assignModalContent)}
                    />
                  </React.Fragment>
                ),
              )}
            </FlexWithBorder>
          </Fragment>
        );
      })}
    </>
  );
};

interface AssignOpportunitiesListProps {
  proceduresToAssign: ProcedureFull[];
  proceduresToAssignTotal: number;
  proceduresToAssignLoading: boolean;
  setIsAssignModalOpen: setIsModalOpen;
  setModalContent: (content: AssignModalProps['content']) => void;
  onSearchChanged?: (search?: string) => void;
  isModalOpen: boolean;
}

const AssignOpportunitiesList: FC<AssignOpportunitiesListProps> = ({
  proceduresToAssign,
  proceduresToAssignTotal,
  proceduresToAssignLoading,
  setIsAssignModalOpen,
  setModalContent,
  onSearchChanged,
}) => {
  const { t } = useTranslation();

  const handleOpenAssignModal = (
    assignModalContent: AssignModalProps['content'],
  ) => {
    setModalContent(assignModalContent);
    setIsAssignModalOpen(true);
  };

  const handleSearch = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    onSearchChanged?.(target.value || undefined);
  };

  return (
    <>
      <Flex desktopOnly expand>
        <Card padding="none">
          <Flex
            justify="between"
            alignItems="center"
            paddingTop={{ xs: 'space24' }}
            paddingLeft={{ xs: 'space24' }}
            paddingRight={{ xs: 'space24' }}
          >
            <Flex alignItems="center">
              <IconButton
                iconName="Folder"
                color={theme.colors.salmon2}
                backgroundColor={theme.colors.salmon3}
                rounded
                stroke="regular"
              />
              <Text
                content={t('opportunity.opportunities_to_assign', {
                  count: proceduresToAssignTotal,
                })}
                fontStyle="heading5"
                weight="bold"
                marginLeft={{ xs: 'space8' }}
              />
            </Flex>
            <Flex>
              <InputText
                placeholder={t('opportunity.search_by_opportunity_number')}
                icon={{
                  name: 'Search',
                  primaryColor: theme.colors.salmon1,
                }}
                width={'352px'}
                $size="small"
                onChange={handleSearch}
              />
            </Flex>
          </Flex>
          <TabFlex direction={{ xs: 'column' }} marginTop={{ xs: 'space8' }}>
            <FlexWithBorder justify="between">
              <Block>
                <Text
                  content={t('opportunity.opportunity_and_number')}
                  fontStyle="body2"
                  color={theme.colors.gray6}
                  marginLeft={{ xs: 'space24' }}
                />
              </Block>
              <Block>
                <Text
                  content={t('opportunity.lawyer_spouse_1')}
                  fontStyle="body2"
                  color={theme.colors.gray6}
                  marginLeft={{ xs: 'space8' }}
                />
              </Block>
              <Block>
                <Text
                  content={t('opportunity.lawyer_spouse_2')}
                  fontStyle="body2"
                  color={theme.colors.gray6}
                  marginLeft={{ xs: 'space8' }}
                />
              </Block>
              <Block>
                <Text
                  content={t('opportunity.manager')}
                  fontStyle="body2"
                  color={theme.colors.gray6}
                  marginLeft={{ xs: 'space8' }}
                />
              </Block>
            </FlexWithBorder>
            <OpportunitiesItem
              cases={proceduresToAssign}
              loading={proceduresToAssignLoading}
              handleOpenAssignModal={handleOpenAssignModal}
            />
          </TabFlex>
        </Card>
      </Flex>
      <Flex
        alignItems="center"
        marginBottom={{ xs: 'space16' }}
        mobileOnly
        expand
      >
        <IconButton
          iconName="Folder"
          color={theme.colors.salmon2}
          backgroundColor={theme.colors.salmon3}
          rounded
        />
        <Text
          content={t('opportunity.opportunities_to_assign', {
            count: proceduresToAssignTotal,
          })}
          fontStyle="heading5"
          weight="bold"
          marginLeft={{ xs: 'space8' }}
        />
      </Flex>
      <Flex mobileOnly expand>
        <InputText
          placeholder={t('opportunity.search_by_opportunity_number')}
          icon={{
            name: 'Search',
            primaryColor: theme.colors.salmon1,
          }}
          $size="small"
          width="100%"
          onChange={(e) => handleSearch(e)}
        />
      </Flex>
      <Flex
        marginTop={{ xs: 'space8' }}
        direction={{ xs: 'column' }}
        mobileOnly
        expand
      >
        <OpportunitiesItem
          cases={proceduresToAssign}
          loading={proceduresToAssignLoading}
          handleOpenAssignModal={handleOpenAssignModal}
        />
      </Flex>
    </>
  );
};

export default AssignOpportunitiesList;
