import { FC, Fragment, useEffect } from 'react';
import PageForm from '../../../components/Pages/PageForm';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';
import { useApi } from '../../../hooks/useApi';
import { useTranslation } from 'react-i18next';
import { Page } from '../../../types/resources';
import Loader from 'react-loader-spinner';
import { theme } from '../../../theme';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

const PageEdit: FC = (props) => {
  const { t } = useTranslation();

  const { pageName } = useParams<{ pageName: string }>();

  const { execute: getPage, state: getPageState } = useApi<Page>(
    `/pages/${pageName}`,
    {
      method: 'GET',
    },
  );

  const {
    execute: editPage,
    state: { loading },
  } = useApi(`/Pages/${getPageState.data?.value.id}`, {
    method: 'PATCH',
  });

  useEffect(() => {
    getPage();
  }, []);

  return (
    <>
      <div>
        <Breadcrumbs />
        <h1 className="tw-font-bold tw-font-value tw-text-3xl tw-mb-8">
          {t('pages.edit.submit')}
        </h1>
        {getPageState.data?.value ? (
          <PageForm
            Page={getPageState?.data?.value}
            loading={loading}
            onSubmit={(data) => {
              editPage({
                body: data,
                onSuccess: () => {
                  toast.success(t('pages.edit.success'));
                },
                onError: () => {
                  toast.error(t('pages.edit.error'));
                },
              });
            }}
          />
        ) : (
          <Loader
            type="TailSpin"
            height={24}
            width={24}
            color={theme.colors.salmon1}
          />
        )}
      </div>
    </>
  );
};

export default PageEdit;
