import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import ConversationCard from 'components/Conversation/Card/Card';
import ConversationList from 'components/Conversation/List/List';
import { Ghost } from 'components/Loading';
import { useQueryParams } from 'hooks/useQueryParams';
import { theme } from 'theme';
import { Modal, ModalHeader } from 'components/Modal';
import { Flex, Text } from 'components/Layout';
import { Button } from 'components/Buttons';
import { useAuth } from '../../../hooks/useAuth';
import {
  ConversationFull,
  MessageFull,
  ProcedureSpouseFull,
  Role,
  SpouseRole,
} from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';

export type DeleteMessageMutationVariables = {
  noteId: string;
};

export type UpdateNoteOpportunityMutationVariables = {
  noteId: string;
  data: {
    content?: string;
    isValid?: boolean;
  };
};

const DEFAULT_ITEMS_PER_PAGE = 10;

interface AdminConversationProps {
  opportunityId: string;
}

export const AdminConversation: FC<AdminConversationProps> = ({
  opportunityId,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQueryParams();
  const conversationParam = query.get('conversation');
  const spouseParam = query.get('spouse');
  const [activeConversation, setActiveConversation] = useState<
    ConversationFull | undefined
  >(undefined);

  const { execute: getProcedureSpouses, state: getProcedureSpousesState } =
    useApi<ProcedureSpouseFull[]>(`/procedures/${opportunityId}/spouses`);
  const spouses = getProcedureSpousesState.data?.value ?? [];

  const { execute: getConversations, state: getConversationsState } = useApi<
    ConversationFull[]
  >(`/procedures/${opportunityId}/conversations`);
  const conversations = getConversationsState.data?.value ?? [];

  useEffect(() => {
    getConversations();
    getProcedureSpouses();
  }, []);

  const handleConversationClick = (conversationId: string) => {
    const conversation = conversations.find((c) => c.id === conversationId);

    setActiveConversation(conversation);

    navigate({
      pathname: location.pathname,
      search: `?conversation=${conversationId}`,
    });
  };

  const allConversations =
    conversations.map((conversation) => ({
      ...conversation,
      recipients: conversation.participants
        .filter((participant) => participant.role === Role.CUSTOMER)
        .map((participant) => {
          const spouse = spouses.find((s) => s.spouse_id === participant.id);

          return {
            full_name: participant.full_name,
            first_name: participant.first_name,
            role: participant.role,
            type:
              spouse?.number === 1
                ? SpouseRole.SPOUSE_LEAD
                : SpouseRole.SPOUSE_FOLLOWER,
          };
        }),
      lastMessage: conversation.last_message?.content || '',
      active: conversation.id === activeConversation?.id,
      onClick: () => handleConversationClick(conversation.id),
    })) || [];

  const activeConversationRecipients = allConversations.find(
    (c) => c.id === activeConversation?.id,
  );

  useEffect(() => {
    if (!conversations.length) return;

    if (conversationParam) {
      const conversation = conversations.find(
        (c) => c.id === conversationParam,
      );
      setActiveConversation(conversation);
    } else {
      const targetConversation =
        conversations.find((c) => c.spouse_id === spouseParam) ??
        conversations[0];
      setActiveConversation(targetConversation);

      navigate(
        {
          pathname: location.pathname,
          search: `?conversation=${targetConversation.id}`,
        },
        { replace: true },
      );
    }
  }, [conversations, conversationParam]);

  return (
    <Flex
      direction={{ xs: 'column', lg: 'row' }}
      style={{ gap: theme.spacing.space12 }}
    >
      <div style={{ width: '276px', minWidth: '276px' }}>
        {getConversationsState.loading ? (
          <Ghost width="100%" height="300px" shape="rect" rx={24} />
        ) : (
          <ConversationList conversations={allConversations} />
        )}
      </div>
      {activeConversation && (
        <SingleConversation
          conversation={activeConversation}
          recipients={activeConversationRecipients?.recipients ?? []}
        />
      )}
    </Flex>
  );
};

interface SingleConversationProps {
  conversation: ConversationFull;
  recipients: {
    role: Role;
    full_name: string;
    type?: SpouseRole;
  }[];
}

const SingleConversation: FC<SingleConversationProps> = ({
  conversation,
  recipients,
}) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [modalDelete, setModalDelete] = useState(false);
  const [noteIdToDelete, setNoteIdToDelete] = useState('');
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { execute: getMessages, state: getMessagesState } = useApi<
    MessageFull[]
  >(`/conversations/${conversation.id}/messages`);

  const { execute: createMessage } = useApi(
    `/conversations/${conversation.id}/messages`,
    { method: 'POST' },
  );

  const { execute: callApi, state: callApiState } = useApi('');

  const loading = callApiState.loading;
  const messages = getMessagesState.data?.value ?? [];

  useEffect(() => {
    if (conversation) {
      getMessages({
        query: {
          page: currentPage,
          items: DEFAULT_ITEMS_PER_PAGE,
        },
      });
    }
  }, [conversation]);

  // TODO
  // const groupedConversation = conversation?.type === ConversationType.GROUPED;
  const groupedConversation = false;
  const orangeRoles = [
    t(`enums:${Role.MANAGER}`),
    t(`enums:${Role.ADMIN}`),
    t(`enums:${Role.LAWYER}`),
  ].map((r) => r.toUpperCase());

  const handleSendMessage = useCallback(
    (content: { message: string; attachments: File[] }) => {
      if (!conversation) return;

      const body = new FormData();
      body.append('content', content.message);

      for (const attachment of content.attachments) {
        body.append('attachments', attachment, attachment.name);
      }

      createMessage({
        body,
        onSuccess: () => {
          getMessages({
            query: {
              page: currentPage,
              items: DEFAULT_ITEMS_PER_PAGE,
            },
          });
        },
      });
    },
    [conversation],
  );

  const handleUpdateMessage = ({
    noteId,
    data,
  }: UpdateNoteOpportunityMutationVariables) => {
    if (data.isValid) {
      callApi({
        method: 'POST',
        endpoint: `/conversations/${conversation.id}/messages/${noteId}/process`,
        onSuccess: () => {
          getMessages({
            query: {
              page: currentPage,
              items: DEFAULT_ITEMS_PER_PAGE,
            },
          });
        },
      });
    } else {
      callApi({
        method: 'PATCH',
        body: { content: data.content },
        endpoint: `/conversations/${conversation.id}/messages/${noteId}`,
        onSuccess: () => {
          handleCloseModal();
          getMessages({
            query: {
              page: currentPage,
              items: DEFAULT_ITEMS_PER_PAGE,
            },
          });
        },
      });
    }
  };

  const openModalDelete = ({ noteId }: DeleteMessageMutationVariables) => {
    setModalDelete(true);
    setNoteIdToDelete(noteId);
  };

  const handleDeleteMessage = useCallback(() => {
    callApi({
      method: 'DELETE',
      endpoint: `/conversations/${conversation.id}/messages/${noteIdToDelete}`,
      onSuccess: () => {
        handleCloseModal();
        getMessages({
          query: {
            page: currentPage,
            items: DEFAULT_ITEMS_PER_PAGE,
          },
        });
      },
    });
  }, [noteIdToDelete]);

  const handleCloseModal = () => {
    setModalDelete(false);
    setNoteIdToDelete('');
  };

  const messagesByDate = new Map<string, MessageFull[]>();

  messages.forEach((message) => {
    const messageDate = message.created_at.split('T')[0];

    if (!messagesByDate.has(messageDate)) {
      messagesByDate.set(messageDate, []);
    }

    messagesByDate.get(messageDate)?.push(message);
  });
  const formattedMessages = Array.from(messagesByDate, ([date, items]) => ({
    date,
    items,
  }));

  const conversationMessages = formattedMessages.map((message) => ({
    date: message.date,
    items: message.items.map(({ author, ...item }) => {
      const isClientMessage =
        author?.role === t(`enums:${Role.CUSTOMER}`).toUpperCase();
      const iAmTheAuthor = author?.id === user?.id;
      const fullname = author?.full_name
        ? author.full_name
        : 'Message automatique';
      const showOrangeIcon = author?.role
        ? orangeRoles.includes(author.role.toUpperCase())
        : false;

      return {
        ...item,
        fullname: iAmTheAuthor ? t('opportunity.conversation.me') : fullname,
        showOrangeIcon,
        isValid: !isClientMessage ? false : item.processed,
        icons: {
          showValid: isClientMessage,
          showDelete: !isClientMessage,
          showEdit: !isClientMessage,
        },
        onUpdate: handleUpdateMessage,
        onDelete: openModalDelete,
      };
    }),
  }));

  const handleFetchMore = () => {
    return;
    if (getMessagesState.data?.pagination?.total === messages.length) return;

    getMessages({
      query: {
        page: 1,
        items: DEFAULT_ITEMS_PER_PAGE,
      },
    });
  };

  if (!conversation) return <div>No data</div>;

  return (
    <>
      <ConversationCard
        conversation={conversation}
        recipients={recipients}
        messages={conversationMessages}
        onSendMessage={handleSendMessage}
        showGroupedTipCard={groupedConversation}
        fetchMore={handleFetchMore}
        canSendMessage={true}
      />
      <Modal opened={modalDelete} onClose={handleCloseModal} width="473px">
        <ModalHeader
          content={t('opportunity.conversation.modal_delete.title')}
          iconName="Danger"
        />
        <Text
          content={t('opportunity.conversation.modal_delete.description')}
          fontStyle="body1"
          color={theme.colors.gray6}
          marginBottom={{ xs: 'space16' }}
        />
        <Flex direction={{ xs: 'column', md: 'row' }}>
          <Button
            content={t('opportunity.conversation.modal_delete.cancel')}
            marginRight={{ xs: 'none', md: 'space16' }}
            marginBottom={{ xs: 'space16', md: 'none' }}
            onClick={handleCloseModal}
          />
          <Button
            primary
            content={t('opportunity.conversation.modal_delete.delete')}
            onClick={handleDeleteMessage}
            $loading={loading}
          />
        </Flex>
      </Modal>
    </>
  );
};
