import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { CircleArrowOutUpRight } from 'lucide-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface GroupedActionsProps {
  buttons: { label: string; onClick: () => void; hidden?: boolean }[];
  disabled?: boolean;
}

const GroupedActions: FC<GroupedActionsProps> = ({ buttons, disabled }) => {
  const { t } = useTranslation();

  const availableButtons = buttons.filter((b) => !b.hidden);

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button
          disabled={disabled}
          className="tw-relative tw-flex tw-items-center tw-gap-2 tw-p-3 tw-bg-[#28534F] tw-text-white tw-rounded-xl tw-text-sm disabled:tw-pointer-events-none disabled:tw-opacity-50"
          aria-label="Customise options"
        >
          <CircleArrowOutUpRight size={20} />
          {t('opportunity.grouped_actions.title')}
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="tw-bg-white tw-rounded-lg tw-shadow-lg tw-border tw-text-xs tw-border-gray-200 tw-z-50 tw-font-open tw-p-3 tw-max-w-[200px]"
          sideOffset={5}
        >
          <span>{t('opportunity.grouped_actions.description')}</span>
          <hr className="tw-my-2" />
          <div className="tw-flex tw-flex-col tw-gap-2">
            {availableButtons.length > 0 ? (
              availableButtons.map((button, index) => (
                <DropdownMenu.Item
                  key={index}
                  className="tw-bg-primary/20 tw-rounded-xl tw-text-xs tw-py-2 tw-px-3 tw-font-bold tw-cursor-pointer"
                  onClick={button.onClick}
                >
                  {button.label}
                </DropdownMenu.Item>
              ))
            ) : (
              <span className="tw-font-bold tw-text-center">
                {t('opportunity.grouped_actions.none')}
              </span>
            )}
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default GroupedActions;
