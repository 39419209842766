import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { theme } from 'theme';
import styled from 'styled-components';
import { downloadFileNew } from 'utils/downloadFile';

// Components
import { ClientCard } from 'components/Cards';
import { ClientAppRoutes } from 'ClientApp';
import { Flex, Text } from 'components/Layout';
import { IconButton } from 'components/Buttons/IconButton';
import { DocumentFull, Procedure } from '../../../../types/resources';
import { useApi } from '../../../../hooks/useApi';
import { formatDocumentTitle } from '../../../../utils/format';

interface CertificateCardProps {
  procedure: Procedure;
  administrativeDocuments: DocumentFull[];
}

const Card = styled(Flex)`
  padding: ${theme.spacing.space8} ${theme.spacing.space16}
    ${theme.spacing.space8} ${theme.spacing.space8};
  border-radius: ${theme.spacing.space16};
  border: 1px solid ${theme.colors.gray3};
`;

const StyledText = styled(Text)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const CertificateCard: FC<CertificateCardProps> = ({
  procedure,
  administrativeDocuments,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(ClientAppRoutes.ADMINISTRATIVE_DOCUMENTS);
  };

  const hasDocuments =
    administrativeDocuments && administrativeDocuments.length > 0;

  const { execute: downloadDocument } = useApi<{ url: string }>(``);

  const onDownloadDocument = (document: DocumentFull) => {
    if (document.file_id) {
      downloadDocument({
        endpoint: `/procedures/${procedure.id}/documents/${document.id}/download`,
        onSuccess: async (result) => {
          try {
            await downloadFileNew(
              result.value.url,
              formatDocumentTitle(document),
              procedure.reference.toString(),
            );
          } catch (e) {
            console.error(e);
          }
        },
      });
    }
  };

  const cardButton = [
    {
      content: t('see_more'),
      primary: true,
      fullWidth: true,
      disabled: !hasDocuments,
    },
  ];

  return (
    <>
      <ClientCard
        iconName="Document"
        title={t('opportunity.certificate.title')}
        onClick={handleClick}
        buttonsDirections={{ xs: 'column' }}
        buttons={cardButton}
      >
        {hasDocuments ? (
          <>
            {administrativeDocuments.map((document, index) => (
              <Card
                key={document.id}
                alignItems="center"
                justify="between"
                marginBottom={{
                  xs:
                    index !== administrativeDocuments.length - 1
                      ? 'space8'
                      : 'none',
                }}
              >
                <Flex alignItems="center" width="80%">
                  <IconButton
                    iconName="Document"
                    color={theme.colors.black}
                    backgroundColor={theme.colors.white}
                    size="large"
                    stroke="regular"
                  />
                  <StyledText
                    content={formatDocumentTitle(document)}
                    fontStyle="body1"
                    weight="medium"
                    color={theme.colors.black}
                  />
                </Flex>
                <IconButton
                  iconName="Download"
                  color={theme.colors.white}
                  backgroundColor={theme.colors.green1}
                  rounded
                  onClick={() => {
                    onDownloadDocument(document);
                  }}
                />
              </Card>
            ))}
          </>
        ) : (
          <Text
            content={t('opportunity.certificate.no_certificate')}
            fontStyle="body2"
            color={theme.colors.gray6}
          />
        )}
      </ClientCard>
    </>
  );
};

export default CertificateCard;
