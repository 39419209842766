import { FC } from 'react';
import { useForm, UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Data
// Components
import { Modal } from './Modal';
import { regexes } from 'utils/regex';
import { Flex, Line, Text } from 'components/Layout';
import { InputText } from 'components/FormTemplate/Fields/InputText';
import { theme } from 'theme';
import { lg } from 'theme/styles/mediaQueries';
import { Button, IconButton } from 'components/Buttons';
import { Address } from '../../types/resources';

type UpdateAddressInput = {
  city: string;
  id: string;
  post_code: string;
  street: string;
  userId: string;
};

interface AddressModalProps {
  onClose: () => void;
  opened?: boolean;
  onSubmit: (data: UpdateAddressInput) => void;
  title: string;
  address: Address | null;
}

const Form = styled.form``;

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: ${theme.spacing.space32} ${theme.spacing.space16};

  ${lg(`
    grid-template-columns: 1fr 1fr; 
  `)}
`;

interface Field {
  type?: 'email' | 'text' | 'tel';
  name: 'city' | 'street' | 'post_code';
  regex?: RegExp;
  value?: string | null;
  validate?: (value?: string | null) => boolean | string;
}

interface FormInputProps {
  field: Field;
  register: UseFormRegister<UpdateAddressInput>;
}

const FormInput: FC<FormInputProps> = ({ field, register }) => {
  const { t } = useTranslation();

  const { name, regex, value, type, validate } = field;
  const pattern = regex
    ? {
        value: regex,
        message: t(`formality_letters.address.modal.${name}_error`),
      }
    : undefined;
  return (
    <InputText
      {...register(`${name}`, {
        required: t('error.form.required').toString(),
        pattern,
        validate,
      })}
      type={type}
      defaultValue={value as string | undefined}
      label={t(`formality_letters.address.modal.${name}`)}
      placeholder={t(`formality_letters.address.modal.${name}_placeholder`)}
      width="100%"
    />
  );
};

const AddressModal: FC<AddressModalProps> = ({
  onClose,
  opened,
  onSubmit,
  title,
  address,
}) => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm<UpdateAddressInput>();
  const rows: Field[][] = [
    [
      {
        name: 'street',
        value: address?.street,
      },
    ],
    [
      {
        name: 'post_code',
        regex: regexes.numbersOnly,
        value: address?.post_code,
      },
      {
        name: 'city',
        regex: regexes.lettersOnly,
        value: address?.city,
      },
    ],
  ];
  return (
    <Modal opened={opened} onClose={onClose} width="720px">
      <Flex marginBottom={{ xs: 'space32' }} alignItems="center">
        <IconButton
          iconName="User"
          color={theme.colors.salmon1}
          backgroundColor={theme.colors.salmon3}
          rounded
        />
        <Flex alignItems="center">
          <Text
            content={title}
            fontStyle={'heading5'}
            marginLeft={{ xs: 'space16' }}
            weight="bold"
          />
        </Flex>
        <Flex marginLeft={{ xs: 'auto' }} marginRight={{ xs: 'none' }}>
          <Button
            iconLeft={{ name: 'close' }}
            content={t('formality_letters.address.modal.close')}
            onClick={onClose}
          />
        </Flex>
      </Flex>
      <Flex marginBottom={{ xs: 'space32' }}>
        <Line />
      </Flex>
      <Form id="form" onSubmit={handleSubmit(onSubmit)}>
        {rows.map((fields, index) => {
          if (fields.length === 1) {
            const [field] = fields;
            return (
              <Flex key={field.name} marginBottom={{ xs: 'space16' }}>
                <FormInput field={field} register={register} />
              </Flex>
            );
          }
          return (
            <InputWrapper key={`wrapper_${index}`}>
              {fields.map((field) => {
                return (
                  <FormInput
                    key={field.name}
                    field={field}
                    register={register}
                  />
                );
              })}
            </InputWrapper>
          );
        })}
        <Flex marginTop={{ xs: 'space32' }}>
          <Button
            type="submit"
            content={t('formality_letters.address.modal.validate')}
            primary
          />
        </Flex>
      </Form>
    </Modal>
  );
};

export default AddressModal;
