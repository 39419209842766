import { Column } from '@tanstack/react-table';
import { ArrowDown, ArrowUp, ChevronsUpDown } from 'lucide-react';
import cx from 'classnames';
import React from 'react';

interface TableColumnHeaderProps<TData, TValue>
  extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>;
  title: string;
}

const TableColumnHeader = <TData, TValue>({
  column,
  title,
  className,
}: TableColumnHeaderProps<TData, TValue>) => {
  if (!column.getCanSort()) {
    return (
      <th className="tw-px-2 tw-py-8 tw-text-left tw-text-xs tw-font-bold tw-text-gray-700 tw-uppercase tw-tracking-wider">
        {title}
      </th>
    );
  }

  return (
    <th className={cx(className)}>
      <button
        type="button"
        className="tw-px-2 tw-py-8 tw-text-left tw-text-xs tw-font-bold tw-text-gray-700 tw-uppercase tw-tracking-wider tw-flex tw-flex-row tw-items-center "
        onClick={() => column.toggleSorting(undefined)}
      >
        <span>{title}</span>
        {column.getIsSorted() === 'desc' ? (
          <ArrowDown className="tw-h-4 tw-w-4" />
        ) : column.getIsSorted() === 'asc' ? (
          <ArrowUp className="tw-h-4 tw-w-4" />
        ) : (
          <ChevronsUpDown className="tw-h-4 tw-w-4" />
        )}
      </button>
    </th>
  );
};

export default TableColumnHeader;
