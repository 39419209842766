import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { theme } from 'theme';
import { ClientCard } from 'components/Cards';
import { Text } from 'components/Layout';
import { useNavigate } from 'react-router-dom';
import { ClientAppRoutes } from 'ClientApp';

// Utils

const UpsellClientCard: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate(ClientAppRoutes.MESSAGING, {
      state: {
        defaultMessage: t('opportunity.upsell.message') as string,
      },
    });
  }, []);

  const cardButton = [
    {
      content: t('opportunity.upsell.add'),
      primary: true,
      fullWidth: true,
      onClick: handleClick,
    },
  ];

  return (
    <ClientCard
      iconName="Calling"
      title={t('opportunity.upsell.title')}
      buttonsDirections={{ xs: 'column' }}
      buttons={cardButton}
    >
      <Text
        content={t('opportunity.upsell.description')}
        fontStyle="body2"
        color={theme.colors.gray6}
      />
    </ClientCard>
  );
};

export default UpsellClientCard;
