import { FC, useState } from 'react';
import { Flex, Text } from 'components/Layout';

import { Button } from 'components/Buttons';
import { InputText } from 'components/FormTemplate/Fields/InputText';
import styled from 'styled-components';
import { theme } from 'theme';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { regexes } from '../../../utils/regex';
import { useApi } from '../../../hooks/useApi';
import { md } from '../../../theme/styles/mediaQueries';
import toast from 'react-hot-toast';

const Form = styled.form`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: ${theme.spacing.space40} ${theme.spacing.space40};
  margin-bottom: ${theme.spacing.space40};

  ${md(`
    grid-template-columns: 1fr 1fr;
  `)}
`;

type PasswordInputType = 'password' | 'text';

type ChangePasswordFormData = {
  password: string;
  passwordConfirmation: string;
};

const ChangePasswordForm: FC = () => {
  const { t } = useTranslation();

  const [edited, setEdited] = useState<boolean>();
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<ChangePasswordFormData>();
  const [passwordType, setPasswordType] =
    useState<PasswordInputType>('password');
  const [passwordConfirmType, setPasswordConfirmType] =
    useState<PasswordInputType>('password');
  const { execute: changePasswordRequest, state: changePasswordState } = useApi(
    `/auth/user/password`,
    { method: 'PATCH' },
  );

  watch((value) => {
    if (value.password && value.passwordConfirmation) {
      setEdited(true);
    } else {
      setEdited(false);
    }
  });

  const onSubmit = (data: ChangePasswordFormData) => {
    if (data) {
      changePasswordRequest({
        body: { password: data.password },
        onSuccess: () => {
          toast.success(t('user.change_password.submit_success'));
          reset();
        },
        onError: () => {
          toast.error(t('user.change_password.submit_error'));
        },
      });
    }
  };

  return (
    <Flex direction={{ xs: 'column' }} flex={1}>
      <Flex marginBottom={{ xs: 'space24' }} alignItems="center">
        <Text fontStyle="heading4" content={t('user.change_password.title')} />
      </Flex>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputText
          {...register('password', {
            required: t('error.form.required').toString(),
            pattern: {
              value: regexes.password,
              message: t('error.form.password').toString(),
            },
          })}
          type={passwordType}
          error={errors.password}
          labelFontStyle="body1"
          labelWeight="medium"
          label={t('user.change_password.new_password')}
          placeholder={t('user.change_password.new_password')}
          width="100%"
          icon={{
            name: passwordType === 'password' ? 'Hide' : 'Show',
          }}
          onClick={() => {
            setPasswordType((prev) =>
              prev === 'password' ? 'text' : 'password',
            );
          }}
          required
        />
        <InputText
          {...register('passwordConfirmation', {
            required: t('error.form.required').toString(),
            validate: (val: string) => {
              if (watch('password') != val) {
                return t('error.form.password_no_match').toString();
              }
            },
          })}
          type={passwordConfirmType}
          error={errors.passwordConfirmation}
          labelFontStyle="body1"
          labelWeight="medium"
          label={t('user.change_password.confirm_password')}
          placeholder={t('user.change_password.confirm_password')}
          width="100%"
          icon={{
            name: passwordConfirmType === 'password' ? 'Hide' : 'Show',
          }}
          onClick={() => {
            setPasswordConfirmType((prev) =>
              prev === 'password' ? 'text' : 'password',
            );
          }}
          required
        />

        <Flex>
          <Button
            content={t('user.change_password.submit')}
            type="submit"
            disabled={!edited}
            $loading={changePasswordState.loading}
            primary
          />
        </Flex>
      </Form>
    </Flex>
  );
};

export default ChangePasswordForm;
