import { useState } from 'react';
import { format } from 'date-fns';
import config from '../../config/app';
import { getInitialName } from '../../utils/getter';
import Papa from 'papaparse';
import { useApiAsync } from 'hooks/useApi';
import { ProcedureFull } from 'types/resources';
import { useSearchParamsContext } from '../../context';
import { CasesFilters } from '../../pages/admin/Cases';
import { Loader2, Upload } from 'lucide-react';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

interface ExportCSVButtonProps<T> {
  columns: ColumnDef<T>[];
  visibleColumns: { [key: string]: boolean };
}

const ExportCSVButton = <T,>({
  columns,
  visibleColumns,
}: ExportCSVButtonProps<T>) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    filters: currentFilters,
    updateFilters,
    removeFilter,
    getFilter,
  } = useSearchParamsContext<CasesFilters>();

  const { execute: getCasesExport } = useApiAsync<ProcedureFull[]>(
    `/procedures`,
    {
      method: 'GET',
    },
  );

  const handleExport = async () => {
    try {
      setLoading(true);

      const exportData = await getCasesExport({
        query: {
          search: currentFilters.search || undefined,
          step_type: currentFilters.step_type,
          step_status: currentFilters.step_status,
          manager_id: currentFilters.manager_id || undefined,
          lawyer_id: currentFilters.lawyer_id || undefined,
          sort: currentFilters.sort,
          direction: currentFilters.direction,
          express: currentFilters.express,
          premium: currentFilters.premium,
          tp: currentFilters.tp,
          not_ext: currentFilters.not_ext,
          signed_lawyer_fee: currentFilters.signed_lawyer_fee,
          consents: currentFilters.consents,
          letters: currentFilters.letters,
          payment: currentFilters.payment,
          items: 10000,
        },
      });

      if (!exportData) {
        return;
      }

      // Get visible columns
      const visibleColumnDefs = columns.filter(
        (column) => visibleColumns[column.id as string],
      );

      // Prepare data for CSV export
      const csvData = exportData.map((row) => {
        const rowData: { [key: string]: any } = {};

        visibleColumnDefs.forEach((column) => {
          const columnId = column.id as string;

          // Handle special cases based on column rendering logic
          switch (columnId) {
            case 'spouse1':
              rowData[t('opportunity.columns.spouse_leader')] = row.spouse1
                ? `${row.spouse1.first_name?.[0]}. ${row.spouse1.last_name}`
                : '';
              break;

            case 'spouse2':
              rowData[t('opportunity.columns.spouse_follower')] = row.spouse2
                ? `${row.spouse2.first_name?.[0]}. ${row.spouse2.last_name}`
                : '';
              break;

            case 'step.type':
              rowData[t('opportunity.columns.step')] = t(
                `opportunity.steps.${row.step}`,
              );
              break;

            case 'step.status':
              rowData[t('opportunity.columns.step_status')] = t(
                `enums:${row.current_step.status}`,
              );
              break;

            case 'created_at':
              rowData[t('opportunity.columns.created_at')] = format(
                new Date(row.created_at),
                config.dateFormat,
              );
              break;

            case 'last_step_updated_at':
              rowData[t('opportunity.columns.updated_at')] = format(
                new Date(row.last_step_updated_at),
                'dd/MM/yyyy HH:mm',
              );
              break;

            case 'appointment_date':
              rowData[t('opportunity.columns.appointment_date')] =
                row.appointment_date
                  ? format(new Date(row.appointment_date), 'dd/MM/yyyy')
                  : '';
              break;

            case 'manager':
              rowData[t('opportunity.columns.manager')] = row.manager
                ? getInitialName(row.manager.full_name)
                : '';
              break;

            case 'express':
            case 'premium':
            case 'not_ext':
            case 'tp':
              rowData[t(`opportunity.columns.${columnId}`)] = row[columnId]
                ? 'Oui'
                : 'Non';
              break;

            case 'lawyer_fees_status':
            case 'payment_status':
            case 'consent_status':
            case 'letter_status':
              rowData[t(`opportunity.columns.${columnId}`)] = t(
                `opportunity.statuses.${row[columnId]}`,
              );
              break;

            default:
              // @ts-ignore
              rowData[t(`opportunity.columns.${columnId}`)] = row[columnId];
          }
        });

        return rowData;
      });
      const csv = Papa.unparse(csvData);

      // Create and download file
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);

      link.setAttribute('href', url);
      link.setAttribute(
        'download',
        `export_dossiers_${format(new Date(), 'dd/MM/yyyy_HH-mm')}.csv`,
      );
      link.style.visibility = 'hidden';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setLoading(false);
    } catch (e) {}
    setLoading(false);
  };

  return (
    <button
      disabled={loading}
      className="tw-relative tw-flex tw-items-center tw-gap-2 tw-p-3 tw-bg-[#28534F] tw-text-white tw-rounded-xl tw-text-sm disabled:tw-pointer-events-none disabled:tw-opacity-50"
      aria-label="Customise options"
      onClick={handleExport}
    >
      {loading ? (
        <Loader2 size={20} className="tw-animate-spin" />
      ) : (
        <Upload size={20} />
      )}
      Export
    </button>
  );
};

export default ExportCSVButton;
