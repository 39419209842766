import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Controller, useForm } from 'react-hook-form';
import { InputText } from 'components/FormTemplate/Fields/InputText';
import { AdminAppRoutes } from 'AdminApp';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from 'theme';
import { Flex } from 'components/Layout';
import { Button } from 'components/Buttons';
import { useApi } from '../../../hooks/useApi';
import {
  Customer,
  Department,
  Estimate,
  Option,
  Plan,
} from '../../../types/resources';
import InputCheckbox from '../../../components/FormTemplate/Fields/InputCheckbox';
import InputSelect from '../../../components/FormTemplate/Fields/InputSelect';
import { InputCurrency } from '../../../components/FormTemplate/Fields/InputCurrency';
import { toString } from 'lodash';
import InputSelectAsync from '../../../components/FormTemplate/Fields/InputSelectAsync';

const FullWidth = styled.div`
  grid-column: 1/-1;
`;

export type CreateEstimateInput = {
  customer1_id: string;
  customer2_id: string;
  name?: string;
  split_payments: boolean;
  has_real_estate: boolean;
  adult_children: number;
  minor_children: number;
  department_code: Department['code'];
  plan_id: Plan['id'];
  options_ids: Option['id'][];
};

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 38px 32px;
  width: 928px;
  margin-top: ${theme.spacing.space8};
`;

type EstimateCreateFormProps = {
  departments: Department[];
  plans: Plan[];
  options: Option[];
  onSubmit: (data: CreateEstimateInput) => void;
  loading?: boolean;
};

const EstimateCreateForm: FC<EstimateCreateFormProps> = ({
  departments,
  plans,
  options,
  onSubmit,
  loading,
}) => {
  const {
    control,
    setValue,
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm<CreateEstimateInput>({
    defaultValues: {
      options_ids: [],
      split_payments: false,
      has_real_estate: false,
      adult_children: 0,
      minor_children: 0,
    },
  });
  const [price, setPrice] = useState<number>(0);
  const departmentOptions = departments.map((d) => ({
    value: d.code,
    label: d.full_name,
  }));
  const planOptions = plans.map((p) => ({
    value: p.id,
    label: p.name,
  }));
  const optionOptions = options.map((o) => ({
    value: o.id,
    label: o.name,
  }));
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { execute: estimatePriceRequest } = useApi<Pick<Estimate, 'price'>>(
    `/estimates/price`,
    {
      method: 'POST',
      onSuccess: (result) => {
        setPrice(result.value.price);
      },
    },
  );

  const { execute: getCustomers } = useApi<Customer[]>(
    '/customers?sort=full_name&direction=asc',
  );

  const handleCustomerSearch = (
    val: string,
    callback: (options: any[]) => void,
  ) => {
    const query: Record<string, string> = {};
    if (val) {
      query.search = val;
    }

    getCustomers({
      query,
      onSuccess: (result) => {
        callback(
          result.value.map((c) => ({
            value: c.id,
            label: c.full_name,
          })),
        );
      },
    });
  };

  const goBack = () => {
    navigate(AdminAppRoutes.ESTIMATE);
  };

  useEffect(() => {
    watch((value) => {
      if (value.department_code && value.plan_id) {
        estimatePriceRequest({
          body: {
            split_payments: value.split_payments,
            has_real_estate: value.has_real_estate,
            adult_children: value.adult_children,
            minor_children: value.minor_children,
            department_code: value.department_code,
            plan_id: value.plan_id,
            options_ids: value.options_ids,
          },
        });
      }
    });
  }, [watch]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="customer1_id"
        rules={{ required: t('error.form.required').toString() }}
        render={({ field, fieldState: { error } }) => (
          <InputSelectAsync
            label={t('estimate.customer1')}
            placeholder={t('estimate.customer1')}
            loadOptions={handleCustomerSearch}
            defaultOptions
            error={error}
            {...field}
            onChange={(e) => {
              field.onChange(e);
            }}
            required
          />
        )}
      />
      <Controller
        control={control}
        name="customer2_id"
        rules={{ required: t('error.form.required').toString() }}
        render={({ field, fieldState: { error } }) => (
          <InputSelectAsync
            label={t('estimate.customer2')}
            placeholder={t('estimate.customer2')}
            loadOptions={handleCustomerSearch}
            defaultOptions
            error={error}
            {...field}
            onChange={(e) => {
              field.onChange(e);
            }}
            required
          />
        )}
      />
      <Controller
        control={control}
        name="plan_id"
        rules={{ required: t('error.form.required').toString() }}
        render={({ field, fieldState: { error } }) => (
          <InputSelect
            label={t('estimate.plan_id')}
            placeholder={t('estimate.plan_id')}
            options={planOptions}
            error={error}
            {...field}
            onChange={(e) => {
              field.onChange(e);
            }}
            required
          />
        )}
      />
      <Controller
        control={control}
        name="options_ids"
        render={({ field, fieldState: { error } }) => (
          <InputSelect
            label={t('estimate.options_ids')}
            placeholder={t('estimate.options_ids')}
            options={optionOptions}
            error={error}
            {...field}
            onChange={(e) => {
              field.onChange(e);
            }}
            required
            isMulti
          />
        )}
      />
      <FullWidth>
        <Flex width={'50%'}>
          <Controller
            control={control}
            name="department_code"
            rules={{ required: t('error.form.required').toString() }}
            render={({ field, fieldState: { error } }) => (
              <InputSelect
                label={t('estimate.department_code')}
                placeholder={t('estimate.department_code')}
                options={departmentOptions}
                error={error}
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                }}
                required
              />
            )}
          />
        </Flex>
      </FullWidth>
      <InputText
        {...register('adult_children', {
          required: t('error.form.required').toString(),
        })}
        type={'number'}
        error={errors.name}
        label={t('estimate.adult_children')}
        placeholder={t('estimate.adult_children')}
        width="100%"
        min="0"
        max="20"
      />
      <InputText
        {...register('minor_children', {
          required: t('error.form.required').toString(),
        })}
        type={'number'}
        error={errors.name}
        label={t('estimate.minor_children')}
        placeholder={t('estimate.minor_children')}
        width="100%"
        min={0}
      />
      <Controller
        control={control}
        name="has_real_estate"
        rules={{
          validate: (value) => {
            if (value === undefined) {
              return t('error.form.checkbox').toString();
            }
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <InputCheckbox
            {...field}
            checked={!!field.value}
            onChange={(value) => {
              setValue(`has_real_estate`, !!value);
            }}
            fontWeight="bold"
            align="center"
            checkPoint
            error={!!error}
            label={t(`estimate.has_real_estate`)}
          />
        )}
      />
      <Controller
        control={control}
        name="split_payments"
        rules={{
          validate: (value) => {
            if (value === undefined) {
              return t('error.form.checkbox').toString();
            }
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <InputCheckbox
            {...field}
            checked={!!field.value}
            onChange={(value) => {
              setValue(`split_payments`, !!value);
            }}
            fontWeight="bold"
            align="center"
            checkPoint
            error={!!error}
            label={t(`estimate.split_payments`)}
          />
        )}
      />

      <InputCurrency
        value={toString(price)}
        onChange={() => {}}
        label={t('estimate.price')}
        placeholder={t('estimate.price')}
        width="100%"
        disabled
      />

      <FullWidth>
        <Flex>
          <Button
            onClick={goBack}
            content={t('back')}
            marginRight={{ sm: 'space24' }}
          />
          <Button
            content={t('estimate.submit_create')}
            type="submit"
            $loading={loading}
            primary
          />
        </Flex>
      </FullWidth>
    </Form>
  );
};

export default EstimateCreateForm;
