import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ListItem } from 'components/List';
import { Flex } from 'components/Layout';
import { AdministrativeDocumentList } from 'components/AdministrativeDocuments';
import { useDownloadOpportunity } from 'utils/downloadFile';
import {
  DocumentFull,
  ProcedureFull,
  ProcedureSpouseFull,
} from '../../../../types/resources';
import { useApi } from '../../../../hooks/useApi';
import CreateAndUploadModal from '../../../../components/Modal/CreateAndUploadDocumentModal';
import { ButtonProps } from '../../../../components/Buttons/Button';
import toast from 'react-hot-toast';

interface AdministrativeDocumentsProps {
  procedure: ProcedureFull;
  spouses: ProcedureSpouseFull[];
}

const AdministrativeDocuments: FC<AdministrativeDocumentsProps> = ({
  procedure,
  spouses,
}) => {
  const { t } = useTranslation();
  const { execute: getDocuments, state: getDocumentsState } = useApi<
    DocumentFull[]
  >(`/procedures/${procedure.id}/documents?is_administrative=false`);
  const documents = getDocumentsState.data?.value ?? [];
  const spousesUsers = spouses.map((s) => s.spouse);

  useEffect(() => {
    getDocuments();
  }, [procedure]);

  const { onDownload, loading } = useDownloadOpportunity();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleDownloadFolder = async () => {
    try {
      await onDownload({
        opportunityId: procedure.id,
        filename: `${procedure.reference}-${procedure.name.toLowerCase()}`,
      });
    } catch (e) {
      toast.error(t('errors.unknown'));
    }
  };

  const buttons: ButtonProps[] = [
    {
      content: t('administrative_documents.buttons.upload_document'),
      primary: false,
      onClick: () => setIsModalOpen(true),
    },
    {
      content: t('administrative_documents.buttons.download_full'),
      primary: true,
      onClick: handleDownloadFolder,
      $loading: loading,
    },
  ];

  return (
    <>
      <Flex width="100%" marginBottom={{ xs: 'space40' }}>
        <ListItem
          title={t('administrative_documents.folder')}
          iconName="Folder"
          buttons={buttons}
        />
      </Flex>

      {documents.length > 0 && (
        <AdministrativeDocumentList
          title={t('administrative_documents.documents')}
          count={t('administrative_documents.count', {
            count: documents.length,
          })}
          procedure={procedure}
          data={documents}
          refetch={() => getDocuments()}
        />
      )}

      {isModalOpen && (
        <CreateAndUploadModal
          procedure={procedure}
          opened={isModalOpen}
          isAdministrative={false}
          spouses={spousesUsers}
          onClose={() => {
            setIsModalOpen(false);
            getDocuments();
          }}
        />
      )}
    </>
  );
};

export default AdministrativeDocuments;
