import { FC, useState } from 'react';

import { Col, Row } from 'components/Layout';
import { ProductCode } from 'types/resources';
import { RecapCard } from 'components/Cards/RecapCard';
import {
  CalendlyModal,
  OptionModal,
  PlanModal,
  QuantityModal,
  QuestionModal,
} from 'components/Modal';
import { Ghost } from 'components/Loading';
import { useTranslation } from 'react-i18next';
import { ChildrenElement } from 'components/Modal/QuantityModal';
import { useViewport } from 'hooks/useViewport';
import ZendeskProvider from '../Zendesk/ZendeskProvider';
import {
  EstimateFull,
  EstimateProduct,
  EstimateStatus,
  EstimateUpdate,
  Option,
  Plan,
  Product,
} from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';
import { env } from '../../../config/env';

enum Modals {
  NoModal = '',
  ProductModal = 'product',
  OptionModal = 'option',
  ChildrenModal = 'children',
  PropertiesModal = 'properties',
}

interface RecapSituationQuoteProps {
  data?: EstimateFull;
  plans: Plan[];
  options: Option[];
  products: Product[];
  loading: boolean;
  refetch: () => void;
}

const RecapSituationQuote: FC<RecapSituationQuoteProps> = ({
  data,
  plans,
  options,
  products,
  loading,
  refetch,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const {
    execute: updateEstimate,
    state: { loading: updateLoading },
  } = useApi(`/estimates/${data?.id}`, { method: 'PATCH' });
  const [openedModal, setOpenedModal] = useState(Modals.NoModal);
  const [calendlyModalOpen, setCalendlyModalOpen] = useState(false);
  const estimatePlan = data
    ? plans.find((p) => p.id === data.plan_id)
    : undefined;
  const estimateOptions = data
    ? options.filter((o) => data.options_ids.includes(o.id))
    : undefined;

  if (!data || !estimatePlan || !estimateOptions || loading)
    return (
      <Row marginBottom={{ xs: 'space32' }}>
        <Col xs={12}>
          <Ghost width="100%" height={624} shape="rect" rx={24} />
        </Col>
      </Row>
    );

  const { status, id } = data;
  const productsObject =
    data.products?.reduce<
      Record<ProductCode, EstimateProduct & { product: Product }>
    >((acc, val) => {
      const product = products.find((p) => p.id === val.product_id);
      if (!product) {
        return acc;
      } else {
        return { ...acc, [product.code]: { ...val, product } };
      }
    }, {} as Record<ProductCode, EstimateProduct & { product: Product }>) ?? [];
  const idfProduct = productsObject[ProductCode.IleDeFrance];
  const provProduct = productsObject[ProductCode.Province];
  const registerProduct = productsObject[ProductCode.FraisPostauxObligatoires];
  const childrenProduct = productsObject[ProductCode.EnfantsMineurs];
  const lawyerProduct = productsObject[ProductCode.AvocatsIndependants];
  const propertyProduct = productsObject[ProductCode.BienImmobilier];
  const managerProduct = productsObject[ProductCode.GestionnaireDedie];

  const editable = status !== EstimateStatus.WON;

  const closeModal = () => {
    setOpenedModal(Modals.NoModal);
  };
  const formattedPlan = {
    productName: t('quote.resume.plan_name', { name: estimatePlan.name }),
    mobileName: t('quote.resume.plan_name', { name: estimatePlan.name }),
    productDescription: estimatePlan.description,
    productPrice:
      estimatePlan.price +
      (idfProduct?.product?.price ?? 0) +
      (provProduct?.product?.price ?? 0),
    productId: estimatePlan.id,
    included: true,
    onClick: () => {
      setOpenedModal(Modals.ProductModal);
    },
    editable,
  };
  const getOptionDescription = (options?: Option[]) => {
    if (!options) return t('quote.resume.no_option');
    if (options.length === 0) return t('quote.resume.default_option');
    if (options.length === 1) return options[0].description;
    if (options.length > 1) return t('quote.resume.all_options_selected');
  };
  const getOptionMobileName = (options?: Option[]) => {
    if (!options) return t('quote.resume.option_mobile_zero');
    if (options.length === 0) return t('quote.resume.option_mobile_zero');
    if (options.length === 1) return t('quote.resume.option_mobile_one');
    return t('quote.resume.option_mobile_multiple');
  };
  const formattedOption = {
    productName:
      estimateOptions && estimateOptions.length > 0
        ? estimateOptions?.map((option) => option.name).join(' et ')
        : t('quote.resume.no_option'),
    mobileName: getOptionMobileName(estimateOptions),
    productDescription: getOptionDescription(estimateOptions),
    productPrice: estimateOptions
      ?.map((option) => option.price)
      .reduce((prev, curr) => prev + curr, 0),
    productId: '100545',
    included: false,
    onClick: () => {
      setOpenedModal(Modals.OptionModal);
    },
    editable,
  };
  const nbYoungChildren = data.minor_children;
  const otherProducts = [registerProduct, lawyerProduct, managerProduct].map(
    (product) => {
      return {
        productName: product?.product?.name || '',
        mobileName: product?.product?.short_name || '',
        productDescription: product?.product?.description || '',
        productPrice: product?.product?.price || 0,
        productId: product?.product?.id,
        editable,
        included: true,
      };
    },
  );

  const formattedProducts = [
    {
      productName: nbYoungChildren
        ? t('quote.resume.children_option', {
            number: nbYoungChildren,
            count: nbYoungChildren,
          })
        : t('quote.resume.no_children'),
      mobileName: t('quote.resume.children_mobile'),
      productDescription:
        childrenProduct?.product?.description ||
        t('quote.resume.default_children'),
      productPrice:
        childrenProduct?.product?.price * childrenProduct?.quantity || 0,
      productId: childrenProduct?.product?.id,
      included: true,
      onClick: () => {
        setOpenedModal(Modals.ChildrenModal);
      },
      editable,
    },
    {
      productName: data.has_real_estate
        ? t('quote.resume.properties_option')
        : t('quote.resume.no_property'),
      mobileName: t('quote.resume.properties_mobile'),
      productDescription:
        propertyProduct?.product?.description ||
        t('quote.resume.default_property'),
      productPrice: propertyProduct?.product?.price || 0,
      productId: propertyProduct?.product?.id,
      included: true,
      onClick: () => {
        setOpenedModal(Modals.PropertiesModal);
      },
      editable,
    },
  ];
  const displayedProducts = [
    formattedPlan,
    ...formattedProducts,
    ...otherProducts,
    formattedOption,
  ];

  const childrenElements: ChildrenElement = {
    nbYoungChildren: {
      content: t('quote.resume.children.children_title'),
      key: 'nbYoungChildren',
      value: data.minor_children,
    },
    nbAdultChildren: {
      content: t('quote.resume.children.adult_title'),
      key: 'nbAdultChildren',
      value: data.adult_children,
    },
  };

  const bonusCard = {
    buttonName: t('quote.resume.need_talk'),
    onClick: () => {
      setCalendlyModalOpen(true);
    },
  };
  const onSubmit = async (
    submitData: Partial<EstimateUpdate>,
    callback: () => void,
  ) => {
    updateEstimate({
      body: submitData,
      onSuccess: () => {
        callback();
        refetch();
      },
    });
  };
  const commonModalProperties = {
    loading: updateLoading,
    onSubmit,
    deal: data,
    onClose: () => closeModal(),
  };

  return (
    <ZendeskProvider dealId={id}>
      <Row marginBottom={{ xs: 'space32' }}>
        <Col xs={12}>
          <RecapCard
            title={t('quote.resume.summary_of_your_situation')}
            buttonName={t('edit')}
            products={displayedProducts}
            bonusCard={bonusCard}
          />
        </Col>
      </Row>
      <CalendlyModal
        opened={calendlyModalOpen}
        onClose={() => setCalendlyModalOpen(false)}
        url={`${env.calendly.url}/15min`}
      />
      <PlanModal
        {...commonModalProperties}
        plans={plans}
        selected={estimatePlan}
        opened={openedModal === Modals.ProductModal}
        isMobile={isMobile}
      />
      <OptionModal
        {...commonModalProperties}
        options={options}
        selected={estimateOptions}
        opened={openedModal === Modals.OptionModal}
      />
      <QuantityModal
        {...commonModalProperties}
        elements={childrenElements}
        title={t('quote.resume.children.title')}
        opened={openedModal === Modals.ChildrenModal}
      />
      <QuestionModal
        {...commonModalProperties}
        question={t('quote.resume.properties.question')}
        title={t('quote.resume.properties.title')}
        value={data.has_real_estate}
        field="has_real_estate"
        opened={openedModal === Modals.PropertiesModal}
        enumOptions={[
          { label: 'Oui', value: true },
          { label: 'Non', value: false },
        ]}
      />
    </ZendeskProvider>
  );
};

export default RecapSituationQuote;
