import { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Controller, useForm } from 'react-hook-form';
import { InputText } from 'components/FormTemplate/Fields/InputText';
import styled from 'styled-components';
import { theme } from 'theme';
import { Flex, Text } from 'components/Layout';
import { Button } from 'components/Buttons';
import { useApi } from '../../../hooks/useApi';
import { regexes } from '../../../utils/regex';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { InputTel } from '../../../components/FormTemplate/Fields/InputTel';
import { Prospect } from '../../../types/resources';
import toast from 'react-hot-toast';

const FullWidth = styled.div`
  grid-column: 1/-1;
`;

type UpdateProspectInput = {
  email?: string;
  phone?: string;
};

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 38px 32px;
  width: 928px;
  margin-top: ${theme.spacing.space8};
`;

type ProspectUpdateFormProps = {
  prospect: Prospect;
};

const ProspectUpdateForm: FC<ProspectUpdateFormProps> = ({ prospect }) => {
  const {
    control,
    setValue,
    register,
    clearErrors,
    formState: { errors },
    handleSubmit,
  } = useForm<UpdateProspectInput>({
    defaultValues: { ...prospect, phone: prospect.phone ?? undefined },
  });
  const { t } = useTranslation();

  const {
    execute: updateProspect,
    state: { loading },
  } = useApi(`/prospects/${prospect.id}`, { method: 'PATCH' });

  const onSubmit = async (data: UpdateProspectInput) => {
    if (data) {
      updateProspect({
        body: {
          email: data.email,
          phone: data.phone,
        },
        onSuccess: () => {
          toast.success(t('prospect.details.submit_success'));
        },
        onError: () => {
          toast.error(t('prospect.details.submit_error'));
        },
      });
    }
  };

  return (
    <>
      <Flex marginTop={{ xs: 'space48' }}>
        <Text content={t('prospect.information')} fontStyle="heading3" />
      </Flex>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputText
          {...register('email', {
            required: t('error.form.required').toString(),
            pattern: {
              value: regexes.email,
              message: t('error.form.email'),
            },
          })}
          error={errors.email}
          label={t('prospect.email')}
          placeholder={t('prospect.email')}
          width="100%"
        />
        <Controller
          control={control}
          name="phone"
          rules={{
            validate: (value: string | undefined) => {
              if (value && !isValidPhoneNumber(value)) {
                return t('error.form.phone').toString();
              }
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <InputTel
              label={t('prospect.phone')}
              placeholder={t('prospect.phone')}
              error={error}
              {...field}
              onChange={(value) => {
                setValue(`phone`, value);
                clearErrors('phone');
              }}
            />
          )}
        />
        <FullWidth>
          <Flex>
            <Button
              content={t('prospect.details.submit_update')}
              type="submit"
              $loading={loading}
              primary
            />
          </Flex>
        </FullWidth>
      </Form>
    </>
  );
};

export default ProspectUpdateForm;
