import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Role, User } from '../types/resources';
import { useApi } from './useApi';
import { AuthContext } from '../context';

type AuthCallbacks<T = void> = {
  onSuccess?: (value: T) => void;
  onError?: (err: unknown) => void;
};

type LoginProps = AuthCallbacks<User> & {
  email: string;
  password: string;
};

type LogoutProps = AuthCallbacks;

type ActivateCheckProps = AuthCallbacks & {
  token: string;
};

type ActivateProps = AuthCallbacks<User> & {
  password: string;
  token: string;
};

type PasswordResetProps = AuthCallbacks & {
  email: string;
};

type PasswordResetCheckProps = AuthCallbacks & {
  token: string;
};

type PasswordResetConfirmProps = AuthCallbacks<User> & {
  password: string;
  token: string;
};

export function useAuth() {
  const navigate = useNavigate();
  const { user, setUser } = useContext(AuthContext);

  const { execute: loginRequest, state: loginState } = useApi('/auth/login', {
    method: 'POST',
    onError: () => {},
  });

  const { execute: logoutRequest, state: logoutState } = useApi(
    '/auth/logout',
    {
      method: 'POST',
    },
  );

  const { execute: meRequest, state: meState } = useApi<User>('/auth/user', {
    method: 'GET',
  });

  const { execute: activateCheckRequest } = useApi('/auth/activate');

  const { execute: activateRequest, state: activateState } = useApi(
    '/auth/activate',
    {
      method: 'POST',
    },
  );

  const { execute: passwordResetRequest } = useApi('/auth/password-reset', {
    method: 'POST',
  });

  const { execute: passwordResetCheckRequest } = useApi('/auth/password-reset');

  const {
    execute: passwordResetConfirmRequest,
    state: passwordResetConfirmState,
  } = useApi('/auth/password-confirm', {
    method: 'POST',
  });

  const resetCurrentUser = () => {
    setUser(null);
    localStorage.setItem('user', JSON.stringify(null));
  };

  const setCurrentUser = (value: User) => {
    setUser(value);
    localStorage.setItem('user', JSON.stringify(value));
  };

  const authenticateUser = ({ onSuccess, onError }: AuthCallbacks<User>) => {
    meRequest({
      onError: (err) => {
        resetCurrentUser();
        onError?.(err);
      },
      onSuccess: ({ value }) => {
        setCurrentUser(value);
        onSuccess?.(value);
      },
    });
  };

  const login = ({ email, password, onSuccess, onError }: LoginProps) => {
    loginRequest({
      body: { email, password },
      onError: (err) => {
        resetCurrentUser();
        onError?.(err);
      },
      onSuccess: () => {
        authenticateUser({ onSuccess, onError });
      },
    });
  };

  const logout = (props?: LogoutProps) => {
    logoutRequest({
      onSuccess: () => {
        resetCurrentUser();
        props?.onSuccess?.();
        navigate({ pathname: '/connexion' });
      },
      onError: (err) => {
        props?.onError?.(err);
      },
    });
  };

  const activateCheck = ({ token, onSuccess, onError }: ActivateCheckProps) => {
    activateCheckRequest({
      query: { hash: token },
      onSuccess: () => onSuccess?.(),
      onError,
    });
  };

  const activate = ({ token, password, onSuccess, onError }: ActivateProps) => {
    activateRequest({
      body: { hash: token, password },
      onSuccess: () => {
        authenticateUser({ onSuccess, onError });
      },
    });
  };

  const passwordReset = ({ email, onSuccess, onError }: PasswordResetProps) => {
    passwordResetRequest({
      body: { email },
      onSuccess: () => onSuccess?.(),
      onError,
    });
  };

  const passwordResetCheck = ({
    token,
    onSuccess,
    onError,
  }: PasswordResetCheckProps) => {
    passwordResetCheckRequest({
      query: { hash: token },
      onSuccess: () => onSuccess?.(),
      onError,
    });
  };

  const passwordResetConfirm = ({
    password,
    token,
    onSuccess,
    onError,
  }: PasswordResetConfirmProps) => {
    passwordResetConfirmRequest({
      body: { password, hash: token },
      onSuccess: () => {
        authenticateUser({ onSuccess, onError });
      },
    });
  };

  return {
    isLoading:
      loginState.loading ||
      logoutState.loading ||
      activateState.loading ||
      passwordResetConfirmState.loading ||
      meState.loading,
    isCustomer: user?.role === Role.CUSTOMER,
    user,
    login,
    logout,
    activateCheck,
    activate,
    passwordReset,
    passwordResetCheck,
    passwordResetConfirm,
    authenticateUser,
  };
}
