import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

// Component
import { Flex, Text } from 'components/Layout';
import { ConfirmForm } from 'components/ConfirmPopin/ConfirmPopin';
import { Modal } from 'components/Modal';
import { InputText } from 'components/FormTemplate/Fields/InputText';
import { Button } from 'components/Buttons';
import { InputTel } from 'components/FormTemplate/Fields/InputTel';

//data
import { Breadcrumbs } from 'container/admin';
// Utils
import { AdminAppRoutes } from 'AdminApp';
import { theme } from 'theme';
import { regexes } from 'utils/regex';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useApi } from '../../hooks/useApi';

type CreateProspectInput = {
  email: string;
  phone?: string;
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 38px 32px;
  width: 928px;
  margin-top: ${theme.spacing.space48};
  margin-bottom: ${theme.spacing.space32};
`;

const ProspectsAdd: FC = () => {
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    clearErrors,
  } = useForm<CreateProspectInput>();
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [result, setResult] = useState<CreateProspectInput>();
  const navigate = useNavigate();

  const onSubmit = (data: CreateProspectInput) => {
    setResult(data);
    setModalIsOpen(true);
  };

  const onClose = () => {
    setModalIsOpen(false);
  };

  const {
    execute: createProspectRequest,
    state: { loading, success },
  } = useApi('/prospects', { method: 'POST' });

  const goBack = () => {
    navigate(AdminAppRoutes.PROSPECTS);
  };

  const createProspect = async () => {
    if (result) {
      createProspectRequest({ body: result });
    }
  };

  useEffect(() => {
    if (success) {
      goBack();
    }
  }, [success]);

  return (
    <Container>
      <Modal opened={modalIsOpen} onClose={onClose}>
        <ConfirmForm
          handleSubmit={createProspect}
          setModalIsOpen={setModalIsOpen}
          loading={loading}
        />
      </Modal>
      <Breadcrumbs />
      <Flex direction={{ xs: 'row' }} alignItems="center">
        <Text
          content={t('prospect.add')}
          fontStyle="heading2"
          weight="bold"
          marginRight={{ xs: 'space32' }}
        />
      </Flex>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputText
          {...register('email', {
            required: t('error.form.required').toString(),
            pattern: {
              value: regexes.email,
              message: t('error.form.email'),
            },
          })}
          error={errors.email}
          label={t('prospect.email')}
          placeholder={t('prospect.email')}
          width="100%"
          required
        />

        <Controller
          control={control}
          name="phone"
          rules={{
            validate: (value?: string) => {
              if (value && !isValidPhoneNumber(value)) {
                return t('error.form.phone').toString();
              }
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <InputTel
              label={t('prospect.phone')}
              placeholder={t('prospect.phone')}
              error={error}
              {...field}
              onChange={(value) => {
                setValue(`phone`, value);
                clearErrors('phone');
              }}
            />
          )}
        />
        <Flex>
          <Button
            onClick={goBack}
            content={t('back')}
            marginRight={{ sm: 'space24' }}
          />
          <Button
            content={t('prospect.add')}
            type="submit"
            $loading={loading}
            primary
          />
        </Flex>
      </Form>
    </Container>
  );
};

export default ProspectsAdd;
