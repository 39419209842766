import { FC, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { ConventionValidation } from 'container/client';
import { ConventionPreview, ViewGate } from 'container/common';

import { theme } from 'theme';

import { Col, Container, Row } from 'components/Layout';
import { useCustomer } from '../../hooks/useCustomer';
import {
  DivorceAgreementFull,
  ProcedureFull,
  ProcedureStepType,
} from '../../types/resources';
import { useApi } from '../../hooks/useApi';

const CustomContainer = styled(Container)`
  padding-top: ${theme.spacing.space32};
  padding-bottom: ${theme.spacing.space32};
`;

const ClientConvention: FC = () => {
  const { customer } = useCustomer();
  const { execute: getProcedure, state: getProcedureState } =
    useApi<ProcedureFull>('');
  const procedure = getProcedureState.data?.value;

  const { execute: getDivorceAgreement, state: getDivorceAgreementState } =
    useApi<DivorceAgreementFull>(
      `/procedures/${procedure?.id}/divorce-agreements/${procedure?.divorce_agreement_id}`,
    );
  const divorceAgreement = getDivorceAgreementState.data?.value;

  const getProcedureCb = useCallback(() => {
    if (customer?.procedure_id) {
      getProcedure({
        endpoint: `/procedures/${customer.procedure_id}`,
        query: { customer_id: customer.id },
      });
    }
  }, [customer?.procedure_id]);

  useEffect(() => {
    if (customer?.procedure_id) {
      getProcedureCb();
    }
  }, [customer?.procedure_id]);

  useEffect(() => {
    if (procedure?.divorce_agreement_id) {
      getDivorceAgreement();
    }
  }, [procedure]);

  if (!procedure || !divorceAgreement) return null;

  const lawyer =
    customer?.id === procedure.spouse1_id
      ? procedure.lawyer1
      : customer?.id === procedure.spouse2_id
      ? procedure.lawyer2
      : null;

  return (
    <ViewGate
      procedure={procedure}
      steps={[ProcedureStepType.REVIEW_AGREEMENT]}
    >
      <CustomContainer fluid="xl">
        <Row>
          <Col xs={12} md={4} vertical alignSelf="auto">
            <ConventionValidation
              procedure={procedure}
              agreement={divorceAgreement}
              refetchProcedure={getProcedureCb}
              refetchAgreement={getDivorceAgreement}
            />
          </Col>
          <Col xs={12} md={8}>
            <ConventionPreview
              procedure={procedure}
              agreement={divorceAgreement}
              lawyer={lawyer}
            />
          </Col>
        </Row>
      </CustomContainer>
    </ViewGate>
  );
};

export default ClientConvention;
