import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Hooks
import { useModal } from 'hooks/useModal';
import { useViewport } from 'hooks/useViewport';

// Components
import { Modal } from 'components/Modal';
import { Col, DesktopOnly, Flex, Requires, Row, Text } from 'components/Layout';
import { Permission } from 'components/User';
import { Button } from 'components/Buttons';

// Type
// Container
import {
  AssignOpportunitiesList,
  AssignSingleModal,
  ManagerDashboard,
  OpportunitiesCountStatus,
} from 'container/admin';
import { AssignModalProps } from 'container/admin/AssignModal/AssignModal';
import { AdminAppRoutes } from 'AdminApp';
import WithInvalidMessageOpportunities from 'container/admin/lists/WithInvalidMessageOpportunities/WithInvalidMessageOpportunities';
import { useAuth } from '../../hooks/useAuth';
import { ProcedureFull, Role } from '../../types/resources';
import { useApi } from '../../hooks/useApi';
import WithErrorOpportunities from 'container/admin/lists/WithErrorOpportunities';

const AdminHome: FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const [isAssignModalOpen, setIsAssignModalOpen] = useModal(false);
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [assignModalContent, setAssignModalContent] = useState<
    AssignModalProps['content']
  >({
    title: '',
    type: Role.MANAGER,
    opportunityIds: [],
    role: Role.LAWYER,
    assignedRoleId: '',
    spouseId: undefined,
  });
  const { user, logout } = useAuth();
  const handleCloseModal = () => {
    setIsAssignModalOpen(false);
  };

  const { execute: getProceduresToAssign, state: getProceduresToAssignState } =
    useApi<ProcedureFull[]>(`/procedures`);

  const getProceduresToAssignCb = useCallback(() => {
    getProceduresToAssign({
      query: {
        page: 1,
        items: 10,
        assigned: false,
        sort: 'reference',
        direction: 'asc',
        search,
      },
    });
  }, [search]);

  useEffect(() => {
    if (user && [Role.ADMIN, Role.MANAGER].includes(user.role)) {
      getProceduresToAssignCb();
    }
  }, [search, user]);

  const redirect =
    user?.role === Role.SALES ? AdminAppRoutes.ESTIMATE : AdminAppRoutes.CASES;

  return (
    <Permission roles={[Role.ADMIN, Role.MANAGER]} redirect={redirect}>
      <Row marginBottom={{ xs: 'space32', md: 'space48' }}>
        <Col xs={12} justify="between">
          <Text
            content={t('hello_name', { name: user?.first_name })}
            fontStyle={isMobile ? 'heading3' : 'heading2'}
          />
        </Col>
      </Row>
      <Permission roles={[Role.ADMIN]}>
        <DesktopOnly>
          <OpportunitiesCountStatus />
        </DesktopOnly>
      </Permission>
      <Permission roles={[Role.ADMIN]}>
        <Row marginBottom={{ xs: 'space32' }}>
          <Col xs={12} vertical>
            <AssignOpportunitiesList
              proceduresToAssign={getProceduresToAssignState.data?.value ?? []}
              proceduresToAssignTotal={
                getProceduresToAssignState.data?.pagination?.total ?? 0
              }
              proceduresToAssignLoading={getProceduresToAssignState.loading}
              setIsAssignModalOpen={setIsAssignModalOpen}
              setModalContent={setAssignModalContent}
              onSearchChanged={setSearch}
              isModalOpen={isAssignModalOpen}
            />
          </Col>
          <Col xs={12} marginTop={{ xs: 'space24' }}>
            <Flex expand style={{ userSelect: 'none' }}>
              <WithInvalidMessageOpportunities />
            </Flex>
          </Col>
          <Col
            xs={12}
            marginTop={{ xs: 'space24' }}
            marginBottom={{ xs: 'space48' }}
          >
            <Flex expand style={{ userSelect: 'none' }}>
              <WithErrorOpportunities />
            </Flex>
          </Col>
        </Row>
        <Requires value={assignModalContent.title}>
          <Modal
            opened={isAssignModalOpen}
            width="83vw"
            full={isMobile}
            onClose={handleCloseModal}
          >
            <AssignSingleModal
              content={assignModalContent}
              onClose={handleCloseModal}
              refetchProceduresToAssign={getProceduresToAssignCb}
            />
          </Modal>
        </Requires>
      </Permission>
      <Permission roles={[Role.MANAGER]}>
        <ManagerDashboard />
      </Permission>
      <Row>
        <Col xs={12} mobileOnly>
          <Flex expand>
            <Button
              content={t('sign_out')}
              iconLeft={{ name: 'Logout' }}
              fullWidth
              onClick={() => logout()}
            />
          </Flex>
        </Col>
      </Row>
    </Permission>
  );
};

export default AdminHome;
