import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Partner } from '../../types/resources';
import { useApi } from '../../hooks/useApi';
import { Text } from '../Layout';
import { AdminAppRoutes } from '../../AdminApp';
import { Button } from '../Buttons';
import PartnerSortable from './PartnerSortable';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { DragEndEvent } from '@dnd-kit/core/dist/types/events';

import { InputText } from 'components/FormTemplate/Fields/InputText';
import { theme } from '../../theme';
import { getRouteWithParams } from '../../utils/router';
import toast from 'react-hot-toast';

const PartnersList: FC = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [search, setSearch] = useState<string>();
  const [sortedPartners, setSortedPartners] = useState<Partner[]>([]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const { execute: getPartners, state: getPartnersState } =
    useApi<Partner[]>(`/partners`);
  const total = getPartnersState.data?.pagination?.total ?? 0;

  const {
    execute: updatePartnerOrder,
    state: { loading },
  } = useApi(`/partners/order`, {
    method: 'PATCH',
  });

  useEffect(() => {
    getPartners({
      query: {
        title: search || undefined,
      },
      onSuccess: (response) => setSortedPartners(response.value),
    });
  }, [search]);

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = sortedPartners.findIndex(
        (item) => item.id === (active.id as string),
      );
      const newIndex = sortedPartners.findIndex(
        (item) => item.id === (over?.id as string),
      );

      const newOrder = arrayMove(sortedPartners, oldIndex, newIndex);

      setSortedPartners(newOrder);

      updatePartnerOrder({
        body: { order: newOrder.map((item) => item.id) },
        onSuccess: () => void toast.success(t('partners.reorder.success')),
      });
    }
  }

  return (
    <>
      <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
        <Text
          content={`${t('partners.title')} (${total})`}
          fontStyle="heading2"
        />

        <div className="tw-flex-row tw-flex tw-gap-8">
          <Button
            content={t('partners.see')}
            iconLeft={{ name: 'Show' }}
            onClick={() =>
              window.open(
                getRouteWithParams(AdminAppRoutes.PARTNER_PREVIEW, {
                  pageName: 'partners',
                }),
                '_blank',
              )
            }
          />
          <Button
            content={t('partners.settings')}
            iconLeft={{ name: 'Setting' }}
            onClick={() =>
              navigate(
                getRouteWithParams(AdminAppRoutes.PAGE_EDIT, {
                  pageName: 'partners',
                }),
              )
            }
          />
          <Button
            content={t('partners.add.new')}
            iconLeft={{ name: 'Plus' }}
            onClick={() => navigate(AdminAppRoutes.PARTNER_CREATE)}
          />
        </div>
      </div>
      <div className="tw-w-1/4 tw-mt-8">
        <InputText
          onChange={(e) => setSearch(e.target.value)}
          labelUppercase={true}
          labelFontStyle="label"
          placeholder={t('partners.search')}
          width="100%"
          labelWeight="bold"
          $size="small"
          icon={{ name: 'Search', primaryColor: theme.colors.salmon1 }}
        />
      </div>

      <div className={'tw-mt-8 tw-flex tw-flex-col tw-gap-4'}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={sortedPartners}
            strategy={verticalListSortingStrategy}
          >
            {sortedPartners.map((partner) => (
              <PartnerSortable
                key={partner.id}
                partner={partner}
                refetch={() =>
                  getPartners({
                    query: {
                      title: search || undefined,
                    },
                    onSuccess: (response) => setSortedPartners(response.value),
                  })
                }
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </>
  );
};

export default PartnersList;
