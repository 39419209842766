import { FC, useCallback, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import { Button } from 'components/Buttons/Button';
import Table from 'components/Table/Table';
import { Flex } from 'components/Layout/Flex';

import { AdminAppRoutes } from 'AdminApp';
import { getRouteWithParams } from 'utils/router';
import format from 'date-fns/format';
import { Estimate } from '../../../types/resources';
import { ExecuteFunction } from '../../../hooks/useFetch';
import { ColumnDef, OnChangeFn, SortingState } from '@tanstack/react-table';
import { useSearchParamsContext } from '../../../context';
import { EstimatesFilters } from '../../../pages/admin/Estimates';
import { parseInt } from 'lodash';

type EstimatesListProps = {
  pageSize: number;
  search: string | null;
  customerId?: string;
  fetch: ExecuteFunction<Estimate[]>;
  total: number;
  items: Estimate[];
};

const EstimatesList: FC<EstimatesListProps> = ({
  pageSize,
  search,
  customerId,
  fetch,
  items,
  total,
}) => {
  const { t } = useTranslation();
  const {
    filters: currentFilters,
    updateFilters,
    removeFilter,
    getFilter,
  } = useSearchParamsContext<EstimatesFilters>();
  const navigate = useNavigate();

  useEffect(() => {
    fetch({
      query: {
        customer_id: customerId,
        items: pageSize,
        sort: currentFilters.sort,
        direction: currentFilters.direction,
        search: currentFilters.search || undefined,
        page: currentFilters.page || 1,
      },
    });
  }, [currentFilters]);

  const columns = useMemo<ColumnDef<Estimate>[]>(
    () => [
      {
        header: t('estimate.name'),
        accessorKey: 'name',
        enableSorting: false,
        size: 250,
      },
      {
        header: t('estimate.price'),
        accessorKey: 'price',
        cell: ({ row }) => `${row.original.price} €`,
        size: 100,
      },
      {
        header: t('estimate.created_at'),
        accessorKey: 'created_at',
        cell: ({ row }) =>
          format(new Date(row.original.created_at), "dd/MM/YYY 'à' HH:mm"),
      },
      {
        header: t('estimate.updated_at'),
        accessorKey: 'updated_at',
        cell: ({ row }) =>
          format(new Date(row.original.updated_at), "dd/MM/YYY 'à' HH:mm"),
      },
      {
        header: t('payments.case'),
        id: 'procedure',
        enableSorting: false,
        size: 50,
        cell: ({ row }) => {
          const estimate = row.original;
          const procedure_id = estimate.procedure_id;
          const procedure_reference = estimate.procedure_reference;

          return procedure_id && procedure_reference ? (
            <Link to={`${AdminAppRoutes.CASES}/${procedure_id}`}>
              {procedure_reference}
            </Link>
          ) : null;
        },
      },
      {
        accessorKey: 'id',
        size: 55,
        enableSorting: false,
        cell: ({ row }) => (
          <Flex justify="end" expand>
            <Button
              content={t('view')}
              size="small"
              primary
              onClick={() =>
                navigate(
                  getRouteWithParams(AdminAppRoutes.ESTIMATES_DETAILS, {
                    id: row.original.id,
                  }),
                )
              }
            />
          </Flex>
        ),
      },
    ],
    [history],
  );

  const currentSorting: SortingState = (() => {
    const sortColumn = getFilter('sort') as string;
    const sortDirection = getFilter('direction') as string;

    if (sortColumn) {
      return [
        {
          id: sortColumn,
          desc: sortDirection === 'desc',
        },
      ];
    }
    return [];
  })();

  const handleSortingChange: OnChangeFn<SortingState> = useCallback(
    (updaterOrValue) => {
      const updatedSorting = Array.isArray(updaterOrValue)
        ? updaterOrValue
        : updaterOrValue([]);

      try {
        const sortInfo = updatedSorting[0];

        if (sortInfo) {
          updateFilters({
            sort: sortInfo.id,
            direction: sortInfo.desc ? 'desc' : 'asc',
          });
        }
      } catch (error) {
        console.error('Erreur lors du tri:', error);
      }
    },
    [updateFilters],
  );

  return (
    <Table
      data={items}
      columns={columns}
      onPaginationChange={(pageIndex) => updateFilters({ page: pageIndex })}
      pageCount={Math.ceil((total || 0) / pageSize)}
      pagination={{
        pageIndex: getFilter('page')
          ? parseInt(getFilter('page') as string)
          : 1,
        pageSize: pageSize,
      }}
      sorting={currentSorting}
      onSortingChange={handleSortingChange}
    />
  );
};

export default EstimatesList;
