import * as React from 'react';
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  OnChangeFn,
  PaginationState,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import TableColumnHeader from './TableColumnHeader';
import cx from 'classnames';
import Pagination from './Pagination';
import { useTranslation } from 'react-i18next';
import * as Checkbox from '@radix-ui/react-checkbox';
import { Check } from 'lucide-react';

interface TableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  loading?: boolean;
  onRowClick?: (item: TData) => void;
  onSortingChange?: OnChangeFn<SortingState>;
  sorting?: SortingState;
  getRowId?: (row: TData) => string;
  enableSelection?: boolean;
  pageCount?: number;
  pagination?: PaginationState;
  onPaginationChange?: (pageIndex: number) => void;
  onRowSelectionChange?: (rows: TData[]) => void;
  selectedRows?: TData[];
}

const Table = <TData, TValue>({
  columns: userColumns,
  data,
  loading,
  onRowClick,
  onSortingChange,
  sorting,
  getRowId = (row: any) => row.id,
  enableSelection = false,
  pageCount,
  pagination,
  onPaginationChange,
  onRowSelectionChange,
  selectedRows,
}: TableProps<TData, TValue>) => {
  const { t } = useTranslation();

  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  );
  const [selectedItems, setSelectedItems] = React.useState<TData[]>([]);

  React.useEffect(() => {
    if (selectedRows) {
      setSelectedItems(selectedRows);
    }
  }, [selectedRows]);

  const selectedRowIds = React.useMemo(
    () => new Set(selectedItems.map((item) => getRowId(item))),
    [selectedItems, getRowId],
  );

  const selectionColumn: ColumnDef<TData, any> = {
    id: 'select',
    enableSorting: false,
    header: ({ table }) => {
      const isIndeterminate =
        table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected();

      return (
        <Checkbox.Root
          checked={table.getIsAllRowsSelected()}
          onClick={(e) => e.stopPropagation()}
          onCheckedChange={(checked) => {
            table.toggleAllRowsSelected(!!checked);
            if (checked) {
              setSelectedItems((prev) => {
                const newItems = data.filter(
                  (row) =>
                    !prev.some((item) => getRowId(item) === getRowId(row)),
                );
                return [...prev, ...newItems];
              });
            } else {
              setSelectedItems((prev) =>
                prev.filter(
                  (item) =>
                    !data.some((row) => getRowId(row) === getRowId(item)),
                ),
              );
            }
          }}
          className="tw-w-5 tw-h-5 tw-border tw-border-gray-500 tw-rounded-md tw-bg-white data-[state=checked]:tw-bg-primary tw-text-white tw-flex tw-items-center tw-justify-center"
        >
          <Checkbox.Indicator>
            <Check size={14} />
          </Checkbox.Indicator>
        </Checkbox.Root>
      );
    },
    cell: ({ row }) => {
      return (
        <Checkbox.Root
          checked={row.getIsSelected()}
          onClick={(e) => e.stopPropagation()}
          onCheckedChange={(checked) => {
            row.toggleSelected(!!checked);
            const rowId = getRowId(row.original);

            setSelectedItems((prev) => {
              if (!!checked) {
                if (!prev.some((item) => getRowId(item) === rowId)) {
                  return [...prev, row.original];
                }
                return prev;
              } else {
                return prev.filter((item) => getRowId(item) !== rowId);
              }
            });
          }}
          className="tw-w-5 tw-h-5 tw-border tw-border-gray-500 tw-rounded-md tw-bg-white data-[state=checked]:tw-bg-primary tw-text-white tw-flex tw-items-center tw-justify-center"
        >
          <Checkbox.Indicator>
            <Check size={14} />
          </Checkbox.Indicator>
        </Checkbox.Root>
      );
    },
  };

  const columns = React.useMemo(() => {
    if (enableSelection) {
      return [selectionColumn, ...userColumns];
    }
    return userColumns;
  }, [userColumns, enableSelection]);

  const organizedData = React.useMemo(() => {
    const selectedRowsInCurrentPage = data.filter((row) =>
      selectedRowIds.has(getRowId(row)),
    );

    const unselectedRowsInCurrentPage = data.filter(
      (row) => !selectedRowIds.has(getRowId(row)),
    );

    const selectedRowsNotInCurrentPage = selectedItems.filter(
      (item) => !data.some((row) => getRowId(row) === getRowId(item)),
    );

    const result = [
      ...selectedRowsInCurrentPage,
      ...selectedRowsNotInCurrentPage,
      ...unselectedRowsInCurrentPage,
    ];

    return result;
  }, [data, selectedItems, selectedRowIds, getRowId]);

  const rowSelection = React.useMemo(() => {
    return organizedData.reduce((acc, row, index) => {
      acc[index] = selectedRowIds.has(getRowId(row));
      return acc;
    }, {} as Record<number, boolean>);
  }, [organizedData, selectedRowIds, getRowId]);

  const table = useReactTable({
    data: organizedData,
    columns,
    manualPagination: true,
    pageCount: pageCount,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      pagination,
    },
    enableRowSelection: enableSelection,
    onRowSelectionChange: (updater) => {
      let newSelection: Record<number, boolean>;

      if (typeof updater === 'function') {
        newSelection = updater(rowSelection);
      } else {
        newSelection = updater;
      }

      const newSelectedItems: TData[] = [];
      organizedData.forEach((row, index) => {
        if (newSelection[index]) {
          newSelectedItems.push(row);
        }
      });

      setSelectedItems(newSelectedItems);
      onRowSelectionChange?.(newSelectedItems);
    },
    onSortingChange,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  return (
    <div className="tw-w-full">
      <div>
        <table className="tw-w-full tw-text-text tw-font-open tw-font-bold">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableColumnHeader
                    key={header.id}
                    column={header.column}
                    title={header.column.columnDef.header as string}
                  />
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="tw-border-t">
            <tr>
              <td colSpan={columns.length} className="tw-py-4"></td>
            </tr>
            {loading ? (
              [...Array(3)].map((_, index) => (
                <tr key={index}>
                  {columns.map((value, colIndex) => (
                    <td
                      key={`${index}-${colIndex}`}
                      className="tw-py-4 tw-px-2"
                    >
                      <div className="tw-h-4 tw-bg-slate-200 tw-rounded-md tw-animate-pulse"></div>
                    </td>
                  ))}
                </tr>
              ))
            ) : table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <tr
                  onClick={() => onRowClick?.(row.original)}
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  className={cx(
                    { 'tw-cursor-pointer': onRowClick },
                    { 'tw-bg-blue-50': row.getIsSelected() },
                  )}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td className="tw-py-4 tw-px-2" key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={columns.length}
                  className="tw-py-4 tw-h-24 tw-text-center"
                >
                  {t('filter.no_result')}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          pageCount={pageCount}
          gotoPage={onPaginationChange}
          pageIndex={pagination?.pageIndex}
        />
      </div>
    </div>
  );
};

export default Table;
