import { FC } from 'react';
import styled from 'styled-components';

import { theme } from 'theme';

import { ProcedureStepType } from 'types/resources';

const Tooltip = styled.span<{ bgColor: string }>`
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  width: 120px;
  top: calc(100% + 12px);
  left: 0%;
  margin-left: -60px;
  position: absolute;
  padding-left: 8px;

  background-color: ${(props) => props.bgColor};

  &::after {
    content: ' ';
    position: absolute;
    bottom: 100%; /* At the top of the tooltip */
    left: 90%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent ${(props) => props.bgColor}
      transparent;
  }
`;

const Container = styled.div`
  position: relative;
  display: inline-block;
  cursor: default;
`;

const OpportunityButton = styled.span<OpportunityButtonProps>`
  padding: ${theme.spacing.space8};
  border-radius: 12px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  border: 1px solid ${(props) => props.borderColor};
  white-space: nowrap;
`;

interface OpportunityButtonProps {
  borderColor: string;
  bgColor: string;
  color: string;
}

export interface OpportunityStepButtonProps {
  step: ProcedureStepType;
  text: string;
  className?: string;
}

const OpportunityStep: FC<OpportunityStepButtonProps> = ({
  step,
  text,
  className,
}) => {
  const getStepTypeColor = (stepType: ProcedureStepType) => {
    switch (stepType) {
      case ProcedureStepType.FORM:
        return {
          borderColor: '#70ff70',
          bgColor: '#CCFFCC',
          color: theme.colors.black,
        };
      case ProcedureStepType.DOCUMENT:
        return {
          borderColor: '#1fff1f',
          bgColor: '#66FF66',
          color: theme.colors.black,
        };
      case ProcedureStepType.VERIFICATION:
        return {
          borderColor: '#00a37a',
          bgColor: '#00CC99',
          color: theme.colors.black,
        };
      case ProcedureStepType.WRITING_AGREEMENT:
        return {
          borderColor: '#ff70ff',
          bgColor: '#FFCCFF',
          color: theme.colors.black,
        };
      case ProcedureStepType.REVIEW_AGREEMENT:
        return {
          borderColor: '#ff1f69',
          bgColor: '#FF6699',
          color: theme.colors.black,
        };
      case ProcedureStepType.AGREEMENT_SENT:
        return {
          borderColor: '#70ffff',
          bgColor: '#CCFFFF',
          color: theme.colors.black,
        };
      case ProcedureStepType.SIGNATURE:
        return {
          borderColor: '#47a3ff',
          bgColor: '#99CCFF',
          color: theme.colors.black,
        };
      case ProcedureStepType.ARCHIVE_SIGN:
        return {
          borderColor: '#007aa3',
          bgColor: '#0099CC',
          color: theme.colors.black,
        };
      case ProcedureStepType.NOTARY:
        return {
          borderColor: '#7070ff',
          bgColor: '#CCCCFF',
          color: theme.colors.black,
        };
      case ProcedureStepType.MARITAL_STATUS:
        return {
          borderColor: '#4747ff',
          bgColor: '#9999FF',
          color: theme.colors.black,
        };
      case ProcedureStepType.FINAL_SENDING:
        return {
          borderColor: '#8e8e8e',
          bgColor: '#B2B2B2',
          color: theme.colors.black,
        };
      default:
        return {
          borderColor: theme.colors.black,
          bgColor: theme.colors.white,
          color: theme.colors.black,
        };
    }
  };

  const colors = getStepTypeColor(step);

  return (
    <Container>
      <OpportunityButton
        color={colors?.color}
        borderColor={colors?.borderColor}
        bgColor={colors?.bgColor}
        className={className}
      >
        {text}
      </OpportunityButton>
    </Container>
  );
};

export default OpportunityStep;
