import { FC, useCallback, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';

// Components
import { Button } from 'components/Buttons/Button';
import Table from 'components/Table/Table';
import { Flex } from 'components/Layout/Flex';

import { AdminAppRoutes } from 'AdminApp';
import { getRouteWithParams } from 'utils/router';
import { PaymentFull } from '../../../types/resources';
import { TextEllipsis } from '../../../components/Text';
import { ExecuteFunction } from '../../../hooks/useFetch';
import { ColumnDef, OnChangeFn, SortingState } from '@tanstack/react-table';
import { useSearchParamsContext } from '../../../context';
import { PaymentsFilters } from '../../../pages/admin/Payments';
import { parseInt } from 'lodash';

type PaymentsListProps = {
  pageSize: number;
  fetch: ExecuteFunction<PaymentFull[]>;
  total: number;
  items: PaymentFull[];
  customerId?: string;
  estimateId?: string;
};

const PaymentsList: FC<PaymentsListProps> = ({
  pageSize,
  fetch,
  total,
  items,
  customerId,
  estimateId,
}) => {
  const { t } = useTranslation();
  const {
    filters: currentFilters,
    updateFilters,
    removeFilter,
    getFilter,
  } = useSearchParamsContext<PaymentsFilters>();
  const navigate = useNavigate();

  useEffect(() => {
    fetch({
      query: {
        items: pageSize,
        sort: currentFilters.sort,
        direction: currentFilters.direction,
        estimate_id: currentFilters.estimate_id || estimateId || undefined,
        customer_id: currentFilters.customer_id || customerId || undefined,
        status: currentFilters.status || undefined,
        page: currentFilters.page || 1,
      },
    });
  }, [currentFilters]);

  const columns = useMemo<ColumnDef<PaymentFull>[]>(
    () => [
      {
        header: t('payments.amount'),
        accessorKey: 'amount',
        size: 75,
        cell: ({ row }) => `${row.original.amount} €`,
      },
      {
        header: t('payments.status'),
        accessorKey: 'status',
        size: 75,
        enableSorting: false,
        cell: ({ row }) => {
          const details = row.original.error;
          const text = t(`payments.statuses.${row.original.status}`);

          return (
            <Flex direction={{ xs: 'column' }}>
              <Flex>{text}</Flex>
              {details && <Flex>({details})</Flex>}
            </Flex>
          );
        },
      },
      {
        header: t('payments.customer'),
        accessorKey: 'customer',
        enableSorting: false,
        size: 150,
        cell: ({ row }) => (
          <TextEllipsis fontStyle={'body2'} style={{ fontWeight: 500 }}>
            <Link
              to={`${AdminAppRoutes.CUSTOMERS}/${row.original.customer.id}`}
            >
              {row.original.customer.full_name}
            </Link>
          </TextEllipsis>
        ),
      },
      {
        header: t('payments.estimate'),
        id: 'estimate_name',
        accessorKey: 'estimate',
        enableSorting: false,
        size: 200,
        cell: ({ row }) => (
          <TextEllipsis fontStyle={'body2'} style={{ fontWeight: 500 }}>
            <Link to={`${AdminAppRoutes.ESTIMATE}/${row.original.estimate.id}`}>
              {row.original.estimate.name}
            </Link>
          </TextEllipsis>
        ),
      },
      {
        header: t('payments.case'),
        id: 'procedure_reference',
        accessorKey: 'estimate',
        enableSorting: false,
        size: 70,
        cell: ({ row }) =>
          row.original.estimate ? (
            <Link to={`${AdminAppRoutes.CASES}/${row.original.estimate.id}`}>
              {row.original.estimate.procedure_reference}
            </Link>
          ) : null,
      },
      {
        header: t('payments.created_at'),
        accessorKey: 'created_at',
        size: 125,
        cell: ({ row }) =>
          format(new Date(row.original.created_at), "dd/MM/YYY 'à' HH:mm"),
      },
      {
        accessorKey: 'id',
        size: 50,
        enableSorting: false,
        cell: ({ row }) => (
          <Flex justify="end">
            <Button
              content={t('view')}
              size="small"
              primary
              onClick={() =>
                navigate(
                  getRouteWithParams(AdminAppRoutes.PAYMENTS_DETAILS, {
                    id: row.original.id,
                  }),
                )
              }
            />
          </Flex>
        ),
      },
    ],
    [history],
  );

  const currentSorting: SortingState = (() => {
    const sortColumn = getFilter('sort') as string;
    const sortDirection = getFilter('direction') as string;

    if (sortColumn) {
      return [
        {
          id: sortColumn,
          desc: sortDirection === 'desc',
        },
      ];
    }
    return [];
  })();

  const handleSortingChange: OnChangeFn<SortingState> = useCallback(
    (updaterOrValue) => {
      const updatedSorting = Array.isArray(updaterOrValue)
        ? updaterOrValue
        : updaterOrValue([]);

      try {
        const sortInfo = updatedSorting[0];

        if (sortInfo) {
          updateFilters({
            sort: sortInfo.id,
            direction: sortInfo.desc ? 'desc' : 'asc',
          });
        }
      } catch (error) {
        console.error('Erreur lors du tri:', error);
      }
    },
    [updateFilters],
  );

  return (
    <Table
      data={items}
      columns={columns}
      onPaginationChange={(pageIndex) => updateFilters({ page: pageIndex })}
      pageCount={Math.ceil((total || 0) / pageSize)}
      pagination={{
        pageIndex: getFilter('page')
          ? parseInt(getFilter('page') as string)
          : 1,
        pageSize: pageSize,
      }}
      sorting={currentSorting}
      onSortingChange={handleSortingChange}
    />
  );
};

export default PaymentsList;
